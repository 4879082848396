import React from 'react';
import {
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Table,
  TableBody,
  Tooltip,
  Badge,
} from '@mui/material';
import moment from 'moment';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PhotoIcon from '@mui/icons-material/Photo';
import { useState } from 'react';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FactoryTwoToneIcon from '@mui/icons-material/FactoryTwoTone';
import { useAuthState } from '../../../../../../contexts/AuthContext';

export default function Row(props) {
  const { ordem, visualizarImagem, concluiProducao } = props;
  const { maquinaCodigo } = useAuthState();
  const [open, setOpen] = useState(false);

  const posicao = ordem.etapas.findIndex(
    (etapa) => etapa.codigo === maquinaCodigo
  );
  const proximaMaquina = ordem.etapas[posicao + 1];
  return (
    <>
      <TableRow
        sx={ordem.observacao ? { bgcolor: 'primary.infoRow' } : { bgcolor: '' }}
      >
        <TableCell colSpan={2} sx={{ width: '14%' }}>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </IconButton>
          <Tooltip title="Conclui a Produção">
            <IconButton
              size="small"
              onClick={() => concluiProducao(ordem.producao, proximaMaquina)}
            >
              <CheckCircleOutlineIcon
                sx={{ color: 'primary.buttonColorSecondary' }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Visualizar Imagem">
            <IconButton
              size="small"
              onClick={() => visualizarImagem(ordem.cdCaixa, ordem.cdCliente)}
            >
              <PhotoIcon sx={{ color: 'primary.buttonColorSecondary' }} />
            </IconButton>
          </Tooltip>

          {ordem.carga === 'SIM' ? (
            <IconButton size="small">
              <Tooltip title="Enviar Pedido para Carga">
                <LocalShippingIcon sx={{ color: 'primary.success' }} />
              </Tooltip>
            </IconButton>
          ) : (
            <></>
          )}
          <Badge
            badgeContent={ordem.etapas[posicao].prioridade}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            overlap="circular"
            color={
              ordem.etapas[posicao].prioridade === 1
                ? 'error'
                : ordem.etapas[posicao].prioridade === 2
                ? 'warning'
                : ordem.etapas[posicao].prioridade === 3
                ? 'success'
                : ordem.etapas[posicao].prioridade === 4
                ? 'info'
                : ordem.etapas[posicao].prioridade === 5
                ? 'primary'
                : 'primary'
            }
          >
            <Tooltip title="Prioridade de Produção">
              <FactoryTwoToneIcon
                sx={{ color: 'primary.buttonColorSecondary' }}
              />
            </Tooltip>
          </Badge>
        </TableCell>
        <TableCell>{ordem.pedido}</TableCell>
        <TableCell>
          {ordem.entrega
            ? moment(ordem.entrega).add(3, 'h').format('DD/MM/YY')
            : ''}
        </TableCell>
        <TableCell>{ordem.fantasia ? ordem.fantasia : ordem.razao}</TableCell>
        <TableCell>{ordem.produto}</TableCell>
        <TableCell>{ordem.novoComprimento}</TableCell>
        <TableCell>{ordem.novalargura}</TableCell>
        <TableCell>{ordem.gramatura}</TableCell>
        <TableCell>{ordem.chapa}</TableCell>
        <TableCell>{ordem.matriz}</TableCell>
        <TableCell>
          {ordem.novaQuantidade * ordem.etapas[posicao].multiplica}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={13}>
                      <strong>OBSERVAÇÃO: </strong> {ordem.opr_obs}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <>
                      {ordem.etapas.length > 0 ? (
                        ordem.etapas.map((etapa, index) => {
                          if (etapa.maquina) {
                            return (
                              <TableCell colSpan={2} key={index}>
                                <strong> MAQUINA {index + 1}: </strong>
                                {etapa.maquina}
                                <span
                                  style={{ color: 'red', marginLeft: '5px' }}
                                >
                                  {etapa.terceiro === 'SIM' ? (
                                    `${ordem.terceiro[0].nome} - ${ordem.terceiro[0].entrada} - ${ordem.terceiro[0].saida}`
                                  ) : (
                                    <strong>({etapa.inicio})</strong>
                                  )}
                                </span>
                              </TableCell>
                            );
                          } else {
                            return <></>;
                          }
                        })
                      ) : (
                        <></>
                      )}
                    </>
                  </TableRow>
                  <TableRow>
                    {ordem.materias
                      ? ordem.materias.length > 0
                        ? ordem.materias.map((materia, index) => {
                            return (
                              <TableCell colSpan={4} key={index}>
                                <strong
                                  style={{ color: 'red', marginRight: ' 1%' }}
                                >
                                  MATERIA DEFINIDA:
                                </strong>
                                {materia.materia}
                                <strong
                                  style={{
                                    color: 'red',
                                    marginLeft: '1%',
                                    marginRight: '1%',
                                  }}
                                >
                                  ({materia.quantidade})
                                </strong>
                              </TableCell>
                            );
                          })
                        : ''
                      : ''}
                  </TableRow>

                  {/*ordem.materia_prima_definida ? (
                    ordem.materia_prima_definida.map((materia_prima, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell colSpan={4}>
                            <strong
                              style={{ color: 'red', marginRight: ' 1%' }}
                            >
                              MATERIA DEFINIDA:
                            </strong>
                            {materia_prima.mat1_descricao}
                            <strong
                              style={{
                                color: 'red',
                                marginLeft: '1%',
                                marginRight: '1%',
                              }}
                            >
                              QTDE CHAPAS:
                            </strong>
                            {materia_prima.mat1_quantidade}
                          </TableCell>
                          <TableCell colSpan={4}>
                            <strong
                              style={{ color: 'red', marginRight: ' 1%' }}
                            >
                              MATERIA DEFINIDA:
                            </strong>
                            {materia_prima.mat2_descricao}
                            <strong
                              style={{
                                color: 'red',
                                marginLeft: '1%',
                                marginRight: '1%',
                              }}
                            >
                              QTDE CHAPAS:
                            </strong>
                            {materia_prima.mat2_quantidade}
                          </TableCell>
                          <TableCell colSpan={5}>
                            <strong
                              style={{ color: 'red', marginRight: ' 1%' }}
                            >
                              MATERIA DEFINIDA:
                            </strong>
                            {materia_prima.mat3_descricao
                              ? materia_prima.mat3_descricao
                              : '  '}
                            <strong
                              style={{
                                color: 'red',
                                marginLeft: '1%',
                                marginRight: '1%',
                              }}
                            >
                              QTDE CHAPAS:
                            </strong>
                            {materia_prima.mat3_quantidade}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <></>
                  )*/}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
