import React, { useEffect, useState } from 'react';
import {
  NativeSelect,
  TextField,
  Box,
  FormControl,
  Stack,
  Divider,
  Typography,
  Button,
} from '@mui/material';

import { toast } from 'react-toastify';
import moment from 'moment';

import api from '../../../../api/api';
import { useAuthState } from '../../../../contexts/AuthContext';
import Spinner from '../../../../components/Spinner/Spinner';
import TabelaResultados from './TabelaResultados/TabelaResultados';
import TitlePage from '../../../../components/TitlePage/TitlePage';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useConfirm } from 'material-ui-confirm';
const LinhaDeProducao = () => {
  const { empresaAtiva, maquinaCodigo, usuarioIDLogado, codigoFuncionario } =
    useAuthState();
  const [loading, setLoading] = useState(false);
  const [listaOrdens, setListaOrdens] = useState([]);
  const [listaClientes, setListaClientes] = useState([]);
  const [dataInicio, setDataInicio] = useState(moment().format('YYYY-MM-DD'));
  const [dataFim, setDataFim] = useState(moment().format('YYYY-MM-DD'));
  const [tipoFiltro, setTipoFiltro] = useState('');
  const [clienteSelecionado, setClienteSelecionado] = useState();
  const [atualizaFiltro, setAtualizaFiltro] = useState(false);
  const confirm = useConfirm();
  const [parametroFiltro, setParametroFiltro] = useState({
    emissaoInicio: '',
    emissaoFim: '',
    entregaInicio: '',
    entregaFim: '',
    cliente: '',
    status: 'PRODUCAO',
    clicheria: 'NÃO',
    maquina: maquinaCodigo,
  });

  useEffect(() => {
    setLoading(true);
    api
      .post('/producao/lista', parametroFiltro, {
        headers: { banco: empresaAtiva },
      })
      .then(({ data }) => {
        setListaOrdens(data);
        console.log(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [parametroFiltro, empresaAtiva, atualizaFiltro]);

  useEffect(() => {
    api
      .get('/cliente/lista1', {
        headers: {
          banco: empresaAtiva,
        },
      })
      .then(({ data }) => {
        setListaClientes(data);
      })
      .catch(({ data }) =>
        toast.error('Erro ao buscar dados do cliente', data)
      );
  }, [empresaAtiva]);

  const selecionaFiltro = (tipoFiltro) => {
    setTipoFiltro(tipoFiltro.tipoFiltro);
    setClienteSelecionado(tipoFiltro.cliente);
    switch (tipoFiltro.tipoFiltro) {
      case 'emissao':
        setParametroFiltro({
          emissaoInicio: dataInicio,
          emissaoFim: dataFim,
          entregaInicio: '',
          entregaFim: '',
          cliente: '',
          status: 'PRODUCAO',
          clicheria: 'NÃO',
          maquina: maquinaCodigo,
        });
        break;

      case 'entrega':
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: dataInicio,
          entregaFim: dataFim,
          cliente: '',
          status: 'PRODUCAO',
          clicheria: 'NÃO',
          maquina: maquinaCodigo,
        });
        break;
      case 'cliente':
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          cliente: tipoFiltro.cliente,
          status: 'PRODUCAO',
          clicheria: 'NÃO',
          maquina: maquinaCodigo,
        });
        break;
      default:
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          cliente: '',
          status: 'PRODUCAO',
          clicheria: 'NÃO',
          maquina: maquinaCodigo,
        });
    }
  };
  const atualizaProducao = (producao, qtde, maquina) => {
    if (!qtde || qtde <= 0) {
      toast.error('Você deve preencher uma quantidade valida');
    } else {
      confirm({
        title: 'Confirme a Operação',
        description: `DESEJA CONCLUIR A ETAPA ${
          maquina ? `'E ENCAMINHAR PARA A MAQUINA' ${maquina}` : ''
        }`,
        confirmationText: 'SIM',
        cancellationText: 'NÃO',
      })
        .then(() => {
          setLoading(true);
          api
            .put(
              '/producao/finalizar/etapa',
              {
                producao: producao,
                maquina: maquinaCodigo,
                quantidade: qtde,
                usuario: usuarioIDLogado,
                codigoFuncionario: codigoFuncionario,
              },
              {
                headers: { banco: empresaAtiva },
              }
            )
            .then(() => {
              toast.success('Producão alterada com sucesso ');
              setAtualizaFiltro(!atualizaFiltro);
            })
            .catch((err) => {
              toast.success('Erro ao  alterar Producao ');
              console.log(err);
              setAtualizaFiltro(!atualizaFiltro);
            })
            .finally(() => setLoading(false));
        })
        .catch(() => {
          /* ... */
        });
    }
  };

  const iniciaProducao = (producao, duplicada) => {
    if (duplicada !== 0) {
      confirm({
        title: 'Alerta!',
        description: `Existe uma ou mais ordens vinculadas a esse pedido, Verifique!  `,
        confirmationText: 'OK',
        cancellationText: 'CANCELAR',
      }).then(() => {
        confirm({
          title: 'Confirme a Operação',
          description: `DESEJA INICIAR A PRODUÇÃO`,
          confirmationText: 'SIM',
          cancellationText: 'NÃO',
        })
          .then(() => {
            setLoading(true);
            api
              .put(
                '/producao/etapa/inicio',
                {
                  producao: producao,
                  maquina: maquinaCodigo,
                  usuario: usuarioIDLogado,
                },
                {
                  headers: { banco: empresaAtiva },
                }
              )
              .then(() => {
                toast.success('Producão alterada com sucesso ');
                setAtualizaFiltro(!atualizaFiltro);
              })
              .catch((err) => {
                toast.success('Erro ao  alterar Producao ');
                console.log(err);
                setAtualizaFiltro(!atualizaFiltro);
              })
              .finally(() => setLoading(false));
          })
          .catch(() => {
            /* ... */
          });
      });
    } else {
      confirm({
        title: 'Confirme a Operação',
        description: `DESEJA INICIAR A PRODUÇÃO`,
        confirmationText: 'SIM',
        cancellationText: 'NÃO',
      })
        .then(() => {
          setLoading(true);
          api
            .put(
              '/producao/etapa/inicio',
              {
                producao: producao,
                maquina: maquinaCodigo,
                usuario: usuarioIDLogado,
              },
              {
                headers: { banco: empresaAtiva },
              }
            )
            .then(() => {
              toast.success('Producão alterada com sucesso ');
              setAtualizaFiltro(!atualizaFiltro);
            })
            .catch((err) => {
              toast.success('Erro ao  alterar Producao ');
              console.log(err);
              setAtualizaFiltro(!atualizaFiltro);
            })
            .finally(() => setLoading(false));
        })
        .catch(() => {
          /* ... */
        });
    }
  };
  const proximoItem = (maquinasSelecionadas) => {
    const codigoMaquinas = Object.values(maquinasSelecionadas[0]).slice(0, 3);
    const indexAtual = codigoMaquinas.indexOf(maquinaCodigo);
    if (indexAtual === -1 || indexAtual >= 4) {
      return [];
    }

    const proximoCodigo = codigoMaquinas[indexAtual + 1];
    const maquinaCorrespondente =
      maquinasSelecionadas[0][`maquina${indexAtual + 2}`];
    return { codigo: proximoCodigo, maquina: maquinaCorrespondente };
  };

  const encontrarMaquinaAtual = (maquinasSelecionadas) => {
    const codigoMaquinas = Object.values(maquinasSelecionadas[0]).slice(0, 4);
    const indexAtual = codigoMaquinas.indexOf(maquinaCodigo);
    if (indexAtual === -1 || indexAtual >= 4) {
      return [];
    }
    const posicaoMaquina = indexAtual + 1;

    return posicaoMaquina;
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Box>
        <TitlePage title="Linha de Produção" />
        <Box>
          <Box marginLeft={'6%'}>
            <Typography variant="subtitle2">Período </Typography>
          </Box>

          <Stack
            direction={{ xs: 'row', sm: 'row', md: 'row', lg: 'row' }}
            spacing={{ xs: 1, sm: 1, md: 3 }}
            mt={2}
            mb={2}
          >
            <Box width={'4%'} display={'flex'} justifyContent={'center'}>
              <Button
                onClick={() => setAtualizaFiltro(!atualizaFiltro)}
                sx={{
                  borderRadius: '50%',
                  width: '25%',
                  height: '100%',
                  backgroundColor: 'primary.success',
                }}
              >
                <RefreshIcon sx={{ color: 'white' }} />
              </Button>
            </Box>
            <Box width={'13%'}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Emissão Inicial "
                type="date"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </Box>
            <Box width={'13%'}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Emissão Final"
                type="date"
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </Box>
            <Box width={'20%'}>
              <Box>
                <Typography variant="subtitle2">Filtra por Data</Typography>
              </Box>

              <NativeSelect
                value={tipoFiltro}
                fullWidth
                onChange={(e) =>
                  selecionaFiltro({ tipoFiltro: e.target.value })
                }
              >
                <option default value={''}>
                  Todos os Registros
                </option>
                <option value={'emissao'}>Por Emissao</option>
                <option value={'entrega'}>Por Data Entrega </option>
              </NativeSelect>
            </Box>
            <Box width={'45%'}>
              <Box>
                <Typography variant="subtitle2">Filtrar por Cliente</Typography>
              </Box>

              <FormControl fullWidth>
                <NativeSelect
                  fullWidth
                  value={clienteSelecionado}
                  onChange={(e) =>
                    selecionaFiltro({
                      tipoFiltro: 'cliente',
                      cliente: e.target.value,
                    })
                  }
                >
                  <option value={''}>Todos os Registros </option>
                  {listaClientes.length > 0 ? (
                    listaClientes.map((cliente) => {
                      return (
                        <option key={cliente.codigo} value={cliente.codigo}>
                          {cliente.nome_fantasia
                            ? cliente.nome_fantasia
                            : cliente.razao_social}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </NativeSelect>
              </FormControl>
            </Box>
          </Stack>
        </Box>

        <Divider />
        <TabelaResultados
          listaOrdens={listaOrdens}
          atualizaProducao={atualizaProducao}
          proximoItem={proximoItem}
          encontrarMaquinaAtual={encontrarMaquinaAtual}
          iniciaProducao={iniciaProducao}
        />
      </Box>
    </>
  );
};

export default LinhaDeProducao;
