import React, { useState } from 'react';
import {
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Table,
  TableBody,
  Tooltip,
  Badge,
} from '@mui/material';
import moment from 'moment';

import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

export default function Row(props) {
  const { ordem, filtroReservaMateria, solicitaCompra } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        sx={
          ordem.opr_id_duplica !== 0
            ? { bgcolor: 'secondary.light' }
            : ordem.opr_obs
            ? { bgcolor: 'primary.infoRow' }
            : { bgcolor: '' }
        }
      >
        <TableCell colSpan={2} sx={{ width: '25%', padding: 0 }}>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </IconButton>
          <Tooltip title="Reserva Materia Prima">
            <Badge
              badgeContent={ordem.prioridade}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              overlap="circular"
              color={
                ordem.prioridade === 1
                  ? 'error'
                  : ordem.prioridade === 2
                  ? 'warning'
                  : ordem.prioridade === 3
                  ? 'success'
                  : ordem.prioridade === 4
                  ? 'info'
                  : ordem.prioridade === 5
                  ? 'primary'
                  : 'primary'
              }
            >
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() =>
                  filtroReservaMateria(
                    ordem.opr_codigo,
                    ordem.impressora[0].total,
                    ordem.riscador[0].total,
                    ordem.gramatura,
                    ordem.opr_qt_caixa,
                    ordem.materia_prima_comprada[0].codigo,
                    ordem.materia_prima_comprada[0].quatidade,
                    ordem.impressora[0].divisor,
                    ordem.riscador[0].divisor,
                    ordem.vincada,
                    ordem.modelo_caixa,
                    ordem.modelo_caixa_coeficiente,
                    ordem.opr_id_duplica
                  )
                }
              >
                <SwapHorizontalCircleIcon sx={{ color: 'primary.main' }} />
              </IconButton>
            </Badge>
          </Tooltip>

          <Tooltip title="Solicitar Compra">
            <IconButton
              onClick={() =>
                solicitaCompra(ordem.opr_codigo, ordem.opr_id_duplica)
              }
            >
              <ShoppingCartIcon />
            </IconButton>
          </Tooltip>

          {ordem.carga === 'SIM' ? (
            <IconButton size="small">
              <Tooltip title="Enviar Pedido para Carga">
                <LocalShippingIcon sx={{ color: 'primary.success' }} />
              </Tooltip>
            </IconButton>
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell>{ordem.opr_cd_venda}</TableCell>
        <TableCell>
          {moment(ordem.opr_emissao).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell>
          {moment(ordem.opr_entrega).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell>
          {ordem.nome_fantasia ? ordem.nome_fantasia : ordem.razao_social}
        </TableCell>
        <TableCell>{ordem.descricao}</TableCell>
        <TableCell>{ordem.comprimento}</TableCell>
        <TableCell>{ordem.largura}</TableCell>
        <TableCell>{ordem.altura}</TableCell>
        <TableCell>{ordem.tipo_chapa}</TableCell>
        <TableCell>{ordem.gramatura}</TableCell>
        <TableCell>{ordem.opr_qt_caixa}</TableCell>
        <TableCell>{ordem.kilo.toFixed(2)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8}>
                      <strong>OBSERVAÇÃO: </strong> {ordem.opr_obs}
                    </TableCell>
                    <TableCell colSpan={4}>
                      <strong>Cidade: </strong>
                      {ordem.cidade}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <strong>MODELO CAIXA: </strong> {ordem.modelo_caixa}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <strong>MATRIZ: </strong>
                      {ordem.matriz}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>
                      <strong> PREÇO ANTERIOR: </strong>

                      {ordem.preco_anterior.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </TableCell>
                    <TableCell colSpan={2}>
                      <strong>DATA AJUSTE: </strong>
                    </TableCell>
                    <TableCell>
                      {ordem.data_ajuste
                        ? moment(ordem.data_ajuste)
                            .add(3, 'hours')
                            .format('DD/MM/YYYY')
                        : ''}
                    </TableCell>

                    <TableCell colSpan={2}>
                      <strong> PREÇO ATUAL :</strong>
                    </TableCell>
                    <TableCell>
                      {ordem.preco_atual
                        ? ordem.preco_atual.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })
                        : '0'}
                    </TableCell>
                    <TableCell colSpan={2}>
                      <strong> PREÇO KILO: </strong>
                    </TableCell>
                    <TableCell>
                      {ordem.preco_kg
                        ? ordem.preco_kg.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })
                        : '0'}
                    </TableCell>
                  </TableRow>
                  {ordem.riscador ? (
                    ordem.riscador.map((medida, index) => (
                      <TableRow key={(medida, index)}>
                        <TableCell>
                          <strong>RISCADOR </strong>
                        </TableCell>
                        <TableCell>{medida.medida1}</TableCell>
                        <TableCell>{medida.medida2}</TableCell>
                        <TableCell>{medida.medida3}</TableCell>
                        <TableCell>{medida.medida4}</TableCell>
                        <TableCell>{medida.medida5}</TableCell>
                        <TableCell>{medida.medida6}</TableCell>
                        <TableCell>{medida.medida7}</TableCell>
                        <TableCell>{medida.medida8}</TableCell>
                        <TableCell>{medida.medida9}</TableCell>
                        <TableCell>{medida.total}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}

                  {ordem.impressora ? (
                    ordem.impressora.map((medida, index) => (
                      <TableRow key={(medida, index)}>
                        <TableCell>
                          <strong>IMPRESSORA</strong>
                        </TableCell>

                        <TableCell>{medida.medida1}</TableCell>
                        <TableCell>{medida.medida2}</TableCell>
                        <TableCell>{medida.medida3}</TableCell>
                        <TableCell>{medida.medida4}</TableCell>
                        <TableCell>{medida.medida5}</TableCell>
                        <TableCell>{medida.medida6}</TableCell>
                        <TableCell>{medida.medida7}</TableCell>
                        <TableCell>{medida.medida8}</TableCell>
                        <TableCell>{medida.medida9}</TableCell>
                        <TableCell>{medida.total}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
