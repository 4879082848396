import { Box } from '@mui/system';
import React from 'react';
import TitlePage from '../../components/TitlePage/TitlePage';
import { useAuthState } from '../../contexts/AuthContext';

function HomePage() {
  const { empresaAtiva } = useAuthState();

  return (
    <div>
      <TitlePage title="Home" />

      <Box display="flex" justifyContent="center">
        <img
          src={`/images/empresa${empresaAtiva}.jpg`}
          alt="Empresa Ativa"
          style={{ maxWidth: '50%' }}
        />
      </Box>
    </div>
  );
}

export default HomePage;
