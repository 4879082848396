import React from 'react';
import PersonalButton from '../../components/PersonalButton/PersonalButton';
import { useAuthState } from '../../contexts/AuthContext';
import { listImages } from './listImages';

export default function Producao() {
  const { acessos } = useAuthState();

  const nivelAcesso = acessos.filter((acesso) => acesso.grupo === 'PRODUCAO');

  return (
    <>
      <PersonalButton listImages={listImages} acesso={nivelAcesso} />
    </>
  );
}
