import { Switch, Route } from 'react-router-dom';

//Route personalizado para estabelecer a autenticacao tive que mudar o nome pois estava confundindo com o routes de react-router-dom
import { CustomRoute } from './CustomRoute';

//Pagina Login
import Login from '../pages/Login/Login';

import HomePage from '../pages/HomePage/HomePage';

//Layout da Dashboard
import Layout from '../layouts/Layout';

//PAGINA DE RESET DE SENHA
import ResetPassword from '../pages/ResetPassword/ResetPassword';

import Imagens from '../pages/Imagens/Imagens';
import ConsultaLog from '../pages/ConsultaLog/ConsultaLog';

//LINHA DE PRODUCAO  - IMPORTS
import Producao from '../pages/Producao/Producao';
// PRODUCAO PADRAO
import ReservaMateriaPrima from '../pages/Producao/Padrao/ReservaMateriaPrima/ReservaMateriaPrima';
import Acompanhamento from '../pages/Producao/Padrao/Acompanhamento/Acompanhamento';
import Etapas from '../pages/Producao/Padrao/Etapas/Etapas';
import Clicheria from '../pages/Producao/Padrao/Clicheria/Clicheria';
import Estoque from '../pages/Producao/Padrao/Estoque/Estoque';
import LinhaDeProducao from '../pages/Producao/Padrao/LinhaDeProducao/LinhaDeProducao';
import Cargas from '../pages/Producao/Padrao/Cargas/Cargas';
import ConsultaMateria from '../pages/Producao/Padrao/ConsultaMateria/ConsultaMateria';

//IMPORTS PRODUCAO OFFSET
import AcompanhamentoOffSet from '../pages/Producao/OffSet/Acompanhamento/Acompanhamento';
import ArtesOffSet from '../pages/Producao/OffSet/Artes/Artes';
import CargasOffSet from '../pages/Producao/OffSet/Cargas/Cargas';
import ProducaoOffSet from '../pages/Producao/OffSet/Producao/Producao';
import GerirProducaoOffSet from '../pages/Producao/OffSet/GerirProducao/GerirProducao';
import ReservaMateriaPrimaOffSet from '../pages/Producao/OffSet/ReservaMateriaPrima/ReservaMateriaPrima';

export default function Routes() {
  return (
    <>
      <Switch>
        <Route path="/login/:id" component={Login} />
        <CustomRoute
          exact
          path="/alterasenha"
          component={ResetPassword}
          isPrivate
        />
        <Layout>
          <Switch>
            <CustomRoute exact path="/" component={HomePage} isPrivate />
            <CustomRoute exact path="/imagens" component={Imagens} isPrivate />
            <CustomRoute
              exact
              path="/producao"
              component={Producao}
              isPrivate
            />

            {/* PRODUCAO PADRAO */}
            <CustomRoute
              exact
              path="/producao/padrao/clicheria"
              component={Clicheria}
              isPrivate
            />
            <CustomRoute
              exact
              path="/producao/padrao/estoque"
              component={Estoque}
              isPrivate
            />
            <CustomRoute
              exact
              path="/producao/padrao/linhaproducao"
              component={LinhaDeProducao}
              isPrivate
            />
            <CustomRoute
              exact
              path="/producao/padrao/reservamateria"
              component={ReservaMateriaPrima}
              isPrivate
            />
            <CustomRoute
              exact
              path="/producao/padrao/etapas"
              component={Etapas}
              isPrivate
            />
            <CustomRoute
              exact
              path="/producao/padrao/acompanhamento"
              component={Acompanhamento}
              isPrivate
            />
            <CustomRoute
              exact
              path="/producao/padrao/cargas"
              component={Cargas}
              isPrivate
            />
            <CustomRoute
              exact
              path="/producao/padrao/consultamateria"
              component={ConsultaMateria}
              isPrivate
            />

            {/* PRODUCAO OFFSET */}
            <CustomRoute
              exact
              path="/producao/offset/artes"
              component={ArtesOffSet}
              isPrivate
            />
            <CustomRoute
              exact
              path="/producao/offset/acompanhamento"
              component={AcompanhamentoOffSet}
              isPrivate
            />
            <CustomRoute
              exact
              path="/producao/offset/cargas"
              component={CargasOffSet}
              isPrivate
            />
            <CustomRoute
              exact
              path="/producao/offset/producao"
              component={ProducaoOffSet}
              isPrivate
            />
            <CustomRoute
              exact
              path="/producao/offset/gerirprod"
              component={GerirProducaoOffSet}
              isPrivate
            />
            <CustomRoute
              exact
              path="/producao/offset/reservamateria"
              component={ReservaMateriaPrimaOffSet}
              isPrivate
            />

            {/*FIM PRODUCAO OFFSET*/}
            <CustomRoute
              exact
              path="/consultalog"
              component={ConsultaLog}
              isPrivate
            />

            <CustomRoute path="*" isPrivate />
          </Switch>
        </Layout>
      </Switch>
    </>
  );
}
