import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  FormControl,
  Box,
  NativeSelect,
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DefineCaminhao = ({
  open,
  onClose,
  pedidoSelecinado,
  separarCarga,
  listaVeiculos,
  qtdeProntos,
}) => {
  const [veiculoSelecionado, setVeiculoSelecionado] = useState('');

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>SELECIONE O VEICULO PARA A CARGA </DialogTitle>
      <DialogContent dividers sx={{ width: '450px' }}>
        <Box mt={2} mb={2}>
          <FormControl fullWidth>
            <NativeSelect
              fullWidth
              value={veiculoSelecionado}
              onChange={(e) => setVeiculoSelecionado(e.target.value)}
            >
              <option value="">Todos os Registros </option>
              {listaVeiculos.length > 0 ? (
                listaVeiculos.map((veiculo) => {
                  return (
                    <option key={veiculo.vei_codigo} value={veiculo.vei_codigo}>
                      {veiculo.vei_placa}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </NativeSelect>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button onClick={onClose}>Fechar</Button>
        <Button
          onClick={() => {
            separarCarga(pedidoSelecinado, veiculoSelecionado, qtdeProntos);
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DefineCaminhao;
