import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import { DrawerHeader } from '../components/HeaderDrawer/DrawerComponents';
import HeaderDrawer from '../components/HeaderDrawer/HeaderDrawer';
function Layout({ children }) {
  return (
    <>
      <CssBaseline />
      <Box sx={{ display: 'flex' }}>
        <HeaderDrawer />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {children}
        </Box>
      </Box>
    </>
  );
}

export default Layout;
