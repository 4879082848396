import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TablePagination,
} from "@mui/material";

import Row from "./Rows/Row";

import { filtraMateriaPrimaOffSet } from "../../../../../helpers/FilterFunctions";
import ReservaMateria from "./ReservaMateria/ReservaMateria";

export default function TabelaResultados({
  listaOrdens,
  listaMateriasPrima,
  atualizaMateriaPrima,
  solicitaCompra,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openDialogSeparaMateria, setOpenDialogSeparaMateria] = useState(false);
  const [producaoSelecionada, setProducaoSelecionada] = useState("");
  const [quantidadeCaixas, setQuantidadeCaixas] = useState(0);
  const [comprimentoCaixa, setComprimentoCaixa] = useState(0);
  const [larguraCaixa, setLarguraCaixa] = useState(0);
  const [gramaturaCaixa, setGramaturaCaixa] = useState(0);
  const [nomeCaixa, setNomeCaixa] = useState(0);
  const [materiasFiltradas, setMateriasFiltradas] = useState([]);
  const [medidas, setMedidas] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };
  const fechaDialogSeparaMateria = () => {
    setOpenDialogSeparaMateria(false);
    setProducaoSelecionada(0);
    setQuantidadeCaixas(0);
    setComprimentoCaixa(0);
    setLarguraCaixa(0);
    setGramaturaCaixa(0);
    setNomeCaixa(0);
  };

  const aofiltraMateriaPrimaOffSet = (
    producao,
    comprimento,
    largura,
    gramatura,
    qtdCaixasPedido,
    caixa,
    medidas
  ) => {
    async function processaMedidas() {
      const listaMateriasFiltradas = [];

      for (let i = 0; i < medidas.length; i++) {
        try {
          const materia = await filtraMateriaPrimaOffSet(
            listaMateriasPrima,
            medidas[i].corteComprimento,
            medidas[i].corteLargura,
            gramatura,
            qtdCaixasPedido,
            medidas[i].qtde
          );

          listaMateriasFiltradas.push(materia);
        } catch (err) {
          console.log(err);
        }
      }

      setMateriasFiltradas(listaMateriasFiltradas);
    }

    processaMedidas();
    setProducaoSelecionada(producao);
    setQuantidadeCaixas(qtdCaixasPedido);
    setComprimentoCaixa(comprimento);
    setLarguraCaixa(largura);
    setGramaturaCaixa(gramatura);
    setNomeCaixa(caixa);
    setMedidas(medidas);

    setOpenDialogSeparaMateria(true);
  };

  /*  if (materiaPedida !== null) {
      const materialPedido = listaMateriasPrima.find(
        (materia) => materia.codigo == materiaPedida
      );
      setFiltredMateriasPrima([
        {
          ...materialPedido,
          qtde_chapas: qtdePedida,
          qtde_caixasPedido: qtdPedido,
        },
      ]);
    } else {*/

  return (
    <Box>
      {listaOrdens ? (
        <ReservaMateria
          openDialogSeparaMateria={openDialogSeparaMateria}
          onClose={fechaDialogSeparaMateria}
          producaoSelecionada={producaoSelecionada}
          materiasFiltradas={materiasFiltradas}
          atualizaMateriaPrima={atualizaMateriaPrima}
          quantidadeCaixas={quantidadeCaixas}
          nomeCaixa={nomeCaixa}
          comprimentoCaixa={comprimentoCaixa}
          larguraCaixa={larguraCaixa}
          gramaturaCaixa={gramaturaCaixa}
          medidas={medidas}
          listaMateriasPrima={listaMateriasPrima}
        />
      ) : (
        <></>
      )}

      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              <TableCell>Pedido</TableCell>
              <TableCell>Emissão</TableCell>
              <TableCell>Entrega</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Caixa</TableCell>
              <TableCell>Comprimento</TableCell>
              <TableCell>Largura</TableCell>
              <TableCell>Gramatura</TableCell>
              <TableCell>Matriz</TableCell>

              <TableCell>Qtde</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listaOrdens.length > 0 ? (
              listaOrdens.length > 0 ? (
                listaOrdens
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((ordem, index) => {
                    return (
                      <Row
                        ordem={ordem}
                        key={index}
                        solicitaCompra={solicitaCompra}
                        filtrarReservaMateria={aofiltraMateriaPrimaOffSet}
                      />
                    );
                  })
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listaOrdens.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}
