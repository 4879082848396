import React, { useState } from 'react';
import {
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Table,
  TableBody,
  Tooltip,
  TableHead,
} from '@mui/material';
import moment from 'moment';

import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
export default function Row(props) {
  const {
    ordem,
    abrirModalDefineVeiculo,
    confirmaCargaVeiculo,
    removeCargaVeiculo,
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell colSpan={2} sx={{ width: '12%' }}>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </IconButton>

          {ordem.codVeiculo === 0 ? (
            <Tooltip title="Selecionar Caminhao para a entrega ">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() =>
                  abrirModalDefineVeiculo(ordem.venda, ordem.qtdeProntos)
                }
              >
                <LocalShippingIcon sx={{ color: 'primary.main' }} />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Tooltip title="Remover pedido da carga ">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() =>
                    removeCargaVeiculo(ordem.venda, ordem.codVeiculo)
                  }
                >
                  <ClearIcon sx={{ color: 'primary.error' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Confirmar pedido na carga  ">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() =>
                    confirmaCargaVeiculo(ordem.venda, ordem.codVeiculo)
                  }
                >
                  <CheckIcon sx={{ color: 'primary.success' }} />
                </IconButton>
              </Tooltip>
            </>
          )}
        </TableCell>
        <TableCell>{ordem.venda}</TableCell>
        <TableCell>
          {moment(ordem.emissao).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell>
          {moment(ordem.entrega).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell>{ordem.fantasia ? ordem.fantasia : ordem.razao}</TableCell>
        <TableCell>{ordem.cidade}</TableCell>
        <TableCell>{ordem.vendedor}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow
                    sx={{
                      borderRadius: '5px',
                      color: 'primary.textColor',
                    }}
                  >
                    <TableCell
                      sx={{
                        bgcolor: 'primary.main',
                        border: 'solid 1px primary.warning',
                        borderRadius: '15px',
                        color: 'primary.textColor',
                      }}
                    >
                      <strong>CAIXA </strong>
                    </TableCell>

                    <TableCell
                      sx={{
                        bgcolor: 'primary.main',
                        border: 'solid 1px primary.warning',
                        borderRadius: '15px',

                        color: 'primary.textColor',
                      }}
                    >
                      <strong>PARTES CAIXA </strong>
                    </TableCell>
                    <TableCell
                      sx={{
                        bgcolor: 'primary.main',
                        border: 'solid 1px primary.warning',
                        borderRadius: '15px',
                        color: 'primary.textColor',
                      }}
                    >
                      <strong>PARTES PRONTAS </strong>
                    </TableCell>

                    <TableCell
                      sx={{
                        bgcolor: 'primary.main',
                        border: 'solid 1px primary.warning',
                        borderRadius: '15px',
                        color: 'primary.textColor',
                      }}
                    >
                      <strong>QTDE </strong>
                    </TableCell>
                    <TableCell
                      sx={{
                        bgcolor: 'primary.main',
                        border: 'solid 1px primary.warning',

                        color: 'primary.textColor',
                        borderRadius: '15px',
                      }}
                    >
                      <strong> VOLUMES</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordem.itens ? (
                    ordem.itens.map((item, index) => (
                      <TableRow
                        key={(item, index)}
                        sx={
                          item.status === 'FINALIZADO'
                            ? {
                                bgcolor: 'primary.infoRow',
                              }
                            : { bgcolor: '' }
                        }
                      >
                        <TableCell>
                          <strong>{item.caixa}</strong>
                        </TableCell>

                        <TableCell>{item.partes_caixa}</TableCell>
                        <TableCell>{item.partes_pronta}</TableCell>
                        <TableCell>{item.quantidade}</TableCell>
                        <TableCell>{item.volume}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
