import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  TextField,
  FormControl,
  Box,
  MenuItem,
} from '@mui/material';

import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StatusCliche = ({
  open,
  onClose,
  producaoSelecionada,
  alterarStatusCliche,
}) => {
  const [novoStatus, setNovoStatus] = useState(moment().format('YYYY-MM-DD'));
  const status = [
    {
      id: 1,
      status: 'EM CRIACAO',
    },
    { id: 2, status: 'AGUARDANDO CLIENTE' },
    { id: 3, status: 'ENVIADO CLICHERISTA ' },
  ];
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>CLICHERIA</DialogTitle>
      <DialogContent dividers sx={{ width: '450px' }}>
        <TextField
          fullWidth
          id="outlined-select-currency"
          select
          label="SELECIONE UM STATUS"
          onChange={(e) => setNovoStatus(e.target.value)}
        >
          <MenuItem selected value="-1">
            SELECIONE UM STATUS
          </MenuItem>
          {status.map((status) => (
            <MenuItem key={status.id} value={status.status}>
              {status.status}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button onClick={onClose}>Fechar</Button>
        <Button
          onClick={() => {
            alterarStatusCliche(producaoSelecionada, novoStatus);
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StatusCliche;
