import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  FormControl,
  Box,
  NativeSelect,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AjustaEstoque = ({
  open,
  onClose,
  materiaPrimaSelecionada,
  atualizaEstoque,
}) => {
  const [tipoMovimento, setTipoMovimento] = useState('');
  const [quantidade, setQuantidade] = useState('');

  const aoFecharModal = () => {
    setQuantidade('');
    setTipoMovimento('0');
    onClose();
  };

  const aoAtualizarEstoque = () => {
    if (tipoMovimento !== 'ENTRADA' && tipoMovimento !== 'SAÍDA') {
      return toast.error('O tipo de movimento deve ser Preenchido ');
    } else if (quantidade <= 0 || quantidade === '') {
      return toast.error('A quantidade deve ser preenchida e maior que 0 ');
    } else {
      atualizaEstoque(
        materiaPrimaSelecionada.codigo,
        tipoMovimento,
        quantidade
      );
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={aoFecharModal}
    >
      <DialogTitle>AJUSTE DE ESTOQUE </DialogTitle>
      <DialogContent dividers sx={{ width: '450px' }}>
        <Typography variant="subtitle2">
          {materiaPrimaSelecionada.codigo
            ? materiaPrimaSelecionada.descricao
            : ''}
        </Typography>

        <Box mt={2} mb={2}>
          <FormControl fullWidth>
            <NativeSelect
              fullWidth
              value={tipoMovimento}
              onChange={(e) => setTipoMovimento(e.target.value)}
            >
              <option value="0">Todos os Registros </option>
              <option value="ENTRADA">ENTRADA</option>
              <option value="SAÍDA">SAÍDA</option>
            </NativeSelect>
          </FormControl>
        </Box>
        <Box mt={2} mb={2}>
          <FormControl fullWidth>
            <TextField
              label={'QUANTIDADE'}
              value={quantidade}
              type="number"
              onChange={(e) => setQuantidade(e.target.value)}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button onClick={aoFecharModal}>Fechar</Button>
        <Button
          onClick={() => {
            aoAtualizarEstoque();
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AjustaEstoque;
