import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TablePagination,
} from '@mui/material';

import Row from './Rows/Row';
import SelecionaMaquinas from './SelecionaMaquinas/SelecionaMaquinas';
import { useConfirm } from 'material-ui-confirm';

export default function TabelaResultados({
  listaOrdens,
  listaMaquinas,
  handleChangeListaMaquinas,
  atualizarMaquinas,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openDialogSeparaMateria, setOpenDialogSeparaMateria] = useState(false);
  const [producaoSelecionada, setProducaoSelecionada] = useState('');
  const confirm = useConfirm();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };
  const fechaDialogSeparaMateria = () => {
    setOpenDialogSeparaMateria(false);
  };
  const separaMateria = (producao, duplicada) => {
    if (duplicada !== 0) {
      confirm({
        title: 'Alerta!',
        description: `Existe uma ou mais ordens vinculadas a esse pedido, Verifique!  `,
        confirmationText: 'OK',
        cancellationText: 'CANCELAR',
      });
    }
    setProducaoSelecionada(producao);
    setOpenDialogSeparaMateria(true);
  };

  return (
    <Box>
      {listaOrdens ? (
        <SelecionaMaquinas
          openDialogSeparaMateria={openDialogSeparaMateria}
          onClose={fechaDialogSeparaMateria}
          producaoSelecionada={producaoSelecionada}
          listaMaquinas={listaMaquinas}
          atualizarMaquinas={atualizarMaquinas}
          handleChangeListaMaquinas={handleChangeListaMaquinas}
        />
      ) : (
        <></>
      )}

      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              <TableCell>Pedido</TableCell>
              <TableCell>Emissão</TableCell>
              <TableCell>Entrega</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Caixa</TableCell>
              <TableCell>Comp.</TableCell>
              <TableCell>Larg.</TableCell>
              <TableCell>Alt.</TableCell>
              <TableCell>Chapa</TableCell>
              <TableCell>Vinc.</TableCell>
              <TableCell>Gram.</TableCell>
              <TableCell>Qtde</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listaOrdens ? (
              listaOrdens.length > 0 ? (
                listaOrdens
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((ordem, index) => {
                    return (
                      <Row
                        ordem={ordem}
                        separaMateria={separaMateria}
                        key={index}
                      />
                    );
                  })
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listaOrdens.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}
