import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TablePagination,
} from '@mui/material';

import Row from './Rows/Row';
import DefineVeiculo from './DefineVeiculo/DefineVeiculo';

export default function TabelaResultados({
  listaOrdens,
  listaVeiculos,
  separarCarga,
  removeCargaVeiculo,
  confirmaCargaVeiculo,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openModalDefineVeiculo, setOpenModalDefineVeiculo] = useState(false);
  const [pedidoSelecinado, setPedidoSelecinado] = useState('');
  const [qtdeProntos, setQtdeProntos] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const abrirModalDefineVeiculo = (pedido, qtdeProntos) => {
    setPedidoSelecinado(pedido);
    setQtdeProntos(qtdeProntos);
    setOpenModalDefineVeiculo(true);
  };
  const fecharModalDefineVeiculo = () => {
    setPedidoSelecinado('');
    setQtdeProntos('');
    setOpenModalDefineVeiculo(false);
  };

  return (
    <Box>
      {listaVeiculos.length > 0 ? (
        <DefineVeiculo
          open={openModalDefineVeiculo}
          onClose={fecharModalDefineVeiculo}
          listaVeiculos={listaVeiculos}
          separarCarga={separarCarga}
          pedidoSelecinado={pedidoSelecinado}
          qtdeProntos={qtdeProntos}
        />
      ) : (
        <></>
      )}
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              <TableCell>Pedido</TableCell>
              <TableCell>Emissão</TableCell>
              <TableCell>Entrega</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Cidade</TableCell>
              <TableCell>Itens Pedido</TableCell>
              <TableCell>Itens Prontos</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listaOrdens ? (
              listaOrdens.length > 0 ? (
                listaOrdens
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((ordem, index) => {
                    return (
                      <Row
                        ordem={ordem}
                        key={index}
                        abrirModalDefineVeiculo={abrirModalDefineVeiculo}
                        removeCargaVeiculo={removeCargaVeiculo}
                        confirmaCargaVeiculo={confirmaCargaVeiculo}
                      />
                    );
                  })
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listaOrdens.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}
