import React, { useEffect, useState } from 'react';
import {
  NativeSelect,
  TextField,
  Box,
  FormControl,
  Stack,
  Divider,
  Typography,
  Button,
} from '@mui/material';

import { toast } from 'react-toastify';
import moment from 'moment';

import api from '../../../../api/api';
import { useAuthState } from '../../../../contexts/AuthContext';
import Spinner from '../../../../components/Spinner/Spinner';
import TabelaResultados from './TabelaResultados/TabelaResultados';
import TitlePage from '../../../../components/TitlePage/TitlePage';

import { useConfirm } from 'material-ui-confirm';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ImprimePedidoProducao } from '../../../../components/ImprimePedidoProducao/ImprimePedidoProducao';

const ListaProduçoes = () => {
  const { empresaAtiva, acessos, appAcesso, usuarioIDLogado } = useAuthState();
  const [loading, setLoading] = useState(false);
  const [listaOrdens, setListaOrdens] = useState([]);
  const [listaClientes, setListaClientes] = useState([]);
  const [dataInicio, setDataInicio] = useState(moment().format('YYYY-MM-DD'));
  const [dataFim, setDataFim] = useState(moment().format('YYYY-MM-DD'));
  const [tipoFiltro, setTipoFiltro] = useState('');
  const [clienteSelecionado, setClienteSelecionado] = useState();
  const [atualizaFiltro, setAtualizaFiltro] = useState(false);
  const [listaMaquinas, setListaMaquinas] = useState([]);
  const [maquinaFiltroSelecionada, setMaquinaFiltroSelecionada] = useState(0);
  const [statusFiltroSelecionado, setStatusFiltroSelecionado] = useState(0);
  const [openDialogImprimePedido, setOpenDialogImprimePedido] = useState(false);
  const [producaoSelecionada, setProducaoSelecionada] = useState(0);
  const confirm = useConfirm();

  const [parametroFiltro, setParametroFiltro] = useState({
    emissaoInicio: '',
    emissaoFim: '',
    entregaInicio: '',
    entregaFim: '',
    cliente: '',
    status: appAcesso === 'EXPEDICAO' ? 'ACABAMENTO' : '',
    maquina: '',
  });

  useEffect(() => {
    setLoading(true);
    api
      .get('/producao/lista/etapas', {
        headers: {
          banco: empresaAtiva,
        },
      })
      .then(({ data }) => {
        setListaMaquinas(data);
      })
      .catch(({ data }) =>
        toast.error('Erro ao buscar dados da MateriaPrima', data)
      );
  }, [empresaAtiva]);

  useEffect(() => {
    setLoading(true);
    api
      .get('/cliente/lista1', {
        headers: {
          banco: empresaAtiva,
        },
      })
      .then(({ data }) => {
        setListaClientes(data);
      })
      .catch(({ data }) =>
        toast.error('Erro ao buscar dados do cliente', data)
      );
  }, [empresaAtiva]);

  useEffect(() => {
    setLoading(true);
    api
      .post('/producao/lista', parametroFiltro, {
        headers: { banco: empresaAtiva },
      })
      .then(({ data }) => {
        setListaOrdens(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [parametroFiltro, empresaAtiva, atualizaFiltro]);

  const selecionaFiltro = (tipoFiltro) => {
    console.log(tipoFiltro);
    setTipoFiltro(tipoFiltro.tipoFiltro);

    setMaquinaFiltroSelecionada(tipoFiltro.maquina);

    setStatusFiltroSelecionado((prevState) =>
      tipoFiltro.status
        ? tipoFiltro.status
        : prevState || tipoFiltro.status
        ? tipoFiltro.status == ''
          ? tipoFiltro.status
          : prevState
        : prevState
    );
    setClienteSelecionado((prevState) =>
      tipoFiltro.cliente
        ? tipoFiltro.cliente
        : prevState || tipoFiltro.cliente
        ? tipoFiltro.cliente == ''
          ? tipoFiltro.cliente
          : prevState
        : prevState
    );

    switch (tipoFiltro.tipoFiltro) {
      case 'emissao':
        setParametroFiltro({
          emissaoInicio: dataInicio,
          emissaoFim: dataFim,
          entregaInicio: '',
          entregaFim: '',
          cliente: '',
          status: appAcesso === 'EXPEDICAO' ? 'ACABAMENTO' : '',
          maquina: '',
          buscaMaquina: '',
        });
        break;

      case 'entrega':
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: dataInicio,
          entregaFim: dataFim,
          cliente: '',
          status: appAcesso === 'EXPEDICAO' ? 'ACABAMENTO' : '',
          maquina: '',
          buscaMaquina: '',
        });
        break;
      case 'cliente':
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          cliente: tipoFiltro.cliente,
          status: statusFiltroSelecionado,
          maquina: '',
          buscaMaquina: '',
        });
        break;
      case 'maquina':
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          cliente: '',
          status: '',
          maquina: tipoFiltro.maquina,
          buscaMaquina: 'SIM',
        });
        break;
      case 'status':
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          cliente: clienteSelecionado,
          status: tipoFiltro.status,
          maquina: '',
          buscaMaquina: 'SIM',
        });
        break;
      default:
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          cliente: '',
          status: appAcesso === 'EXPEDICAO' ? 'ACABAMENTO' : '',
          maquina: '',
          buscaMaquina: '',
        });
    }
  };
  const concluirProducao = (producao, qtde, volume, palete, duplicada) => {
    setLoading(true);
    api
      .put(
        '/producao/finalizar',
        {
          producao: producao,
          quantidade: qtde,
          volume: volume,
          palete: palete,
          usuario: usuarioIDLogado,
        },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(() => {
        setAtualizaFiltro(!atualizaFiltro);
        toast.success('Producão alterada com sucesso ');
        abrirDialogImprimePedido(producao);
      })
      .catch((err) => {
        toast.success('Erro ao  alterar Producao ');
        console.log(err);
        setAtualizaFiltro(!atualizaFiltro);
      })
      .finally(() => setLoading(false));
  };

  const alterarStatus = (producao, statusAtual) => {
    const etapas = [
      'AGUARDANDO',
      'RESERVA',
      'DEFINE ETAPAS',
      'ESTOQUE',
      'PRODUCAO',
      'ACABAMENTO',
      'FINALIZADO',
    ];

    let iNovoStatus = etapas.indexOf(statusAtual) - 1;
    let novoStatus = etapas[iNovoStatus];

    if (statusAtual === 'AGUARDANDO' || statusAtual === 'RESERVA') {
      toast.error('Operacão NÃO PERMITIDA! - O STATUS não permite alteração! ');
    } else {
      confirm({
        title: 'Confirma Operação',
        description: `Deseja Realmente RETROCEDER O STATUS do ${statusAtual}  para o ${novoStatus}`,
        confirmationText: 'SIM',
        cancellationText: 'NÃO',
      }).then(() => {
        setLoading(true);
        api
          .put(
            'producao/retroceder/etapas',
            {
              producao: producao,
              status: novoStatus,
              usuario: usuarioIDLogado,
            },
            {
              headers: { banco: empresaAtiva },
            }
          )
          .then(() => {
            toast.success('Producão alterada com sucesso ');
            setAtualizaFiltro(!atualizaFiltro);
          })
          .catch((err) => {
            toast.error('Erro ao  alterar Producao ');
            console.log(err);
            setAtualizaFiltro(!atualizaFiltro);
          })
          .finally(() => setLoading(false));
      });
    }
  };

  const alteraDataDeEntrega = (producao, historico, entrega, duplica) => {
    setLoading(true);
    api
      .put(
        '/producao/entrega/alterar',
        {
          producao: producao,
          historico: historico,
          entrega: entrega,
          usuario: usuarioIDLogado,
          codDuplica: duplica,
        },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(() => {
        toast.success('Data da Entrega Alterada com Sucesso ');
        setAtualizaFiltro(!atualizaFiltro);
      })
      .catch((err) => {
        toast.success('Erro ao  alterar Producao ');
        console.log(err);
        setAtualizaFiltro(!atualizaFiltro);
      })
      .finally(() => setLoading(false));
  };

  const atualizarMaquinas = (values, duplicada) => {
    setLoading(true);
    setAtualizaFiltro(!atualizaFiltro);
    api
      .put(
        '/producao/define/etapas',
        { ...values, usuario: usuarioIDLogado },
        {
          headers: {
            banco: empresaAtiva,
          },
        }
      )
      .then(() => toast.success('Maquinas Adicionadas com sucesso '))
      .catch((err) => {
        toast.error('Erro na Operacão');
        console.log(err);
      })
      .finally(() => setLoading(false), setAtualizaFiltro(!atualizaFiltro));
  };

  const enviaProducaoCarga = (
    producao,
    envia,
    venda,
    codVeiculo,
    duplicada
  ) => {
    if (codVeiculo !== 0) {
      toast.error(
        'Operacão impossível!  -  Produto entregue ou separado para a entrega '
      );
    } else {
      const mensagem = `Deseja ${
        envia === 'SIM' ? 'enviar o pedido para a' : 'retirar o pedido da '
      }  Carga`;
      confirm({
        title: 'Confirma Operação',
        description: mensagem,
        confirmationText: 'SIM',
        cancellationText: 'NÃO',
      }).then(() => {
        setLoading(true);
        api
          .put(
            '/producao/enviar/carga',
            {
              producao: producao,
              envia: envia,
              pedido: venda,
              usuario: usuarioIDLogado,
            },
            {
              headers: {
                banco: empresaAtiva,
              },
            }
          )
          .then(() => toast.success('Producão alterada com sucesso '))
          .catch((err) => {
            toast.error('Erro na Operacão');
            console.log(err);
          })
          .finally(() => {
            setAtualizaFiltro(!atualizaFiltro);
            setLoading(false);
          });
      });
    }
  };

  const alterarPrioridade = (producao, prioridade, duplica) => {
    console.log(duplica);
    confirm({
      title: 'Confirme a Operação',
      description: `DESEJA ALTERAR A PRIORIDADE`,
      confirmationText: 'SIM',
      cancellationText: 'NÃO',
    })
      .then(() => {
        setLoading(true);
        api
          .put(
            '/producao/prioridade',
            {
              producao: producao,
              usuario: usuarioIDLogado,
              prioridade: prioridade,
              codDuplica: duplica,
            },
            {
              headers: { banco: empresaAtiva },
            }
          )
          .then(() => {
            toast.success('Producão alterada com sucesso ');
            setAtualizaFiltro(!atualizaFiltro);
          })
          .catch((err) => {
            toast.success('Erro ao  alterar Producao ');
            console.log(err);
            setAtualizaFiltro(!atualizaFiltro);
          })
          .finally(() => setLoading(false));
      })
      .catch(() => {
        /* ... */
      });
  };

  const abrirDialogImprimePedido = (producao) => {
    setOpenDialogImprimePedido(true);
    setProducaoSelecionada(producao);
  };

  const fecharDialogImprimePedido = () => {
    setProducaoSelecionada(null);
    setOpenDialogImprimePedido(false);
    setAtualizaFiltro(!atualizaFiltro);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Box>
        <TitlePage title="Lista de Produções" />
        <Box>
          <Box marginLeft={'6%'}>
            <Typography variant="subtitle2">Período </Typography>
          </Box>

          <Stack
            direction={{ xs: 'row', sm: 'row', md: 'row', lg: 'row' }}
            spacing={{ xs: 1, sm: 1, md: 3 }}
            mt={2}
            mb={2}
          >
            <Box width={'4%'} display={'flex'} justifyContent={'center'}>
              <Button
                onClick={() => setAtualizaFiltro(!atualizaFiltro)}
                sx={{
                  borderRadius: '50%',
                  width: '25%',
                  height: '100%',
                  backgroundColor: 'primary.success',
                }}
              >
                <RefreshIcon sx={{ color: 'white' }} />
              </Button>
            </Box>
            <Box width={'13%'}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Emissão Inicial "
                type="date"
                variant="standard"
                size="medium"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </Box>
            <Box width={'13%'}>
              <TextField
                fullWidth
                size="medium"
                InputLabelProps={{ shrink: true }}
                label="Emissão Final"
                type="date"
                variant="standard"
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </Box>
            <Box width={'20%'}>
              <Box>
                <Typography variant="subtitle2">Filtra por Data</Typography>
              </Box>
              <NativeSelect
                value={tipoFiltro}
                fullWidth
                size="small"
                onChange={(e) =>
                  selecionaFiltro({ tipoFiltro: e.target.value })
                }
              >
                <option default value={''}>
                  Todos os Registros
                </option>
                <option value={'emissao'}>Por Emissao</option>
                <option value={'entrega'}>Por Data Entrega </option>
              </NativeSelect>
            </Box>
            <Box width={'45%'}>
              <Box>
                <Typography variant="subtitle2">Filtrar por Cliente</Typography>
              </Box>

              <FormControl fullWidth>
                <NativeSelect
                  fullWidth
                  size="small"
                  value={clienteSelecionado}
                  onChange={(e) =>
                    selecionaFiltro({
                      tipoFiltro: 'cliente',
                      cliente: e.target.value,
                    })
                  }
                >
                  <option value={''}>Todos os Registros </option>
                  {listaClientes.length > 0 ? (
                    listaClientes.map((cliente) => {
                      return (
                        <option key={cliente.codigo} value={cliente.codigo}>
                          {cliente.nome_fantasia
                            ? cliente.nome_fantasia
                            : cliente.razao_social}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </NativeSelect>
              </FormControl>
            </Box>
            <Box width={'25%'}>
              <Box>
                <Typography variant="subtitle2">Filtrar por Maquina</Typography>
              </Box>

              <FormControl fullWidth>
                <NativeSelect
                  size="small"
                  fullWidth
                  value={maquinaFiltroSelecionada}
                  onChange={(e) =>
                    selecionaFiltro({
                      tipoFiltro: 'maquina',
                      maquina: e.target.value,
                    })
                  }
                >
                  <option value={''}>Todos os Registros </option>
                  {listaMaquinas.length > 0 ? (
                    listaMaquinas.map((maquina) => {
                      return (
                        <option
                          key={maquina.maq_codigo}
                          value={maquina.maq_codigo}
                        >
                          {maquina.maq_descricao}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </NativeSelect>
              </FormControl>
            </Box>
            <Box width={'25%'}>
              <Box>
                <Typography variant="subtitle2">Filtrar por Status</Typography>
              </Box>

              <FormControl fullWidth>
                <NativeSelect
                  size="small"
                  fullWidth
                  value={statusFiltroSelecionado}
                  onChange={(e) =>
                    selecionaFiltro({
                      tipoFiltro: 'status',
                      status: e.target.value,
                    })
                  }
                >
                  <option value={''}>Todos os Registros </option>

                  <option value={'ACABAMENTO'}>ACABAMENTO</option>

                  <option value={'AGUARDANDO'}>AGUARDANDO</option>

                  <option value={'CLICHERIA'}>CLICHERIA</option>

                  <option value={'COMPRA'}>COMPRA</option>

                  <option value={'DEFINE ETAPAS'}>DEFINE ETAPAS</option>

                  <option value={'ESTOQUE'}>ESTOQUE</option>

                  <option value={'PRODUCAO'}>PRODUCAO</option>

                  <option value={'RESERVA'}>RESERVA</option>

                  <option value={'FINALIZADO'}>FINALIZADO</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </Stack>
        </Box>
        <Divider />
        {producaoSelecionada ? (
          <ImprimePedidoProducao
            open={openDialogImprimePedido}
            onClose={fecharDialogImprimePedido}
            producao={producaoSelecionada}
            tipo="concluir"
          />
        ) : (
          <></>
        )}
        <TabelaResultados
          nivelAcesso={acessos}
          listaOrdens={listaOrdens}
          concluirProducao={concluirProducao}
          alterarStatus={alterarStatus}
          usuario={usuarioIDLogado}
          alteraDataDeEntrega={alteraDataDeEntrega}
          listaMaquinas={listaMaquinas}
          atualizarMaquinas={atualizarMaquinas}
          enviaProducaoCarga={enviaProducaoCarga}
          alterarPrioridade={alterarPrioridade}
        />
      </Box>
    </>
  );
};

export default ListaProduçoes;
