import React from 'react';
import {
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Table,
  TableBody,
  Tooltip,
} from '@mui/material';
import moment from 'moment';

import PhotoIcon from '@mui/icons-material/Photo';
import { useState } from 'react';
import { GridArrowDownwardIcon, GridArrowUpwardIcon } from '@mui/x-data-grid';

import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

export default function Row(props) {
  const {
    ordem,
    visualizarImagem,
    selecionaMaquinas,
    alteraDataEntrega,
    nivelAcesso,
    enviaProducaoCarga,
    alterarStatus,
  } = props;
  const [open, setOpen] = useState(false);
  console.log(ordem);
  return (
    <>
      <TableRow
        sx={
          ordem.aguardando === 'SIM' && ordem.status === 'PRODUCAO'
            ? { bgcolor: 'primary.destaqueColor' }
            : ordem.observacao
            ? { bgcolor: 'primary.infoRow' }
            : { bgcolor: 'none' }
        }
      >
        <TableCell colSpan={2} sx={{ width: '15%', padding: 0, margin: 0 }}>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <GridArrowUpwardIcon /> : <GridArrowDownwardIcon />}
          </IconButton>
          {nivelAcesso
            .filter((item) => item.subGrupo === 'OFF_GERIR')
            .map((item, index) => {
              return (
                <span key={index} sx={{ margin: 0, padding: 0, width: '100%' }}>
                  {item.liberados.findIndex(
                    (item) => item.ENTREGA === 'ENTREGA'
                  ) >= 0 ? (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => alteraDataEntrega(ordem.producao)}
                    >
                      <Tooltip title="Altera a Data de Entrega">
                        <CalendarMonthIcon />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <></>
                  )}
                  {item.liberados.findIndex(
                    (item) => item.RETROCEDER === 'RETROCEDER'
                  ) >= 0 ? (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        alterarStatus(ordem.producao, ordem.status)
                      }
                    >
                      <Tooltip title="Retroceder Produção para uma determinada etapa">
                        <ReplyAllIcon sx={{ color: 'primary.warning' }} />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <></>
                  )}

                  {item.liberados.findIndex(
                    (item) => item.IMAGEM === 'IMAGEM'
                  ) >= 0 ? (
                    <IconButton
                      mr={1}
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        visualizarImagem(
                          ordem.opr_cd_caixa,
                          ordem.opr_cd_cliente
                        )
                      }
                    >
                      <Tooltip title="Visualizar Imagem">
                        <PhotoIcon
                          sx={{ color: 'primary.buttonColorSecondary' }}
                        />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <></>
                  )}
                  {item.liberados.findIndex(
                    (item) => item.MAQUINA === 'MAQUINA'
                  ) >= 0 ? (
                    <IconButton
                      mr={1}
                      aria-label="expand row"
                      size="small"
                      disabled={ordem.status !== 'PRODUCAO' ? true : false}
                      onClick={() => {
                        selecionaMaquinas(
                          ordem.producao,
                          ordem.etapas,
                          ordem.status,
                          ordem.terceiro
                        );
                      }}
                    >
                      <Tooltip title="Mudar Sequência de Máquinas ">
                        <PrecisionManufacturingIcon
                          sx={
                            ordem.opr_status !== 'PRODUCAO'
                              ? { color: 'primary.disabled' }
                              : { color: 'primary.main' }
                          }
                        />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <></>
                  )}
                  {item.liberados.findIndex((item) => item.CARGA === 'CARGA') >=
                  0 ? (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        enviaProducaoCarga(
                          ordem.carga === 'NAO' ? 'SIM' : 'NAO',
                          ordem.venda,
                          ordem.veiculo
                        )
                      }
                    >
                      <Tooltip title="Enviar / Retirar Pedido para Carga">
                        <LocalShippingIcon
                          sx={
                            ordem.carga !== 'SIM' && ordem.carga !== 'NAO'
                              ? { color: 'primary.disabled' }
                              : ordem.carga === 'NAO'
                              ? { color: 'primary.error' }
                              : { color: 'primary.success' }
                          }
                        />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </span>
              );
            })}
        </TableCell>
        <TableCell>{ordem.pedido}</TableCell>
        <TableCell>{ordem.status}</TableCell>
        <TableCell>
          {moment(ordem.emissao).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell>
          {moment(ordem.entrega).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell>{ordem.fantasia ? ordem.fantasia : ordem.razao}</TableCell>
        <TableCell>{ordem.cidade}</TableCell>
        <TableCell>
          {ordem.produto} ({ordem.comprimento}X{ordem.largura}X{ordem.altura})
        </TableCell>
        <TableCell>{ordem.matriz}</TableCell>
        <TableCell>{ordem.novaQuantidade}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={13}>
                      <strong>OBSERVAÇÃO: </strong> {ordem.observacao}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={13}>
                      <strong style={{ marginRight: ' 1%' }}>
                        COMPRIMENTO:
                      </strong>
                      {ordem.novoComprimento}
                      <strong style={{ marginRight: ' 1%', marginLeft: '1%' }}>
                        LARGURA:
                      </strong>
                      {ordem.novalargura}
                      <strong style={{ marginRight: ' 1%', marginLeft: '1%' }}>
                        GRAMATURA:
                      </strong>
                      {ordem.gramatura}
                      <strong style={{ marginRight: ' 1%', marginLeft: '1%' }}>
                        CHAPA:
                      </strong>
                      {ordem.chapa}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <>
                      {ordem.etapas.length > 0 ? (
                        ordem.etapas.map((etapa, index) => {
                          if (etapa.maquina) {
                            return (
                              <TableCell colSpan={2} key={index}>
                                <strong> MAQUINA {index + 1}: </strong>
                                {etapa.maquina}
                                <span
                                  style={{ color: 'red', marginLeft: '5px' }}
                                >
                                  {etapa.terceiro === 'SIM'
                                    ? `${ordem.terceiro[0].nome}  -  ${
                                        ordem.terceiro[0].entrada
                                          ? moment(
                                              ordem.terceiro[0].entrada
                                            ).format('DD/MM/YYYY')
                                          : ''
                                      } - ${
                                        ordem.terceiro[0].saida
                                          ? moment(
                                              ordem.terceiro[0].saida
                                            ).format('DD/MM/YYYY')
                                          : ''
                                      }`
                                    : ''}
                                </span>{' '}
                                -
                                <strong
                                  style={
                                    etapa.finalizado === 'SIM'
                                      ? { color: 'green' }
                                      : { color: 'red' }
                                  }
                                >
                                  ({etapa.finalizado})
                                </strong>
                              </TableCell>
                            );
                          } else {
                            return <></>;
                          }
                        })
                      ) : (
                        <></>
                      )}
                    </>
                  </TableRow>
                  <TableRow>
                    {ordem.materias ? (
                      ordem.materias.map((materia_prima, index) => {
                        return (
                          <TableCell colSpan={4} key={index}>
                            <strong
                              style={{ color: 'red', marginRight: ' 1%' }}
                            >
                              MATERIA DEFINIDA:
                            </strong>
                            {materia_prima.materia}
                            <strong
                              style={{
                                color: 'red',
                                marginLeft: '1%',
                                marginRight: '1%',
                              }}
                            >
                              QTDE CHAPAS:
                            </strong>
                            {materia_prima.quantidade}
                          </TableCell>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
