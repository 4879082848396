import Icon01 from '../../images/iconProd01.png';
import Icon02 from '../../images/iconProd02.png';
import Icon03 from '../../images/iconProd03.png';
import Icon04 from '../../images/iconProd04.png';
import Icon06 from '../../images/iconProd06.png';
import Icon07 from '../../images/iconProd07.png';
import Icon08 from '../../images/iconProd08.png';
import Icon09 from '../../images/iconProd09.png';
const largura = '22%';
export const listImages = [
  {
    color: 'primary.buttonColorMain',
    title: 'Clichêria',
    width: largura,
    icone: <img src={Icon07} alt="" width={'30%'} height={'30%'} />,
    url: '/producao/padrao/clicheria',
    acesso: 'CLICHERIA',
  },
  {
    color: 'primary.buttonColorMain',
    title: 'Materia Prima',
    width: largura,
    icone: <img src={Icon02} alt="" width={'30%'} height={'30%'} />,
    url: '/producao/padrao/reservamateria',
    acesso: 'MATERIA-PRIMA',
  },
  {
    color: 'primary.buttonColorMain',
    title: 'Etapas de Produção',
    width: largura,
    icone: <img src={Icon01} alt="" width={'30%'} height={'30%'} />,
    url: '/producao/padrao/etapas',
    acesso: 'ETAPAS DE PRODUCAO',
  },
  {
    color: 'primary.buttonColorMain',
    title: 'Estoque',
    width: largura,
    icone: <img src={Icon06} alt="" width={'30%'} height={'30%'} />,
    url: '/producao/padrao/estoque',
    acesso: 'ESTOQUE',
  },
  {
    color: 'primary.buttonColorMain',
    title: 'Linha de Produção',
    width: largura,
    icone: <img src={Icon03} alt="" width={'30%'} height={'30%'} />,
    url: '/producao/padrao/linhaproducao',
    acesso: 'LINHA DE PRODUCAO',
  },
  {
    color: 'primary.buttonColorMain',
    title: 'Acompanhamento',
    width: largura,
    icone: <img src={Icon04} alt="" width={'30%'} height={'30%'} />,
    url: '/producao/padrao/acompanhamento',
    acesso: 'ACOMPANHAMENTO',
  },
  {
    color: 'primary.buttonColorMain',
    title: 'Entregas',
    width: largura,
    icone: <img src={Icon08} alt="" width={'30%'} height={'30%'} />,
    url: '/producao/padrao/cargas',
    acesso: 'CARGA',
  },
  {
    color: 'primary.buttonColorMain',
    title: 'Consulta Matéria Prima',
    width: largura,
    icone: <img src={Icon09} alt="" width={'30%'} height={'30%'} />,
    url: '/producao/padrao/consultamateria',
    acesso: 'CONSULTA MATERIA',
  },

  //--------------------------------------OffSet-----------------------------------------------
  {
    color: 'primary.buttonColorMain',
    title: 'Artes',
    width: largura,
    icone: <img src={Icon07} alt="" width={'30%'} height={'30%'} />,
    url: '/producao/offset/artes',
    acesso: 'OFF_ARTE',
  },
  {
    color: 'primary.buttonColorMain',
    title: 'Matéria Prima',
    width: largura,
    icone: <img src={Icon02} alt="" width={'30%'} height={'30%'} />,
    url: '/producao/offset/reservamateria',
    acesso: 'OFF_MATERIA',
  },
  {
    color: 'primary.buttonColorMain',
    title: 'Gerir Produção',
    width: largura,
    icone: <img src={Icon01} alt="" width={'30%'} height={'30%'} />,
    url: '/producao/offset/gerirprod',
    acesso: 'OFF_GERIR',
  },

  {
    color: 'primary.buttonColorMain',
    title: 'Produção',
    width: largura,
    icone: <img src={Icon03} alt="" width={'30%'} height={'30%'} />,
    url: '/producao/offset/producao',
    acesso: 'OFF_PRODUCAO',
  },
  {
    color: 'primary.buttonColorMain',
    title: 'Acompanhamento',
    width: largura,
    icone: <img src={Icon04} alt="" width={'30%'} height={'30%'} />,
    url: '/producao/offset/acompanhamento',
    acesso: 'OFF_ACOMPANHAMENTO',
  },
  {
    color: 'primary.buttonColorMain',
    title: 'Entregas',
    width: largura,
    icone: <img src={Icon08} alt="" width={'30%'} height={'30%'} />,
    url: '/producao/offset/cargas',
    acesso: 'OFF_CARGA',
  },
];
