import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  TextField,
  FormControl,
  Box,
  DialogContentText,
} from '@mui/material';
import { useState } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConcluirProducao = ({
  openDialogConcluirProducao,
  onClose,
  producaoSelecionada,
  concluirProducao,
  chave,
  cliente,
  caixa,
}) => {
  const [qtde, setQtde] = useState('');
  const [volume, setVolume] = useState('');
  const [palete, setPalete] = useState('');
  console.log(chave);
  return (
    <Dialog
      open={openDialogConcluirProducao}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>FINALIZA A PRODUÇÃO</DialogTitle>
      <DialogContent dividers>
        <DialogContent dividers>
          <DialogContentText>
            DIGITE A QUANTIDADE DE CAIXAS PRODUZIDAS NA ORDEM :
            {producaoSelecionada}
          </DialogContentText>
        </DialogContent>
        <DialogContent></DialogContent>

        <Box mt={2} mb={2}>
          <FormControl fullWidth>
            <TextField
              label="QUANTIDADE "
              type="number"
              value={qtde}
              onChange={(e) => setQtde(e.target.value)}
            />
          </FormControl>
        </Box>
        <Box mt={2} mb={2}>
          <FormControl fullWidth>
            <TextField
              label="VOLUMES "
              type="number"
              value={volume}
              onChange={(e) => setVolume(e.target.value)}
            />
          </FormControl>
        </Box>
        <Box mt={2} mb={2}>
          <FormControl fullWidth>
            <TextField
              label="PALETES "
              type="number"
              value={palete}
              onChange={(e) => setPalete(e.target.value)}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button onClick={onClose}>Fechar</Button>
        <Button
          onClick={() => {
            concluirProducao(chave, cliente, caixa, qtde, volume, palete);
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConcluirProducao;
