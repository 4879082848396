import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';
export const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 250,
  padding: 0,
  margin: 0,

  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 200,
    margin: 5,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '3px solid currentColor',
    },
  },
}));

export const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  borderRadius: '20px',
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

export const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  borderRadius: '20px',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',

  justifyContent: 'center',
  color: theme.palette.common.white,
}));

export const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  borderRadius: '20px',
  right: 0,
  top: 0,
  bottom: 0,

  backgroundColor: theme.palette.common.black,
  opacity: 0.5,
  transition: theme.transitions.create('opacity'),
}));

export const ImageMarked = styled('span')(({ theme }) => ({
  height: 4,
  width: 10,

  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 10px)',
  transition: theme.transitions.create('opacity'),
}));
