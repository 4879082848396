import {
  Box,
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
  Typography,
} from '@mui/material';

export default function Etapas({
  maquina,
  listaMaquinas,
  handleChangeMaquina,
  numeroMaquina,
  etapaDescricao,
  maquinaTitulo,
  etapaTitulo,
  tercerizado,
  listaFornecedores,
  finalizado,
  prioridade,
}) {
  return (
    <Box
      sx={{
        borderBottom: 'solid 0.2px #ededed',
        boxShadow: '0.5px 0.5px #565656, 0.5px 0 0.1px #565656',
      }}
      mt={2}
      mb={2}
      display="flex"
      width={1200}
      justifyContent="space-evenly"
    >
      <Box
        sx={
          etapaDescricao
            ? { width: '60%', display: 'flex', justifyContent: 'space-between' }
            : {
                width: '35%',
                visibility: 'hidden',
                display: 'flex',
                justifyContent: 'space-between',
              }
        }
      >
        <Box sx={{ width: '30%' }}>
          <Typography variant="subtitle2">{etapaTitulo}</Typography>
          <Typography variant="subtitle1">{etapaDescricao}</Typography>
        </Box>
        <Box sx={{ width: '45%' }}>
          <FormControl fullWidth>
            <InputLabel shrink>{maquinaTitulo} </InputLabel>
            <NativeSelect
              variant="filled"
              value={maquina.maquina}
              onChange={handleChangeMaquina(`maquina${numeroMaquina}`)}
              disabled={
                tercerizado === 'SIM'
                  ? true
                  : false || finalizado === 'SIM'
                  ? true
                  : false
              }
            >
              <option value={''}>Selecione</option>
              {listaMaquinas
                ? listaMaquinas.length > 0
                  ? listaMaquinas
                      .filter((maquina) => maquina.maq_tipo === etapaDescricao)
                      .map((maquina, index) => {
                        return (
                          <option value={maquina.maq_codigo} key={index}>
                            {maquina.maq_descricao}
                          </option>
                        );
                      })
                  : ''
                : ''}
            </NativeSelect>
          </FormControl>
        </Box>
        <Box sx={{ ml: 2, width: '45%' }}>
          <FormControl fullWidth>
            <InputLabel shrink> PRIORIDADE </InputLabel>

            <NativeSelect
              value={prioridade}
              disabled={finalizado === 'SIM' ? true : false}
              onChange={handleChangeMaquina(
                `maquina${numeroMaquina}`,
                'prioridade'
              )}
            >
              <option value={0}>0 - NÃO PRODUZIR </option>
              <option value={1}>1 - Máxima Prioridade </option>
              <option value={2}>2 - Muita Prioridade</option>
              <option value={3}>3 - Moderada Prioridade</option>
              <option value={4}>4 - Pouca Prioridade</option>
              <option value={5}>5 - Sem Prioridade</option>
            </NativeSelect>
          </FormControl>
        </Box>
      </Box>{' '}
      <Box
        sx={
          tercerizado !== 'SIM'
            ? { visibility: 'hidden', width: '20%', mr: 2 }
            : { width: '20%', mr: 2 }
        }
      >
        <FormControl sx={{ width: '100%' }}>
          <InputLabel shrink>Terceiro </InputLabel>
          <NativeSelect
            fullWidth
            variant="filled"
            disabled={
              finalizado === 'SIM'
                ? true
                : false || tercerizado === 'SIM'
                ? false
                : true
            }
            onChange={handleChangeMaquina(
              `maquina${numeroMaquina}`,
              'terceiro'
            )}
            value={maquina.terceiro}
          >
            <option value={0}>Selecione</option>
            {listaFornecedores
              ? listaFornecedores.length > 0
                ? listaFornecedores.map((fornecedor, index) => {
                    return (
                      <option key={index} value={fornecedor.codigo}>
                        {fornecedor.razao_social}
                      </option>
                    );
                  })
                : ''
              : ''}
          </NativeSelect>
        </FormControl>
      </Box>
      <Box
        sx={
          tercerizado !== 'SIM'
            ? { visibility: 'hidden', width: '30%', mr: 2 }
            : { width: '30%', mr: 2 }
        }
      >
        <FormControl
          sx={{
            mr: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}
        >
          <TextField
            label="Entrada"
            value={maquina.entrada}
            type="date"
            InputLabelProps={{ shrink: true }}
            disabled={finalizado === 'SIM'}
            onChange={handleChangeMaquina(`maquina${numeroMaquina}`, 'entrada')}
          />

          <TextField
            label="Saída"
            type="date"
            value={maquina.saida}
            InputLabelProps={{ shrink: true }}
            disabled={finalizado === 'SIM'}
            onChange={handleChangeMaquina(`maquina${numeroMaquina}`, 'saida')}
          />
        </FormControl>
      </Box>
    </Box>
  );
}
