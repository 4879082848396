import * as React from 'react';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import {
  Image,
  ImageBackdrop,
  ImageButton,
  ImageMarked,
  ImageSrc,
} from './listComponents';

import history from '../../routes/history';

export default function PersonalButton({ listImages, acesso }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {listImages.map((image, index) => {
        const permitido = acesso.findIndex(
          (liberado) => liberado.subGrupo === image.acesso
        );
        if (permitido !== -1) {
          return (
            <ImageButton
              onClick={() => history.push(image.url)}
              focusRipple
              key={index}
              sx={{
                width: image.width,
                mb: 2,
                mr: 2,
                ml: 1,
              }}
            >
              <ImageSrc sx={{ backgroundColor: image.color }} />
              <ImageBackdrop className="MuiImageBackdrop-root" />
              <Image>
                <Box sx={{ width: '100%' }}> {image.icone}</Box>
                <Typography
                  component="span"
                  variant="h5"
                  color="inherit"
                  sx={{
                    fontSize: '1.5rem',
                    position: 'relative',
                    p: 0.5,
                    pt: 0,
                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                  }}
                >
                  {image.title}
                  <ImageMarked className="MuiImageMarked-root" />
                </Typography>
              </Image>
            </ImageButton>
          );
        } else {
          return <Box key={image.title} />;
        }
      })}
    </Box>
  );
}
