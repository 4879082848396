import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TablePagination,
} from '@mui/material';

import Row from './Rows/Row';
import ConcluirProducao from './ConcluirProducao/ConcluirProducao';

export default function TabelaResultados({
  listaOrdens,
  concluirProducao,
  alterarStatus,
  nivelAcesso,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openDialogConcluirProducao, setOpenDialogConcluirProducao] =
    useState(false);
  const [chaveSelecionada, setChaveSelecionada] = useState('');
  const [caixaSelecionada, setCaixaSelecionada] = useState('');
  const [clienteSelecionado, setClienteSelecionado] = useState('');
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };
  const fechadialogConcluirProducao = () => {
    setOpenDialogConcluirProducao(false);
  };
  const handleOpenDialogConcluirProducao = (chave, cliente, caixa) => {
    setOpenDialogConcluirProducao(true);
    setChaveSelecionada(chave);
    setClienteSelecionado(cliente);
    setCaixaSelecionada(caixa);
  };

  return (
    <Box>
      {chaveSelecionada ? (
        <ConcluirProducao
          openDialogConcluirProducao={openDialogConcluirProducao}
          onClose={fechadialogConcluirProducao}
          concluirProducao={concluirProducao}
          chave={chaveSelecionada}
          caixa={caixaSelecionada}
          cliente={clienteSelecionado}
        />
      ) : (
        <></>
      )}

      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              <TableCell>Pedido</TableCell>
              <TableCell>Emissão</TableCell>
              <TableCell>Entrega</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Cidade</TableCell>
              <TableCell>Caixa</TableCell>
              <TableCell>Qtde</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listaOrdens ? (
              listaOrdens.length > 0 ? (
                listaOrdens
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((ordem, index) => {
                    return (
                      <Row
                        ordem={ordem}
                        key={index}
                        concluiProducao={handleOpenDialogConcluirProducao}
                        nivelAcesso={nivelAcesso}
                        alterarStatus={alterarStatus}
                      />
                    );
                  })
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listaOrdens.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}
