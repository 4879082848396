import React from 'react';
import {
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Table,
  TableBody,
  Tooltip,
  Badge,
} from '@mui/material';
import moment from 'moment';

import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PhotoIcon from '@mui/icons-material/Photo';
import RecommendIcon from '@mui/icons-material/Recommend';
import { useState } from 'react';
import { GridArrowDownwardIcon, GridArrowUpwardIcon } from '@mui/x-data-grid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
export default function Row(props) {
  const {
    ordem,
    concluiProducao,
    alterarStatus,
    enviaProducaoCarga,
    visualizarImagem,
    alteraMaquinas,
    alteraDataEntrega,
    alteraPrioridade,
    nivelAcesso,
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        sx={
          ordem.opr_id_duplica !== 0
            ? { bgcolor: 'secondary.light' }
            : ordem.opr_obs
            ? { bgcolor: 'primary.infoRow' }
            : { bgcolor: '' }
        }
      >
        <TableCell colSpan={2} sx={{ width: '28%', padding: 0, margin: 0 }}>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <GridArrowUpwardIcon /> : <GridArrowDownwardIcon />}
          </IconButton>
          {nivelAcesso
            .filter((item) => item.subGrupo === 'ACOMPANHAMENTO')
            .map((item, index) => {
              return (
                <span key={index} sx={{ margin: 0, padding: 0, width: '100%' }}>
                  {item.liberados.findIndex(
                    (item) =>
                      item.FINALIZAR === 'FINALIZAR' &&
                      ordem.opr_status === 'ACABAMENTO'
                  ) >= 0 ? (
                    <Badge
                      badgeContent={ordem.prioridade}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      overlap="circular"
                      color={
                        ordem.prioridade === 1
                          ? 'error'
                          : ordem.prioridade === 2
                          ? 'warning'
                          : ordem.prioridade === 3
                          ? 'success'
                          : ordem.prioridade === 4
                          ? 'info'
                          : ordem.prioridade === 5
                          ? 'primary'
                          : 'primary'
                      }
                    >
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() =>
                          concluiProducao(
                            ordem.opr_codigo,
                            ordem.opr_id_duplica
                          )
                        }
                      >
                        <Tooltip title="Finaliza a Produção">
                          <RecommendIcon
                            sx={{ color: 'primary.buttonColorSecondary' }}
                          />
                        </Tooltip>
                      </IconButton>
                    </Badge>
                  ) : (
                    <></>
                  )}
                  {item.liberados.findIndex(
                    (item) =>
                      item.FINALIZAR === 'FINALIZAR' &&
                      ordem.opr_status !== 'ACABAMENTO'
                  ) >= 0 ? (
                    <Badge
                      badgeContent={ordem.prioridade}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      overlap="circular"
                      color={
                        ordem.prioridade === 1
                          ? 'error'
                          : ordem.prioridade === 2
                          ? 'warning'
                          : ordem.prioridade === 3
                          ? 'success'
                          : ordem.prioridade === 4
                          ? 'info'
                          : ordem.prioridade === 5
                          ? 'primary'
                          : 'primary'
                      }
                    >
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() =>
                          alteraPrioridade(
                            ordem.opr_codigo,
                            ordem.opr_id_duplica
                          )
                        }
                      >
                        <Tooltip title="Alterar Prioridade">
                          <RecommendIcon
                            sx={{ color: 'primary.buttonColorSecondary' }}
                          />
                        </Tooltip>
                      </IconButton>
                    </Badge>
                  ) : (
                    <></>
                  )}
                  {item.liberados.findIndex(
                    (item) => item.ENTREGA === 'ENTREGA'
                  ) >= 0 ? (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        alteraDataEntrega(
                          ordem.opr_codigo,
                          ordem.opr_id_duplica
                        )
                      }
                    >
                      <Tooltip title="Altera a Data de Entrega">
                        <CalendarMonthIcon />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <></>
                  )}
                  {item.liberados.findIndex(
                    (item) => item.RETROCEDER === 'RETROCEDER'
                  ) >= 0 ? (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        alterarStatus(ordem.opr_codigo, ordem.opr_status)
                      }
                    >
                      <Tooltip title="Retroceder Produção para uma determinada etapa">
                        <ReplyAllIcon sx={{ color: 'primary.blackButton' }} />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <></>
                  )}
                  {item.liberados.findIndex(
                    (item) => item.IMAGEM === 'IMAGEM'
                  ) >= 0 ? (
                    <IconButton
                      mr={1}
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        visualizarImagem(
                          ordem.opr_cd_caixa,
                          ordem.opr_cd_cliente
                        )
                      }
                    >
                      <Tooltip title="Visualizar Imagem">
                        <PhotoIcon sx={{ color: 'secondary.dark' }} />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <></>
                  )}
                  {item.liberados.findIndex(
                    (item) => item.MAQUINAS === 'MAQUINAS'
                  ) >= 0 ? (
                    <IconButton
                      mr={1}
                      aria-label="expand row"
                      size="small"
                      disabled={ordem.opr_status !== 'PRODUCAO' ? true : false}
                      onClick={() => {
                        alteraMaquinas(
                          ordem.opr_codigo,
                          ordem.maquinas_selecionadas,
                          ordem.opr_status
                        );
                      }}
                    >
                      <Tooltip title="Mudar Sequência de Máquinas ">
                        <PrecisionManufacturingIcon
                          sx={
                            ordem.opr_status !== 'PRODUCAO'
                              ? { color: 'primary.disabled' }
                              : { color: 'primary.main' }
                          }
                        />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <></>
                  )}
                  {item.liberados.findIndex((item) => item.CARGA === 'CARGA') >=
                  0 ? (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        enviaProducaoCarga(
                          ordem.opr_codigo,
                          ordem.carga === 'NÃO' ? 'SIM' : 'NÃO',
                          ordem.opr_cd_venda,
                          ordem.codVeiculo
                        )
                      }
                    >
                      <Tooltip title="Enviar Pedido para Carga">
                        <LocalShippingIcon
                          sx={
                            ordem.carga === 'NÃO'
                              ? { color: 'primary.error' }
                              : { color: 'primary.success' }
                          }
                        />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </span>
              );
            })}
        </TableCell>
        <TableCell>
          {ordem.opr_cd_venda} ({ordem.qt_caixas_prontas}/
          {ordem.qt_caixas_pedido})
        </TableCell>
        <TableCell>
          {ordem.clicheria === 'SIM'
            ? 'CLICHERIA'
            : ordem.em_compra !== 'NÃO' && ordem.em_compra_numero === 0
            ? 'EM COMPRA'
            : ordem.opr_status}
        </TableCell>
        <TableCell>
          {moment(ordem.opr_emissao).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell>
          {moment(ordem.opr_entrega).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell>
          {ordem.nome_fantasia ? ordem.nome_fantasia : ordem.razao_social}
        </TableCell>
        <TableCell>{ordem.cidade}</TableCell>
        <TableCell>
          {ordem.descricao} ({ordem.comprimento}X{ordem.largura}X{ordem.altura})
        </TableCell>
        <TableCell>{ordem.opr_qt_caixa}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={13}>
                      <strong>OBSERVAÇÃO: </strong> {ordem.opr_obs}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <strong>MATRIZ: </strong>
                      {ordem.matriz}
                    </TableCell>
                    <TableCell colSpan={7}>
                      <strong>CORES: </strong>
                      {ordem.cores}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={13}>
                      <span>
                        <strong>MODELO CAIXA: </strong> {ordem.modelo_caixa}
                      </span>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    {ordem.maquinas_selecionadas[0].codigo1 !== 0 ? (
                      <>
                        {ordem.maquinas_selecionadas[0].maquina1 ? (
                          <TableCell colSpan={4}>
                            <strong> MAQUINA 1: </strong>
                            {ordem.maquinas_selecionadas[0].maquina1}
                            <span style={{ color: 'red', marginLeft: '5px' }}>
                              (
                              {
                                ordem.maquinas_selecionadas[0]
                                  .maquina1Confirmado
                              }
                              )
                            </span>
                          </TableCell>
                        ) : (
                          ''
                        )}
                        {ordem.maquinas_selecionadas[0].maquina2 ? (
                          <TableCell colSpan={4}>
                            <strong> MAQUINA 2: </strong>
                            {ordem.maquinas_selecionadas[0].maquina2}
                            <span style={{ color: 'red', marginLeft: '5px' }}>
                              (
                              {
                                ordem.maquinas_selecionadas[0]
                                  .maquina2Confirmado
                              }
                              )
                            </span>
                          </TableCell>
                        ) : (
                          ''
                        )}
                        {ordem.maquinas_selecionadas[0].maquina3 ? (
                          <TableCell colsPan={4}>
                            <strong> MAQUINA 3: </strong>
                            {ordem.maquinas_selecionadas[0].maquina3}
                            <span style={{ color: 'red', marginLeft: '5px' }}>
                              (
                              {
                                ordem.maquinas_selecionadas[0]
                                  .maquina3Confirmado
                              }
                              )
                            </span>
                          </TableCell>
                        ) : (
                          ''
                        )}
                        {ordem.maquinas_selecionadas[0].maquina4 ? (
                          <TableCell colSpan={4}>
                            <strong> MAQUINA 4: </strong>
                            {ordem.maquinas_selecionadas[0].maquina4}
                            <span style={{ color: 'red', marginLeft: '5px' }}>
                              (
                              {
                                ordem.maquinas_selecionadas[0]
                                  .maquina4Confirmado
                              }
                              )
                            </span>
                          </TableCell>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      <TableCell colSpan={13}>
                        <strong> MAQUINAS:</strong> MAQUINAS AINDA NÃO
                        SELECIONADAS
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={13}>
                      <strong style={{ marginRight: ' 1%' }}>
                        COMPRIMENTO:
                      </strong>
                      {ordem.comprimento}
                      <strong style={{ marginRight: ' 1%', marginLeft: '1%' }}>
                        LARGURA:
                      </strong>
                      {ordem.largura}
                      <strong
                        style={{
                          marginRight: ' 1%',
                          marginLeft: '1%',
                        }}
                      >
                        ALTURA:
                      </strong>
                      {ordem.altura}
                      <strong style={{ marginRight: ' 1%', marginLeft: '1%' }}>
                        CHAPA:
                      </strong>
                      {ordem.tipo_chapa}
                      <strong style={{ marginRight: ' 1%', marginLeft: '1%' }}>
                        GRAMATURA:
                      </strong>
                      {ordem.gramatura}
                    </TableCell>
                  </TableRow>
                  {ordem.materia_prima_definida ? (
                    ordem.materia_prima_definida.map((materia_prima, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell colSpan={13}>
                            <strong
                              style={{ color: 'red', marginRight: ' 1%' }}
                            >
                              MATERIA DEFINIDA:
                            </strong>
                            {materia_prima.descricao}
                            <strong
                              style={{
                                color: 'red',
                                marginLeft: '1%',
                                marginRight: '1%',
                              }}
                            >
                              QTDE CHAPAS:
                            </strong>
                            {materia_prima.quantidade}
                            <strong
                              style={{
                                color: 'red',
                                marginLeft: '1%',
                                marginRight: '1%',
                              }}
                            >
                              DATA/HORA:
                            </strong>
                            {ordem.dataDefineMateria
                              ? moment(ordem.dataDefineMateria)
                                  .add(3, 'hours')
                                  .format('DD/MM/YYYY')
                              : ''}
                            -{' '}
                            {ordem.horaDefineMateria
                              ? ordem.horaDefineMateria
                              : ''}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  {ordem.riscador ? (
                    ordem.riscador.map((medida, index) => (
                      <TableRow key={(medida, index)}>
                        <TableCell
                          colSpan={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <strong>RISCADOR </strong>
                        </TableCell>
                        <TableCell colSpan={1}>{medida.medida1}</TableCell>
                        <TableCell colSpan={1}>{medida.medida2}</TableCell>
                        <TableCell colSpan={1}>{medida.medida3}</TableCell>
                        <TableCell colSpan={1}>{medida.medida4}</TableCell>
                        <TableCell colSpan={1}>{medida.medida5}</TableCell>
                        <TableCell colSpan={1}>{medida.medida6}</TableCell>
                        <TableCell colSpan={1}>{medida.medida7}</TableCell>
                        <TableCell colSpan={1}>{medida.medida8}</TableCell>
                        <TableCell colSpan={1}>{medida.medida9}</TableCell>
                        <TableCell colSpan={1}>{medida.total}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}

                  {ordem.impressora ? (
                    ordem.impressora.map((medida, index) => (
                      <TableRow key={(medida, index)}>
                        <TableCell
                          colSpan={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <strong>IMPRESSORA</strong>
                        </TableCell>
                        <TableCell colSpan={1}>{medida.medida1}</TableCell>
                        <TableCell colSpan={1}>{medida.medida2}</TableCell>
                        <TableCell colSpan={1}> {medida.medida3}</TableCell>
                        <TableCell colSpan={1}>{medida.medida4}</TableCell>
                        <TableCell colSpan={1}>{medida.medida5}</TableCell>
                        <TableCell colSpan={1}>{medida.medida6}</TableCell>
                        <TableCell colSpan={1}>{medida.medida7}</TableCell>
                        <TableCell colSpan={1}>{medida.medida8}</TableCell>
                        <TableCell colSpan={1}>{medida.medida9}</TableCell>
                        <TableCell colSpan={1}>{medida.total}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}
                  <TableRow>
                    <TableCell colsPan={12}>
                      <strong>VOLUMES: </strong> {ordem.volume}
                      <strong>PALETES: </strong>
                      {ordem.palete} <strong>QTDE. PRODUZIDA: </strong>
                      {ordem.quantidade_produzida}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
