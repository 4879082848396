import React, { createContext, useReducer } from 'react';
import api from '../api/api';
import history from '../routes/history';
import { toast } from 'react-toastify';

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

function reducer(currentState, newState) {
  return { ...currentState, ...newState };
}

function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (!context) throw new Error('useAuthState deve ser usado em AuthProvider');

  return context;
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (!context) throw new Error('useAuthDispatch must be used in AuthProvider');

  return context;
}

const initialState = {
  token: undefined,
  empresaAtiva: null,
  usuarioApp: null,
  usuarioNomeLogado: null,
  usuarioIDLogado: null,
  acessos: [],
  autenticado: false,
  error: false,
  loading: false,
  url: null,
  newThema: null,
  funcionario:null,
};

function verifyUrl(dispatch, values) {
  dispatch({ url: values.url });
}

async function handleLogin(dispatch, values) {
  try {
    dispatch({ loading: true });
    const { data } = await api.post('/auth', values, {
      headers: {
        banco: values.empresa,
      },
    });

    if (data.token) {
      api.defaults.headers.Authorization = `Bearer ${data.token}`;
      dispatch({
        codigoFuncionario:data.funcionario,
        token: data.token,
        usuarioNomeLogado: data.nome,
        usuarioApp: data.appusuario,
        appAcesso: data.appacesso,
        usuarioIDLogado: data.id,
        acessos: data.acessos,
        maquinaCodigo: data.maquina_codigo,
        empresaAtiva: values.empresa,
        autenticado: true,
        loading: false,
        veiculo: data.veiculo,
        error: false,
        url: values.url,
        newThema: values.newThema,
      });
    } else {
      throw new Error('Usuario ou senha invalidos');
    }
  } catch (error) {
    toast.error('Usuario ou senha Invalidos');

    setTimeout(() => {
      handleLogout(dispatch, values.url);
    }, 1000);
  }
}
async function carregarTema(dispatch, values) {
  await dispatch({
    token: undefined,
    usuarioNomeLogado: null,
    usuarioIDLogado: null,
    usuarioApp: null,
    acessos: [],
    empresaAtiva: null,
    maquinaCodigo: null,
    autenticado: false,
    error: false,
    veiculo: null,
    loading: false,
    url: values.url,
    newThema: values,
  });
}
async function handleCheckUser(dispatch, values) {
  try {
    dispatch({ loading: true });
    const { data } = await api.post('/usuario/consulta', values, {
      headers: {
        banco: values.empresa,
      },
    });

    if (data.codigo > 0) {
      console.log(data)
      dispatch({
        codigoFuncionario:data.funcionario,
        token: null,
        usuarioNomeLogado: data.usuario,
        usuarioIDLogado: data.codigo,
        acessos: data.acessos,
        usuarioApp: data.appusuario,
        veiculo: data.veiculo,
        empresaAtiva: values.empresa,
        autenticado: true,
        loading: false,
        error: false,
        url: values.url,
        newThema: values.newThema,
      });
      history.push('/alterasenha');
    } else {
      throw new Error('Usuario ou senha invalidos');
    }
  } catch (error) {
    toast.error('Usuario ou senha Invalidos');
    dispatch({
      codigoFuncionario:null,
      token: undefined,
      usuarioNomeLogado: null,
      usuarioIDLogado: null,
      acessos: [],
      maquinaCodigo: null,
      usuarioApp: null,
      empresaAtiva: null,
      veiculo: null,
      autenticado: false,
      error: false,
      loading: false,
      url: values.url,
      newThema: null,
    });
  }
}
async function handleLogout(dispatch, url) {
  api.defaults.headers.Authorization = undefined;
  dispatch({
    token: undefined,
    codigoFuncionario:null,
    usuarioNomeLogado: null,
    usuarioIDLogado: null,
    acessos: [],
    empresaAtiva: null,
    usuarioApp: null,
    maquinaCodigo: null,
    veiculo: null,
    autenticado: false,
    error: false,
    loading: false,
    url: url,
    newThema: null,
  });
  localStorage.clear();
  history.push(`/login/${url}`);
  history.go();
}

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

export {
  verifyUrl,
  AuthProvider,
  useAuthState,
  useAuthDispatch,
  handleLogin,
  handleCheckUser,
  handleLogout,
  carregarTema,
};
