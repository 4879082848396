import React from 'react';
import {
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Table,
  TableBody,
  Tooltip,
} from '@mui/material';

import moment from 'moment';
import PhotoIcon from '@mui/icons-material/Photo';
import RecommendIcon from '@mui/icons-material/Recommend';
import { useState } from 'react';
import { GridArrowDownwardIcon, GridArrowUpwardIcon } from '@mui/x-data-grid';

export default function Row(props) {
  const { ordem, concluiProducao, nivelAcesso, visualizarImagem } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        sx={ordem.observacao ? { bgcolor: 'primary.infoRow' } : { bgcolor: '' }}
      >
        <TableCell colSpan={2} sx={{ width: '15%', padding: 0, margin: 0 }}>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <GridArrowUpwardIcon /> : <GridArrowDownwardIcon />}
          </IconButton>
          {nivelAcesso
            .filter((item) => item.subGrupo === 'OFF_ACOMPANHAMENTO')
            .map((item, index) => {
              return (
                <span key={index} sx={{ margin: 0, padding: 0, width: '100%' }}>
                  {item.liberados.findIndex(
                    (item) => item.FINALIZAR === 'FINALIZAR'
                  ) >= 0 ? (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      disabled={
                        ordem.qtdePartesCaixaPronta === ordem.qtdePartesCaixa
                          ? false
                          : true
                      }
                      onClick={() =>
                        concluiProducao(
                          ordem.chave,
                          ordem.codCliente,
                          ordem.produto
                        )
                      }
                    >
                      <Tooltip title="Finaliza a Produção">
                        <RecommendIcon
                          sx={
                            ordem.opr_status !== 'ACABAMENTO'
                              ? { color: 'primary.disabled' }
                              : { color: 'primary.buttonColorSecondary' }
                          }
                        />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <></>
                  )}

                  {item.liberados.findIndex(
                    (item) => item.IMAGEM === 'IMAGEM'
                  ) >= 0 ? (
                    <IconButton
                      mr={1}
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        visualizarImagem(
                          ordem.opr_cd_caixa,
                          ordem.opr_cd_cliente
                        )
                      }
                    >
                      <Tooltip title="Visualizar Imagem">
                        <PhotoIcon
                          sx={{ color: 'primary.buttonColorSecondary' }}
                        />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </span>
              );
            })}
        </TableCell>
        <TableCell>
          {ordem.venda} ({ordem.qtdePartesCaixa}/{ordem.qtdePartesCaixaPronta})
        </TableCell>

        <TableCell>
          {moment(ordem.opr_emissao).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell>
          {moment(ordem.opr_entrega).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell>{ordem.fantasia ? ordem.fantasia : ordem.razao}</TableCell>
        <TableCell>{ordem.cidade}</TableCell>
        <TableCell>{ordem.caixa}</TableCell>
        <TableCell>{ordem.qtdeCaixa}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={9}>
                      <strong>OBSERVAÇÃO: </strong> {ordem.observacao}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={9}>
                      <strong>PARTES DA CAIXA:</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={1}>
                      <strong>DESCRIÇÃO</strong>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <strong>MEDIDAS</strong>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <strong>QTDE</strong>
                    </TableCell>
                    <TableCell colSpan={6} />
                  </TableRow>
                  {ordem.itens ? (
                    ordem.itens.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell colSpan={1}>{item.caixa}</TableCell>
                        <TableCell colSpan={1}>
                          {item.comprimento} x {item.largura} x {item.altura}
                        </TableCell>
                        <TableCell colSpan={1}>{item.quantidade}</TableCell>
                        <TableCell colSpan={6}> </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
