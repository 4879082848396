import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TablePagination,
} from '@mui/material';

import Row from './Rows/Row';

import Spinner from '../../../../../components/Spinner/Spinner';
import AjustaEstoque from './AjustaEstoque/AjustaEstoque';

export default function TabelaResultados({
  listaMateriaPrima,
  atualizaEstoque,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(false);
  const [materiaPrimaSelecionada, setMateriPrimaSelecionada] = useState([]);
  const [modalAjustaEstoque, setModalAjustaEstoque] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const handleCloseModalAjustaEstoque = () => {
    setModalAjustaEstoque(false);
  };
  const handleOpenModalAjustaEstoque = (materiaPrima) => {
    setMateriPrimaSelecionada(materiaPrima);
    setModalAjustaEstoque(true);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box>
      {materiaPrimaSelecionada ? (
        <AjustaEstoque
          open={modalAjustaEstoque}
          onClose={handleCloseModalAjustaEstoque}
          materiaPrimaSelecionada={materiaPrimaSelecionada}
          atualizaEstoque={atualizaEstoque}
        />
      ) : (
        <></>
      )}
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Codigo</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Tipo Chapa</TableCell>
              <TableCell>Comprimento</TableCell>
              <TableCell>Largura</TableCell>
              <TableCell>Gramatura</TableCell>
              <TableCell>Estoque Reservado</TableCell>
              <TableCell>Estoque Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listaMateriaPrima ? (
              listaMateriaPrima.length > 0 ? (
                listaMateriaPrima
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((materia, index) => {
                    return (
                      <Row
                        materia={materia}
                        key={index}
                        handleOpenModalAjustaEstoque={
                          handleOpenModalAjustaEstoque
                        }
                      />
                    );
                  })
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listaMateriaPrima.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}
