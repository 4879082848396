import React, { useState, useEffect } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';

import { CloseTwoTone, PrintTwoTone } from '@mui/icons-material';
import Spinner from '../Spinner/Spinner';
import api from '../../api/api';
import { useAuthState } from '../../contexts/AuthContext';
import FileViewer from 'react-file-viewer';
export const ImprimePedidoProducao = ({ open, onClose, producao, tipo }) => {
  const [loading, setLoading] = useState(false);
  const [urlPdf, setUrlPdf] = useState('');
  const styles = { fileView: { width: '100%' } };
  const type = 'pdf';
  const { empresaAtiva } = useAuthState();

  useEffect(() => {
    api
      .post(
        `/producao/imprime/${tipo}`,
        { producao: producao },
        {
          responseType: 'blob',
          headers: {
            banco: empresaAtiva,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        console.log(res);
        const blob = new Blob([res.data], {
          type: 'application/pdf',
        });
        const url = window.URL.createObjectURL(blob);
        setUrlPdf(url);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [producao, empresaAtiva]);

  if (!urlPdf) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="Cadastro de Clientes"
        aria-describedby="Cadastro de Clientes"
        hideBackdrop
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '95%', sm: '95%', md: '95%', lg: '50%', xl: '40%' },
            height: {
              xs: '95%',
              sm: '95%',
              md: '95%',
              lg: '95%',
              xl: '95%',
              xxl: '95%',
            },
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 1,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Spinner />
        </Box>
      </Modal>
    );
  }

  const ErrorComponent = () => {
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Cadastro de Clientes"
      aria-describedby="Cadastro de Clientes"
      hideBackdrop
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', sm: '95%', md: '95%', lg: '50%', xl: '40%' },
          height: {
            xs: '95%',
            sm: '95%',
            md: '95%',
            lg: '95%',
            xl: '95%',
            xxl: '95%',
          },
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 1,
          borderRadius: 5,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <h1>Erro ao Carregar PDF</h1>
      </Box>
    </Modal>;
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Cadastro de Clientes"
      aria-describedby="Cadastro de Clientes"
      hideBackdrop
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', sm: '95%', md: '95%', lg: '50%', xl: '40%' },
          height: {
            xs: '95%',
            sm: '95%',
            md: '95%',
            lg: '95%',
            xl: '95%',
            xxl: '95%',
          },
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 1,
          borderRadius: 5,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid',
            marginBottom: '5%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <IconButton onClick={() => window.open(urlPdf)}>
              <PrintTwoTone />
            </IconButton>
            <Typography variant="h6">Impressão de Pedido</Typography>{' '}
            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <CloseTwoTone sx={{ color: 'primary.error' }} />
            </IconButton>
          </Box>
        </Box>
        <FileViewer
          className={styles.fileView}
          fileType={type}
          filePath={urlPdf ? urlPdf : ''}
          errorComponent={ErrorComponent}
        />
      </Box>
    </Modal>
  );
};
