import React, { createContext, useState } from 'react';

export const GlobalStatesContext = createContext([false, () => {}]);

export function GlobalStates({ children }) {
  const [currentTheme, setCurrentTheme] = useState(0);

  return (
    <GlobalStatesContext.Provider value={[currentTheme, setCurrentTheme]}>
      {children}
    </GlobalStatesContext.Provider>
  );
}
