import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TablePagination,
} from '@mui/material';

import { toast } from 'react-toastify';

import { useAuthState } from '../../../../../contexts/AuthContext';
import api from '../../../../../api/api';
import Row from './Rows/Row';
import SelecionaMaquinas from './SelecionaMaquinas/SelecionaMaquinas';
import VisualizarImagem from './VisualizarImagem/VisualizarImagem';
import AlteraDataEntrega from './AlteraDataEntrega/AlteraDataEntrega';
import Spinner from '../../../../../components/Spinner/Spinner';

export default function TabelaResultados({
  listaOrdens,
  alteraDataDeEntrega,
  nivelAcesso,
  atualizarMaquinas,
  listaMaquinas,
  usuario,
  listaFornecedores,
  enviaProducaoCarga,
  alterarStatus,
  alterarPrioridade,
}) {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { empresaAtiva } = useAuthState();
  const [producaoSelecionada, setProducaoSelecionada] = useState('');
  const [openDialogVisualizaImagem, setOpenDialogVisualizaImagem] =
    useState(false);
  const [imagemSelecionada, setImagemSelecionada] = useState();
  const [openDialogAlteraData, setOpenDialogAlteraData] = useState(false);
  const [openDialogSelecionaMaquinas, setOpenDialogSelecionaMaquinas] =
    useState(true);
  const [maquinasSelecionadas, setMaquinasSelecionadas] = useState([]);
  const [statusAtual, setStatusAtual] = useState();
  const [terceiros, setTerceiros] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const fechaDialogVisualizaImagem = () => {
    setOpenDialogVisualizaImagem(false);
    setImagemSelecionada(null);
  };
  const handleOpenDialogVisualizaImagem = () => {
    setOpenDialogVisualizaImagem(true);
  };
  const handleOpenDialogAlteraData = (producao) => {
    setProducaoSelecionada(producao);
    setOpenDialogAlteraData(true);
  };

  const handleCloseDialogAlteraData = () => {
    setProducaoSelecionada('');
    setOpenDialogAlteraData(false);
  };

  const handleOpenDialogSelecionaMaquinas = (
    producao,
    etapas,
    status,
    terceiros
  ) => {
    setProducaoSelecionada(producao);
    setOpenDialogSelecionaMaquinas(true);
    setMaquinasSelecionadas(etapas);
    setStatusAtual(status);
    setTerceiros(terceiros);
  };
  const handleCloseDialogSelecionaMaquinas = () => {
    setProducaoSelecionada('');
    setOpenDialogSelecionaMaquinas(false);
    setStatusAtual('');
    setMaquinasSelecionadas([]);
    setTerceiros([]);
  };
  const functionVisualizarImagem = (caixa, cliente) => {
    setLoading(true);
    api
      .post(
        '/imagem/arte',
        { item: caixa, cliente: cliente },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(({ data }) => {
        setImagemSelecionada(data.artes[0].imagem);
      })
      .catch((err) => {
        toast.error('Imagem não encontrada ');
      })
      .finally(() => {
        handleOpenDialogVisualizaImagem();
        setLoading(false);
      });
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <Box>
      {imagemSelecionada ? (
        <VisualizarImagem
          onClose={fechaDialogVisualizaImagem}
          openDialogVisualizaImagem={openDialogVisualizaImagem}
          imagemSelecionada={imagemSelecionada}
          usuario={usuario}
        />
      ) : (
        <></>
      )}

      {producaoSelecionada ? (
        <AlteraDataEntrega
          open={openDialogAlteraData}
          openDialogAlteraData={openDialogAlteraData}
          onClose={handleCloseDialogAlteraData}
          producaoSelecionada={producaoSelecionada}
          alteraDataDeEntrega={alteraDataDeEntrega}
        />
      ) : (
        <></>
      )}
      {maquinasSelecionadas ? (
        maquinasSelecionadas.length > 0 ? (
          <SelecionaMaquinas
            open={openDialogSelecionaMaquinas}
            onClose={handleCloseDialogSelecionaMaquinas}
            listaMaquinas={listaMaquinas}
            etapas={maquinasSelecionadas}
            terceiros={terceiros}
            atualizarMaquinas={atualizarMaquinas}
            producaoSelecionada={producaoSelecionada}
            statusAtual={statusAtual}
            listaFornecedores={listaFornecedores}
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              <TableCell>Pedido</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Emissão</TableCell>
              <TableCell>Entrega</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Cidade</TableCell>
              <TableCell>Caixa</TableCell>
              <TableCell>Matriz</TableCell>
              <TableCell>Qtde</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listaOrdens ? (
              listaOrdens.length > 0 ? (
                listaOrdens
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((ordem, index) => {
                    return (
                      <Row
                        ordem={ordem}
                        key={index}
                        visualizarImagem={functionVisualizarImagem}
                        alteraDataEntrega={handleOpenDialogAlteraData}
                        nivelAcesso={nivelAcesso}
                        selecionaMaquinas={handleOpenDialogSelecionaMaquinas}
                        alterarStatus={alterarStatus}
                        enviaProducaoCarga={enviaProducaoCarga}
                      />
                    );
                  })
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listaOrdens.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          enviaProducaoCarga={enviaProducaoCarga}
        />
      </TableContainer>
    </Box>
  );
}
