import React, { useEffect, useState } from 'react';
import {
  NativeSelect,
  TextField,
  Box,
  FormControl,
  Stack,
  Divider,
  Typography,
  Button,
} from '@mui/material';
import { toast } from 'react-toastify';

import moment from 'moment';

import api from '../../../../api/api';
import { useAuthState } from '../../../../contexts/AuthContext';
import Spinner from '../../../../components/Spinner/Spinner';
import TabelaResultados from './TabelaResultados/TabelaResultados';
import TitlePage from '../../../../components/TitlePage/TitlePage';
import RefreshIcon from '@mui/icons-material/Refresh';

const Etapas = () => {
  const { empresaAtiva, usuarioIDLogado } = useAuthState();
  const [loading, setLoading] = useState(false);
  const [listaOrdens, setListaOrdens] = useState([]);
  const [listaClientes, setListaClientes] = useState([]);
  const [listaMaquinas, setListaMaquinas] = useState([]);
  const [dataInicio, setDataInicio] = useState(moment().format('YYYY-MM-DD'));
  const [dataFim, setDataFim] = useState(moment().format('YYYY-MM-DD'));
  const [tipoFiltro, setTipoFiltro] = useState('');
  const [clienteSelecionado, setClienteSelecionado] = useState();
  const [atualizaFiltro, setAtualizaFiltro] = useState(false);

  const [parametroFiltro, setParametroFiltro] = useState({
    emissaoInicio: 0,
    emissaoFim: 0,
    entregaInicio: 0,
    entregaFim: 0,
    cliente: 0,
    clicheria: 'NÃO',
    maquina: '',
    status: 'DEFINE ETAPAS',
  });

  useEffect(() => {
    api
      .get('/cliente/lista1', {
        headers: {
          banco: empresaAtiva,
        },
      })
      .then(({ data }) => {
        setListaClientes(data);
      })
      .catch(({ data }) =>
        toast.error('Erro ao buscar dados do cliente', data)
      );
  }, [empresaAtiva]);

  useEffect(() => {
    api
      .get('/producao/lista/etapas', {
        headers: {
          banco: empresaAtiva,
        },
      })
      .then(({ data }) => {
        setListaMaquinas(data);
      })
      .catch(({ data }) =>
        toast.error('Erro ao buscar dados da MateriaPrima', data)
      );
  }, [empresaAtiva]);

  useEffect(() => {
    setLoading(true);
    api
      .post('/producao/lista', parametroFiltro, {
        headers: { banco: empresaAtiva },
      })
      .then(({ data }) => setListaOrdens(data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [parametroFiltro, empresaAtiva, atualizaFiltro]);

  const selecionaFiltro = (tipoFiltro) => {
    setTipoFiltro(tipoFiltro.tipoFiltro);
    setClienteSelecionado(tipoFiltro.cliente);
    switch (tipoFiltro.tipoFiltro) {
      case 'emissao':
        setParametroFiltro({
          emissaoInicio: dataInicio,
          emissaoFim: dataFim,
          entregaInicio: 0,
          entregaFim: 0,
          cliente: 0,
          clicheria: 'NÃO',
          status: 'DEFINE ETAPAS',
          maquina: '',
        });
        break;

      case 'entrega':
        setParametroFiltro({
          emissaoInicio: 0,
          emissaoFim: 0,
          entregaInicio: dataInicio,
          entregaFim: dataFim,
          cliente: 0,
          clicheria: 'NÃO',
          status: 'DEFINE ETAPAS',
          maquina: '',
        });
        break;
      case 'cliente':
        setParametroFiltro({
          emissaoInicio: 0,
          emissaoFim: 0,
          entregaInicio: 0,
          entregaFim: 0,
          clicheria: 'NÃO',
          cliente: tipoFiltro.cliente,
          status: 'DEFINE ETAPAS',
          maquina: '',
        });
        break;
      default:
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          cliente: '',
          clicheria: 'NÃO',
          status: 'DEFINE ETAPAS',
          maquina: '',
        });
    }
  };

  const atualizarMaquinas = (values) => {
    setLoading(true);
    setAtualizaFiltro(!atualizaFiltro);
    api
      .put(
        '/producao/define/etapas',
        { ...values, usuario: usuarioIDLogado },
        {
          headers: {
            banco: empresaAtiva,
          },
        }
      )
      .then(() => toast.success('Maquinas Adicionadas com sucesso '))
      .catch((err) => {
        toast.error('Erro na Operacão');
        console.log(err);
      })
      .finally(() => setLoading(false), setAtualizaFiltro(!atualizaFiltro));
  };
  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Box>
        <TitlePage title="Definição de Etapas" />
        <Box>
          <Box marginLeft={'6%'}>
            <Typography variant="subtitle2">Período </Typography>
          </Box>

          <Stack
            direction={{ xs: 'row', sm: 'row', md: 'row', lg: 'row' }}
            spacing={{ xs: 1, sm: 1, md: 3 }}
            mt={2}
            mb={2}
          >
            <Box width={'4%'} display={'flex'} justifyContent={'center'}>
              <Button
                onClick={() => setAtualizaFiltro(!atualizaFiltro)}
                sx={{
                  borderRadius: '50%',
                  width: '25%',
                  height: '100%',
                  backgroundColor: 'primary.success',
                }}
              >
                <RefreshIcon sx={{ color: 'white' }} />
              </Button>
            </Box>
            <Box width={'13%'}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Emissão Inicial "
                type="date"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </Box>
            <Box width={'13%'}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Emissão Final"
                type="date"
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </Box>

            <Box width={'20%'}>
              <Box>
                <Typography variant="subtitle2">Filtra por Data</Typography>
              </Box>
              <NativeSelect
                value={tipoFiltro}
                fullWidth
                onChange={(e) =>
                  selecionaFiltro({ tipoFiltro: e.target.value })
                }
              >
                <option default value={''}>
                  Todos os Registros
                </option>
                <option value={'emissao'}>Por Emissao</option>
                <option value={'entrega'}>Por Data Entrega </option>
              </NativeSelect>
            </Box>
            <Box width={'45%'}>
              <Box>
                <Typography variant="subtitle2">Filtrar por Cliente</Typography>
              </Box>

              <FormControl fullWidth>
                <NativeSelect
                  fullWidth
                  value={clienteSelecionado}
                  onChange={(e) =>
                    selecionaFiltro({
                      tipoFiltro: 'cliente',
                      cliente: e.target.value,
                    })
                  }
                >
                  <option value={''}>Todos os Registros </option>
                  {listaClientes.length > 0 ? (
                    listaClientes.map((cliente) => {
                      return (
                        <option key={cliente.codigo} value={cliente.codigo}>
                          {cliente.nome_fantasia
                            ? cliente.nome_fantasia
                            : cliente.razao_social}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </NativeSelect>
              </FormControl>
            </Box>
          </Stack>
        </Box>
        <Divider />
        <TabelaResultados
          listaOrdens={listaOrdens}
          listaMaquinas={listaMaquinas}
          atualizarMaquinas={atualizarMaquinas}
        />
      </Box>
    </>
  );
};

export default Etapas;
