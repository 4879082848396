import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Box,
} from '@mui/material';
import { toast } from 'react-toastify';
import Etapas from './Etapas/Etapas';
import moment from 'moment';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelecionaMaquinas = ({
  open,
  onClose,
  producaoSelecionada,
  etapas,
  listaMaquinas,
  listaFornecedores,
  atualizarMaquinas,
  terceiros,
}) => {
  const [maquina1, setMaquina1] = useState({
    sequencia: 0,
    maquina: 0,
    funcionario: 0,
    terceiro: 0,
    entrada: '',
    saida: '',
    prioridade: 0,
  });
  const [maquina2, setMaquina2] = useState({
    sequencia: 0,
    maquina: 0,
    funcionario: 0,
    terceiro: 0,
    entrada: '',
    saida: '',
    prioridade: 0,
  });
  const [maquina3, setMaquina3] = useState({
    sequencia: 0,
    maquina: 0,
    funcionario: 0,
    terceiro: 0,
    entrada: '',
    saida: '',
    prioridade: 0,
  });
  const [maquina4, setMaquina4] = useState({
    sequencia: 0,
    maquina: 0,
    funcionario: 0,
    terceiro: 0,
    entrada: '',
    saida: '',
    prioridade: 0,
  });
  const [maquina5, setMaquina5] = useState({
    sequencia: 0,
    maquina: 0,
    funcionario: 0,
    terceiro: 0,
    entrada: '',
    saida: '',
    prioridade: 0,
  });
  const [maquina6, setMaquina6] = useState({
    sequencia: 0,
    maquina: 0,
    funcionario: 0,
    terceiro: 0,
    entrada: '',
    saida: '',
    prioridade: 0,
  });

  const [values, setValues] = useState({
    producao: 0,
    configura: [maquina1, maquina2, maquina3, maquina4, maquina5, maquina6],
  });

  useEffect(() => {
    setValues({
      producao: producaoSelecionada,
      configura: [maquina1, maquina2, maquina3, maquina4, maquina5, maquina6],
    });
  }, [
    maquina1,
    maquina2,
    maquina3,
    maquina4,
    maquina5,
    maquina6,
    producaoSelecionada,
  ]);

  useEffect(() => {
    if (etapas.length > 0) {
      const iMaquina1 = encontrarMaquinas(etapas[0].codigo);
      const iMaquina2 = encontrarMaquinas(etapas[1].codigo);
      const iMaquina3 = encontrarMaquinas(etapas[2].codigo);
      const iMaquina4 = encontrarMaquinas(etapas[3].codigo);
      const iMaquina5 = encontrarMaquinas(etapas[4].codigo);
      const iMaquina6 = encontrarMaquinas(etapas[5].codigo);

      setMaquina1({
        sequencia: 1,
        maquina: iMaquina1 ? iMaquina1.maq_codigo : 0,
        funcionario: iMaquina1 ? iMaquina1.fun_codigo : 0,

        terceiro: etapas
          ? etapas[0].terceiro === 'SIM'
            ? terceiros[0].codigo
            : 0
          : 0,
        entrada: terceiros
          ? terceiros[0].entrada
            ? moment(terceiros[0].entrada).add(3, 'hours').format('YYYY-MM-DD')
            : ''
          : '',
        saida: terceiros
          ? terceiros[0].saida
            ? moment(terceiros[0].saida).add(3, 'hours').format('YYYY-MM-DD')
            : ''
          : '',
        prioridade: etapas
          ? etapas[0].prioridade
            ? etapas[0].prioridade
            : 0
          : '',
      });
      setMaquina2({
        sequencia: 2,
        maquina: iMaquina2 ? iMaquina2.maq_codigo : 0,
        funcionario: iMaquina2 ? iMaquina2.fun_codigo : 0,
        terceiro: etapas
          ? etapas[1].terceiro === 'SIM'
            ? terceiros[0].codigo
            : 0
          : 0,
        entrada: terceiros
          ? terceiros[0].entrada
            ? moment(terceiros[0].entrada).add(3, 'hours').format('YYYY-MM-DD')
            : ''
          : '',
        saida: terceiros
          ? terceiros[0].saida
            ? moment(terceiros[0].saida).add(3, 'hours').format('YYYY-MM-DD')
            : ''
          : '',
        prioridade: etapas
          ? etapas[1].prioridade
            ? etapas[1].prioridade
            : 0
          : '',
      });
      setMaquina3({
        sequencia: 3,
        maquina: iMaquina3 ? iMaquina3.maq_codigo : 0,
        funcionario: iMaquina3 ? iMaquina3.fun_codigo : 0,
        terceiro: etapas
          ? etapas[2].terceiro === 'SIM'
            ? terceiros[0].codigo
            : 0
          : 0,
        entrada: terceiros
          ? terceiros[0].entrada
            ? moment(terceiros[0].entrada).add(3, 'hours').format('YYYY-MM-DD')
            : ''
          : '',
        saida: terceiros
          ? terceiros[0].saida
            ? moment(terceiros[0].saida).add(3, 'hours').format('YYYY-MM-DD')
            : ''
          : '',
        prioridade: etapas
          ? etapas[2].prioridade
            ? etapas[2].prioridade
            : 0
          : '',
      });
      setMaquina4({
        sequencia: 4,
        maquina: iMaquina4 ? iMaquina4.maq_codigo : 0,
        funcionario: iMaquina4 ? iMaquina4.fun_codigo : 0,
        terceiro: etapas
          ? etapas[3].terceiro === 'SIM'
            ? terceiros[0].codigo
            : 0
          : 0,
        entrada: terceiros
          ? terceiros[0].entrada
            ? moment(terceiros[0].entrada).add(3, 'hours').format('YYYY-MM-DD')
            : ''
          : '',
        saida: terceiros
          ? terceiros[0].saida
            ? moment(terceiros[0].saida).add(3, 'hours').format('YYYY-MM-DD')
            : ''
          : '',
        prioridade: etapas
          ? etapas[3].prioridade
            ? etapas[3].prioridade
            : 0
          : '',
      });

      setMaquina5({
        sequencia: 5,
        maquina: iMaquina5 ? iMaquina5.maq_codigo : 0,
        funcionario: iMaquina5 ? iMaquina5.fun_codigo : 0,
        terceiro: etapas
          ? etapas[4].terceiro === 'SIM'
            ? terceiros[0].codigo
            : 0
          : 0,
        entrada: terceiros
          ? terceiros[0].entrada
            ? moment(terceiros[0].entrada).add(3, 'hours').format('YYYY-MM-DD')
            : ''
          : '',
        saida: terceiros
          ? terceiros[0].saida
            ? moment(terceiros[0].saida).add(3, 'hours').format('YYYY-MM-DD')
            : ''
          : '',
        prioridade: etapas
          ? etapas[4].prioridade
            ? etapas[4].prioridade
            : 0
          : '',
      });
      setMaquina6({
        sequencia: 6,
        maquina: iMaquina6 ? iMaquina6.maq_codigo : 0,
        funcionario: iMaquina6 ? iMaquina6.fun_codigo : 0,
        terceiro: etapas
          ? etapas[5].terceiro === 'SIM'
            ? terceiros[0].codigo
            : 0
          : 0,
        entrada: terceiros
          ? terceiros[0].entrada
            ? moment(terceiros[0].entrada).add(3, 'hours').format('YYYY-MM-DD')
            : ''
          : '',
        saida: terceiros
          ? terceiros[0].saida
            ? moment(terceiros[0].saida).add(3, 'hours').format('YYYY-MM-DD')
            : ''
          : '',
        prioridade: etapas
          ? etapas[5].prioridade
            ? etapas[5].prioridade
            : 0
          : '',
      });
    }
  }, [etapas, listaMaquinas]);

  const encontrarMaquinas = (maquina) => {
    let maquinaEncontrada = listaMaquinas.find(
      (item) => item.maq_codigo === maquina
    );
    return maquinaEncontrada;
  };

  const validaMaquinas = () => {
    const maquina = [];
    const todosIguais = [];
    values.configura.forEach((item) => {
      if (item.maquina > 0) {
        return maquina.push(item.maquina);
      } else {
        return todosIguais.push(item.maquina);
      }
    });

    if (new Set(maquina).size !== maquina.length) {
      return new Set(maquina).size !== maquina.length;
    } else if (todosIguais.length !== 0 && maquina.length === 0) {
      return true;
    }
  };

  const handleSave = () => {
    if (validaMaquinas()) {
      toast.error(
        'Não existe maquinas selecionadas ou existem maquinas iguais selecionadas! Verifique'
      );
    } else {
      atualizarMaquinas(values);
    }
  };

  const alterarEntradaOuSaida = (maquina, tipo, valor) => {
    switch (maquina) {
      case 'maquina1':
        setMaquina1((maq) => ({
          ...maq,
          [tipo]: valor,
        }));
        break;

      case 'maquina2':
        setMaquina2((maq) => ({
          ...maq,
          [tipo]: valor,
        }));
        break;

      case 'maquina3':
        setMaquina3((maq) => ({
          ...maq,
          [tipo]: valor,
        }));
        break;

      case 'maquina4':
        setMaquina4((maq) => ({
          ...maq,
          [tipo]: valor,
        }));
        break;
      case 'maquina5':
        setMaquina5((maq) => ({
          ...maq,
          [tipo]: valor,
        }));
        break;
      case 'maquina6':
        setMaquina6((maq) => ({
          ...maq,
          [tipo]: valor,
        }));
        break;

      default:
        throw new Error('A entrada ou a saída está vazia.');
    }
  };

  const handleChangeMaquina = (prop, tipo) => (event) => {
    let maquinaSelecionada = {};
    if (event.target.value !== '0') {
      maquinaSelecionada = listaMaquinas.find((maquina) =>
        maquina.maq_codigo == event.target.value ? event.target.value : 0
      );
    } else maquinaSelecionada = {};

    if (!tipo) {
      switch (prop) {
        case 'maquina1':
          setMaquina1({
            ...maquina1,
            sequencia: 1,
            maquina: maquinaSelecionada
              ? maquinaSelecionada.maq_codigo !== 0 ||
                etapas[0].terceiro !== 'SIM'
                ? maquinaSelecionada.maq_codigo
                : 0
              : 0,
            funcionario: maquinaSelecionada
              ? maquinaSelecionada.fun_codigo
                ? maquinaSelecionada.fun_codigo
                : 0
              : 0,
            terceiro:
              etapas[0].terceiro === 'SIM' && tipo === 'terceiro'
                ? event.target.value
                : 0,
          });

          break;
        case 'maquina2':
          const newMaquina2 = {
            ...maquina2,
            sequencia: 2,
            maquina: maquinaSelecionada
              ? maquinaSelecionada.maq_codigo !== 0 ||
                etapas[1].terceiro !== 'SIM'
                ? maquinaSelecionada.maq_codigo
                : 0
              : 0,
            funcionario: maquinaSelecionada
              ? maquinaSelecionada.fun_codigo
                ? maquinaSelecionada.fun_codigo
                : 0
              : 0,
            terceiro:
              etapas[1].terceiro === 'SIM' && tipo === 'terceiro'
                ? event.target.value
                : 0,
          };
          setMaquina2(newMaquina2);

          break;
        case 'maquina3':
          const newMaquina3 = {
            ...maquina3,
            sequencia: 3,
            maquina: maquinaSelecionada
              ? maquinaSelecionada.maq_codigo !== 0 ||
                etapas[2].terceiro !== 'SIM'
                ? maquinaSelecionada.maq_codigo
                : 0
              : 0,
            funcionario: maquinaSelecionada
              ? maquinaSelecionada.fun_codigo
                ? maquinaSelecionada.fun_codigo
                : 0
              : 0,
            terceiro:
              etapas[2].terceiro === 'SIM' && tipo === 'terceiro'
                ? event.target.value
                : 0,
          };

          setMaquina3(newMaquina3);

          break;
        case 'maquina4':
          const newMaquina4 = {
            ...maquina4,
            sequencia: 4,
            maquina: maquinaSelecionada
              ? maquinaSelecionada.maq_codigo !== 0 ||
                etapas[3].terceiro !== 'SIM'
                ? maquinaSelecionada.maq_codigo
                : 0
              : 0,

            funcionario: maquinaSelecionada.fun_codigo
              ? maquinaSelecionada.fun_codigo
              : 0,
            terceiro:
              etapas[3].terceiro === 'SIM' && tipo === 'terceiro'
                ? event.target.value
                : 0,
          };
          setMaquina4(newMaquina4);

          break;
        case 'maquina5':
          setMaquina5({
            ...maquina5,
            sequencia: 5,
            maquina: maquinaSelecionada
              ? maquinaSelecionada.maq_codigo !== 0 ||
                etapas[4].terceiro !== 'SIM'
                ? maquinaSelecionada.maq_codigo
                : 0
              : 0,

            funcionario: maquinaSelecionada.fun_codigo
              ? maquinaSelecionada.fun_codigo
              : 0,
            terceiro:
              etapas[4].terceiro === 'SIM' && tipo === 'terceiro'
                ? event.target.value
                : 0,
          });

          break;
        case 'maquina6':
          setMaquina6({
            ...maquina6,
            sequencia: 6,
            maquina: maquinaSelecionada
              ? maquinaSelecionada.maq_codigo !== 0 ||
                etapas[5].terceiro !== 'SIM'
                ? maquinaSelecionada.maq_codigo
                : 0
              : 0,

            funcionario: maquinaSelecionada.fun_codigo
              ? maquinaSelecionada.fun_codigo
              : 0,
            terceiro:
              etapas[5].terceiro === 'SIM' && tipo === 'terceiro'
                ? event.target.value
                : 0,
          });

          break;
        default:
          break;
      }
    } else {
      alterarEntradaOuSaida(prop, tipo, event.target.value);
    }
  };

  const funcoesEtapas = {
    handleChangeMaquina: handleChangeMaquina,
    listaMaquinas: listaMaquinas,
    etapas: etapas,
    listaFornecedores: listaFornecedores,
  };

  const listaEtapas = [
    {
      ...funcoesEtapas,
      etapaTitulo: 'ETAPA 01',
      maquinaTitulo: 'MAQUINA 01',
      numeroMaquina: 1,
      maquina: maquina1,
      etapaDescricao: etapas[0].etapa,
      tercerizado: etapas[0].terceiro,
      finalizado: etapas[0].finalizado,
      prioridade: maquina1.prioridade,
    },
    {
      ...funcoesEtapas,
      etapaTitulo: 'ETAPA 02',
      maquinaTitulo: 'MAQUINA 02',
      numeroMaquina: 2,
      maquina: maquina2,
      etapaDescricao: etapas[1].etapa,
      tercerizado: etapas[1].terceiro,
      finalizado: etapas[1].finalizado,
      prioridade: maquina2.prioridade,
    },
    {
      ...funcoesEtapas,
      etapaTitulo: 'ETAPA 03',
      maquinaTitulo: 'MAQUINA 03',
      numeroMaquina: 3,
      maquina: maquina3,
      etapaDescricao: etapas[2].etapa,
      tercerizado: etapas[2].terceiro,
      finalizado: etapas[2].finalizado,
      prioridade: maquina3.prioridade,
    },
    {
      ...funcoesEtapas,
      etapaTitulo: 'ETAPA 04',
      maquinaTitulo: 'MAQUINA 04',
      numeroMaquina: 4,
      maquina: maquina4,
      etapaDescricao: etapas[3].etapa,
      tercerizado: etapas[3].terceiro,
      finalizado: etapas[3].finalizado,
      prioridade: maquina4.prioridade,
    },
    {
      ...funcoesEtapas,
      etapaTitulo: 'ETAPA 05',
      maquinaTitulo: 'MAQUINA 05',
      numeroMaquina: 5,
      maquina: maquina5,
      etapaDescricao: etapas[4].etapa,
      tercerizado: etapas[4].terceiro,
      finalizado: etapas[4].finalizado,
      prioridade: maquina5.prioridade,
    },
    {
      ...funcoesEtapas,
      etapaTitulo: 'ETAPA 06',
      maquinaTitulo: 'MAQUINA 06',
      numeroMaquina: 6,
      maquina: maquina6,
      etapaDescricao: etapas[5].etapa,
      tercerizado: etapas[5].terceiro,
      finalizado: etapas[5].finalizado,
      prioridade: maquina6.prioridade,
    },
  ];

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="xl"
      onClose={onClose}
    >
      <DialogTitle>
        DEFINIÇÃO DE MAQUINAS - SELECIONE AS MAQUINAS A SEREM USADAS NA
        PRODUÇÃO:
        {producaoSelecionada}
      </DialogTitle>

      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
        >
          {listaEtapas.map((etapa, index) => {
            return (
              <Etapas
                key={index}
                etapaTitulo={etapa.etapaTitulo}
                maquinaTitulo={etapa.maquinaTitulo}
                numeroMaquina={etapa.numeroMaquina}
                handleChangeMaquina={etapa.handleChangeMaquina}
                listaMaquinas={etapa.listaMaquinas}
                etapaDescricao={etapa.etapaDescricao}
                maquinasSelecionadas={etapa.maquinasSelecionadas}
                listaFornecedores={etapa.listaFornecedores}
                maquina={etapa.maquina}
                tercerizado={etapa.tercerizado}
                finalizado={etapa.finalizado}
                prioridade={etapa.prioridade}
              />
            );
          })}
        </Box>

        <Box mt={2} mb={2}></Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button
          variant="contained"
          startIcon={<CloseIcon sx={{ color: 'primary.error' }} />}
          onClick={onClose}
        >
          Fechar
        </Button>
        <Button
          variant="contained"
          startIcon={<SaveIcon sx={{ color: 'primary.success' }} />}
          onClick={() => {
            handleSave();
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelecionaMaquinas;
