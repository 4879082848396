import React, { useEffect, useState } from 'react';
import {
  NativeSelect,
  TextField,
  Box,
  Stack,
  Divider,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { toast } from 'react-toastify';
import moment from 'moment';

import { useConfirm } from 'material-ui-confirm';
import api from '../../api/api';
import Spinner from '../../components/Spinner/Spinner';
import TabelaResultados from './TabelaResultados/TabelaResultados';
import TitlePage from '../../components/TitlePage/TitlePage';
import { useAuthState } from '../../contexts/AuthContext';

export default function ConsultaLog() {
  const { usuarioIDLogado } = useAuthState();

  const [loading, setLoading] = useState(false);
  const [dataInicio, setDataInicio] = useState(moment().format('YYYY-MM-DD'));
  const [dataFim, setDataFim] = useState(moment().format('YYYY-MM-DD'));
  const [filtroDataAtivo, setFiltroDataAtivo] = useState(false);
  const [listaLogs, setListaLogs] = useState([]);
  const [listaFiltros, setListaFiltros] = useState([]);
  const [tipos, setTiposFiltro] = useState([]);
  const [operacoes, setOperacoes] = useState([]);
  const [atualizaFiltro, setAtualizaFiltro] = useState(false);
  const [tipoSelecionado, setTipoSelecionado] = useState('');
  const [operacaoSelecionada, setOperacaoSelecionada] = useState('');
  const [parametroFiltro, setParametroFiltro] = useState({
    tipo: '',
    operacao: '',
    dataInicio: moment().format('YYYY-MM-DD'),
    dataFim: moment().format('YYYY-MM-DD'),
    usuario: usuarioIDLogado,
  });
  const confirm = useConfirm();
  const { empresaAtiva } = useAuthState();
  useEffect(() => {
    setLoading(true);
    api
      .get('log/tipo', {
        headers: {
          banco: empresaAtiva,
        },
      })
      .then(({ data }) => {
        setListaFiltros(data);
        const filtredData = data.map((item) => {
          return item.tipo;
        });
        setTiposFiltro([...new Set(filtredData)]);
      })
      .catch(() => {
        toast.error('Erro ao buscar os tipos ');
      })
      .finally(() => setLoading(false));
  }, [empresaAtiva]);

  useEffect(() => {
    setParametroFiltro((prevState) => {
      return {
        ...prevState,
        tipo: tipoSelecionado,
        operacao: operacaoSelecionada,
        dataInicio: filtroDataAtivo ? dataInicio : '',
        dataFim: filtroDataAtivo ? dataFim : '',
        usuario: usuarioIDLogado,
      };
    });
  }, [
    filtroDataAtivo,
    tipoSelecionado,
    operacaoSelecionada,
    dataInicio,
    dataFim,
    atualizaFiltro,
    usuarioIDLogado,
  ]);

  useEffect(() => {
    setLoading(true);
    api
      .post('log/historico', parametroFiltro, {
        headers: {
          banco: empresaAtiva,
        },
      })
      .then(({ data }) => {
        setListaLogs(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [parametroFiltro, empresaAtiva]);

  const filtraSubTipo = (e) => {
    setTipoSelecionado(e.target.value);
    const operacoesFiltradas = listaFiltros
      .filter((item) => item.tipo === e.target.value)
      .map((item) => {
        return { operacao: item.operacao };
      });
    setOperacoes([...new Set(operacoesFiltradas)]);
    setOperacaoSelecionada('');
  };

  const ativaFiltroData = (e) => {
    setFiltroDataAtivo(e.target.checked);
  };
  if (loading) {
    return <Spinner />;
  }

  const confirmaLog = (codigo) => {
    confirm({
      title: 'Confirma a Operação',
      description: `O Log ${codigo} foi Verificado ?`,
      confirmationText: 'SIM',
      cancellationText: 'NÃO',
    })
      .then(() => {
        setLoading(true);
        api
          .post(
            '/log/verificado',
            {
              lva_codigo_log: codigo,
              lva_codigo_usuario: usuarioIDLogado,
            },
            {
              headers: {
                banco: empresaAtiva,
              },
            }
          )
          .then((res) => {
            toast.success('Log Baixado com sucesso ');
          })
          .catch((err) => {
            toast.error('Erro ao Baixar Log');
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
            setAtualizaFiltro(!atualizaFiltro);
          });
      })
      .catch(() => {
        /* ... */
      });
  };

  return (
    <>
      <Box>
        <Box>
          <TitlePage title="Logs" />
          <Box
            display={'flex'}
            flexDirection="row"
            justifyContent={'space-between'}
          >
            <Typography variant="subtitle2">Período </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filtroDataAtivo}
                    onChange={ativaFiltroData}
                  />
                }
                label="Filtrar por data"
              />
            </FormGroup>
          </Box>
          <Divider />
          <Stack
            direction={{ xs: 'row', sm: 'row', md: 'row', lg: 'row' }}
            spacing={{ xs: 1, sm: 5, md: 5 }}
            mt={2}
            mb={2}
          >
            <Box width={'50%'}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Emissão Inicial "
                type="date"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </Box>
            <Box width={'50%'}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Emissão Final"
                type="date"
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </Box>
          </Stack>
          <Box>
            <Typography variant="subtitle2">Filtrar</Typography>
          </Box>
          <Divider />
          <Stack
            direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}
            spacing={{ xs: 1, sm: 5, md: 5 }}
            mt={2}
            mb={5}
          >
            <NativeSelect
              value={tipoSelecionado}
              fullWidth
              onChange={(e) => filtraSubTipo(e)}
            >
              <option default value={''}>
                Todos os Registros
              </option>
              {tipos.length > 0 ? (
                tipos.map((filtro, index) => {
                  return <option key={index}>{filtro}</option>;
                })
              ) : (
                <></>
              )}
            </NativeSelect>
            <NativeSelect
              value={operacaoSelecionada}
              fullWidth
              onChange={(e) => setOperacaoSelecionada(e.target.value)}
            >
              <option default value={''}>
                Todos os Registros
              </option>
              {operacoes.length > 0 ? (
                operacoes.map((subFiltro, index) => {
                  return (
                    <option key={index} value={subFiltro.operacao}>
                      {subFiltro.operacao}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </NativeSelect>
          </Stack>

          <Box>
            <Typography variant="subtitle2">Filtrar por Usuário</Typography>
          </Box>
        </Box>
        <Divider />
        {listaLogs.length > 0 ? (
          <TabelaResultados listaLogs={listaLogs} confirmaLog={confirmaLog} />
        ) : (
          <Box display="flex" justifyContent="center">
            Sem Resultados
          </Box>
        )}
      </Box>
    </>
  );
}
