import React, { useEffect, useState } from 'react';
import {
  NativeSelect,
  TextField,
  Box,
  FormControl,
  Stack,
  Divider,
  Typography,
  Button,
} from '@mui/material';

import RefreshIcon from '@mui/icons-material/Refresh';
import moment from 'moment';

import { toast } from 'react-toastify';

import api from '../../../../api/api';
import Spinner from '../../../../components/Spinner/Spinner';
import TabelaResultados from './TabelaResultados/TabelaResultados';
import TitlePage from '../../../../components/TitlePage/TitlePage';
import { useAuthState } from '../../../../contexts/AuthContext';

const Acompanhamento = () => {
  const { empresaAtiva, acessos, usuarioIDLogado } = useAuthState();
  const [loading, setLoading] = useState(false);
  const [listaOrdens, setListaOrdens] = useState([]);
  const [listaClientes, setListaClientes] = useState([]);
  const [dataInicio, setDataInicio] = useState(moment().format('YYYY-MM-DD'));
  const [dataFim, setDataFim] = useState(moment().format('YYYY-MM-DD'));
  const [tipoFiltro, setTipoFiltro] = useState('');
  const [clienteSelecionado, setClienteSelecionado] = useState();
  const [atualizaFiltro, setAtualizaFiltro] = useState(false);
  const [listaMaquinas, setListaMaquinas] = useState([]);
  const [maquinaFiltroSelecionada, setMaquinaFiltroSelecionada] = useState(0);
  const [statusFiltroSelecionado, setStatusFiltroSelecionado] = useState(0);

  const [parametroFiltro, setParametroFiltro] = useState({
    emissaoInicio: '',
    emissaoFim: '',
    entregaInicio: '',
    entregaFim: '',
    cliente: '',
    status: '',
    maquina: '',
  });

  useEffect(() => {
    setLoading(true);
    api
      .get('/producao/lista/etapas', {
        headers: {
          banco: empresaAtiva,
        },
      })
      .then(({ data }) => {
        setListaMaquinas(data);
      })
      .catch(({ data }) =>
        toast.error('Erro ao buscar dados das Etapas', data)
      );
  }, [empresaAtiva]);

  useEffect(() => {
    setLoading(true);
    api
      .get('/cliente/lista1', {
        headers: {
          banco: empresaAtiva,
        },
      })
      .then(({ data }) => {
        setListaClientes(data);
      })
      .catch(({ data }) =>
        toast.error('Erro ao buscar dados do cliente', data)
      );
  }, [empresaAtiva]);

  useEffect(() => {
    setLoading(true);
    api
      .post('/producao/listaOffSin', parametroFiltro, {
        headers: { banco: empresaAtiva },
      })
      .then(({ data }) => {
        setListaOrdens(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [parametroFiltro, empresaAtiva, atualizaFiltro]);

  const selecionaFiltro = (tipoFiltro) => {
    setTipoFiltro(tipoFiltro.tipoFiltro);
    setClienteSelecionado(tipoFiltro.cliente);
    setMaquinaFiltroSelecionada(tipoFiltro.maquina);
    setStatusFiltroSelecionado(tipoFiltro.status);

    switch (tipoFiltro.tipoFiltro) {
      case 'emissao':
        setParametroFiltro({
          emissaoInicio: dataInicio,
          emissaoFim: dataFim,
          entregaInicio: '',
          entregaFim: '',
          cliente: '',
          status: '',
          maquina: '',
          buscaMaquina: '',
        });
        break;

      case 'entrega':
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: dataInicio,
          entregaFim: dataFim,
          cliente: '',
          status: '',
          maquina: '',
          buscaMaquina: '',
        });
        break;
      case 'cliente':
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          cliente: tipoFiltro.cliente,
          status: '',
          maquina: '',
          buscaMaquina: '',
        });
        break;
      case 'maquina':
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          cliente: '',
          status: '',
          maquina: tipoFiltro.maquina,
          buscaMaquina: 'SIM',
        });
        break;
      case 'status':
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          cliente: '',
          status: tipoFiltro.status,
          maquina: '',
          buscaMaquina: 'SIM',
        });
        break;
      default:
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          cliente: '',
          status: '',
          maquina: '',
          buscaMaquina: '',
        });
    }
  };
  const concluirProducao = (chave, cliente, caixa, qtde, volume, palete) => {
    setLoading(true);

    api
      .put(
        '/producao/finalizarOff',
        {
          chave: chave,
          quantidade: qtde,
          usuario: usuarioIDLogado,
          caixa: caixa,
          cliente: cliente,
          volume: volume,
          palete: palete,
        },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(() => {
        toast.success('Producão alterada com sucesso ');
        setAtualizaFiltro(!atualizaFiltro);
      })
      .catch((err) => {
        toast.success('Erro ao  alterar Producao ');
        console.log(err);
        setAtualizaFiltro(!atualizaFiltro);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Box>
        <TitlePage title="Lista de Produções" />
        <Box>
          <Box marginLeft={'6%'}>
            <Typography variant="subtitle2">Período </Typography>
          </Box>

          <Stack
            direction={{ xs: 'row', sm: 'row', md: 'row', lg: 'row' }}
            spacing={{ xs: 1, sm: 1, md: 3 }}
            mt={2}
            mb={2}
          >
            <Box width={'4%'} display={'flex'} justifyContent={'center'}>
              <Button
                onClick={() => setAtualizaFiltro(!atualizaFiltro)}
                sx={{
                  borderRadius: '50%',
                  width: '25%',
                  height: '100%',
                  backgroundColor: 'primary.success',
                }}
              >
                <RefreshIcon sx={{ color: 'white' }} />
              </Button>
            </Box>
            <Box width={'13%'}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Emissão Inicial "
                type="date"
                variant="standard"
                size="medium"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </Box>
            <Box width={'13%'}>
              <TextField
                fullWidth
                size="medium"
                InputLabelProps={{ shrink: true }}
                label="Emissão Final"
                type="date"
                variant="standard"
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </Box>
            <Box width={'20%'}>
              <Box>
                <Typography variant="subtitle2">Filtra por Data</Typography>
              </Box>
              <NativeSelect
                value={tipoFiltro}
                fullWidth
                size="small"
                onChange={(e) =>
                  selecionaFiltro({ tipoFiltro: e.target.value })
                }
              >
                <option default value={''}>
                  Todos os Registros
                </option>
                <option value={'emissao'}>Por Emissao</option>
                <option value={'entrega'}>Por Data Entrega </option>
              </NativeSelect>
            </Box>
            <Box width={'45%'}>
              <Box>
                <Typography variant="subtitle2">Filtrar por Cliente</Typography>
              </Box>

              <FormControl fullWidth>
                <NativeSelect
                  fullWidth
                  size="small"
                  value={clienteSelecionado}
                  onChange={(e) =>
                    selecionaFiltro({
                      tipoFiltro: 'cliente',
                      cliente: e.target.value,
                    })
                  }
                >
                  <option value={''}>Todos os Registros </option>
                  {listaClientes.length > 0 ? (
                    listaClientes.map((cliente, index) => {
                      return (
                        <option key={index} value={cliente.codigo}>
                          {cliente.nome_fantasia
                            ? cliente.nome_fantasia
                            : cliente.razao_social}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </NativeSelect>
              </FormControl>
            </Box>
            <Box width={'25%'}>
              <Box>
                <Typography variant="subtitle2">Filtrar por Maquina</Typography>
              </Box>

              <FormControl fullWidth>
                <NativeSelect
                  size="small"
                  fullWidth
                  value={maquinaFiltroSelecionada}
                  onChange={(e) =>
                    selecionaFiltro({
                      tipoFiltro: 'maquina',
                      maquina: e.target.value,
                    })
                  }
                >
                  <option value={''}>Todos os Registros </option>
                  {listaMaquinas.length > 0 ? (
                    listaMaquinas.map((maquina, index) => {
                      return (
                        <option key={index} value={maquina.maq_codigo}>
                          {maquina.maq_descricao}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </NativeSelect>
              </FormControl>
            </Box>
            <Box width={'25%'}>
              <Box>
                <Typography variant="subtitle2">Filtrar por Status</Typography>
              </Box>

              <FormControl fullWidth>
                <NativeSelect
                  size="small"
                  fullWidth
                  value={statusFiltroSelecionado}
                  onChange={(e) =>
                    selecionaFiltro({
                      tipoFiltro: 'status',
                      status: e.target.value,
                    })
                  }
                >
                  <option value={''}>Todos os Registros </option>

                  <option value={'ACABAMENTO'}>ACABAMENTO</option>

                  <option value={'AGUARDANDO'}>AGUARDANDO</option>

                  <option value={'DEFINE ETAPAS'}>DEFINE ETAPAS</option>

                  <option value={'ESTOQUE'}>ESTOQUE</option>

                  <option value={'PRODUCAO'}>PRODUCAO</option>

                  <option value={'RESERVA'}>RESERVA</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </Stack>
        </Box>
        <Divider />
        <TabelaResultados
          nivelAcesso={acessos}
          listaOrdens={listaOrdens}
          concluirProducao={concluirProducao}
          listaMaquinas={listaMaquinas}
        />
      </Box>
    </>
  );
};

export default Acompanhamento;
