import { useTheme } from '@mui/material';
import React from 'react';

import '../Spinner/Spinner.css';

function Spinner() {
  const theme = useTheme();

  return (
    <div className="loader" style={{ color: theme.palette.primary.main }}>
      Loading...
    </div>
  );
}

export default Spinner;
