import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  TextField,
  FormControl,
  Box,
} from '@mui/material';
import { useState } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CadCliche = ({ open, onClose, producaoSelecionada, atualizaStatus }) => {
  const [numCliche, setNumCliche] = useState('');
  const [corCliche, setCorCliche] = useState('');

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>CLICHERIA</DialogTitle>
      <DialogContent dividers sx={{ width: '300px' }}>
        <Box mt={2} mb={2}>
          <FormControl fullWidth>
            <TextField
              label="Digite o Numero do  Clichê "
              type="string"
              value={numCliche}
              onChange={(e) => setNumCliche(e.target.value)}
            />
          </FormControl>
        </Box>

        <Box mt={2} mb={2}>
          <FormControl fullWidth>
            <TextField
              label="Digite a Cor do  Clichê "
              type="string"
              value={corCliche}
              onChange={(e) => setCorCliche(e.target.value)}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button onClick={onClose}>Fechar</Button>
        <Button
          onClick={() => {
            atualizaStatus(producaoSelecionada, numCliche, corCliche);
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CadCliche;
