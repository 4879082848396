import React, { useState, useEffect } from 'react';

import {
  FormControl,
  InputLabel,
  NativeSelect,
  Typography,
  Box,
} from '@mui/material';

import { toast } from 'react-toastify';

import api from '../../api/api';
import TitlePage from '../../components/TitlePage/TitlePage';
import Spinner from '../../components/Spinner/Spinner';
import { useAuthState } from '../../contexts/AuthContext';

function Imagens() {
  const [loading, setLoading] = useState(false);
  const [listaClientes, setListaClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState('-1');
  const [listaArtes, setListaArtes] = useState([]);
  const [caixaSelecionada, setCaixaSelecionada] = useState('-1');
  const [cdCaixasSelecionada, setCdCaixaSelecionada] = useState();
  const { empresaAtiva } = useAuthState();

  useEffect(() => {
    setLoading(true);
    api
      .get('/imagem/clientes', {
        headers: {
          banco: empresaAtiva,
        },
      })
      .then(({ data }) => {
        setListaClientes(data.clientes);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Erro ao buscar dados do cliente');
      })
      .finally(() => setLoading(false));
  }, [empresaAtiva]);

  useEffect(() => {
    if (clienteSelecionado) {
      setLoading(true);
      api
        .post(
          '/imagem/artes',
          {
            cliente: clienteSelecionado,
          },
          {
            headers: {
              banco: empresaAtiva,
            },
          }
        )
        .then(({ data }) => {
          setListaArtes(data.artes);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Erro  ao buscar as artes ');
        })
        .finally(() => setLoading(false));
    }
  }, [clienteSelecionado, empresaAtiva]);

  const visualizaLayout = (e) => {
    setCdCaixaSelecionada(e.target.value);
    setLoading(true);
    api
      .post(
        '/imagem/arte',
        {
          cliente: clienteSelecionado,
          item: e.target.value,
        },
        {
          headers: {
            banco: empresaAtiva,
          },
        }
      )
      .then(({ data }) => {
        setCaixaSelecionada(data.artes[0].imagem);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const selecionaCliente = (e) => {
    setListaArtes([]);
    setCaixaSelecionada('-1');
    setCdCaixaSelecionada('-1');
    setClienteSelecionado(e);
  };
  if (loading) {
    return (
      <>
        <Spinner />
      </>
    );
  }
  return (
    <Box>
      <TitlePage title="Layouts" />
      <Box mb={5} mt={3}>
        <FormControl fullWidth>
          <InputLabel>Cliente</InputLabel>
          <NativeSelect
            size="large"
            value={clienteSelecionado}
            onChange={(e) => selecionaCliente(e.target.value)}
          >
            <option value={-1}>Nenhum Cliente Selecionado </option>;
            {listaClientes.length > 0 ? (
              listaClientes.map((cliente, index) => {
                return (
                  <option value={cliente.img_codigo_cliente} key={index}>
                    {cliente.img_razao_social}
                  </option>
                );
              })
            ) : (
              <></>
            )}
          </NativeSelect>
        </FormControl>
      </Box>
      <Box mb={5}>
        <FormControl fullWidth>
          <InputLabel>Caixa</InputLabel>
          <NativeSelect
            size="large"
            placeholder="Escolha a Caixa"
            value={cdCaixasSelecionada}
            onChange={(e) => visualizaLayout(e)}
          >
            <option value={-1}>Nenhuma Caixa Selecionada </option>;
            {listaArtes.length > 0 ? (
              listaArtes.map((imagem, index) => {
                return (
                  <option value={imagem.codCaixa} key={index}>
                    {imagem.desCaixa}
                  </option>
                );
              })
            ) : (
              <></>
            )}
          </NativeSelect>
        </FormControl>
      </Box>
      <Box>
        {caixaSelecionada !== '-1' ? (
          <img
            src={`data:image/jpg;base64,${caixaSelecionada}`}
            width="100%"
            height="100%"
            alt="Caixa"
          />
        ) : (
          <Box>
            <Typography variant="h4" textAlign={'center'}>
              {clienteSelecionado !== '-1'
                ? ' Selecione uma Caixa'
                : 'Selecione o Cliente'}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Imagens;
