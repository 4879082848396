import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Table,
  TableBody,
  Tooltip,
} from "@mui/material";
import moment from "moment";

import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

export default function Row(props) {
  const { ordem, filtrarReservaMateria } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        sx={ordem.opr_obs ? { bgcolor: "primary.infoRow" } : { bgcolor: "" }}
      >
        <TableCell colSpan={2} sx={{ width: "10%" }}>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </IconButton>
          <Tooltip title="Reserva Materia Prima">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() =>
                filtrarReservaMateria(
                  ordem.producao,
                  ordem.comprimento,
                  ordem.largura,
                  ordem.gramatura,
                  ordem.quantidade,
                  ordem.produto,
                  ordem.medidas
                )
              }
            >
              <SwapHorizontalCircleIcon sx={{ color: "primary.main" }} />
            </IconButton>
          </Tooltip>

          {ordem.cargaOff === "SIM" ? (
            <IconButton size="small">
              <Tooltip title="Enviar Pedido para Carga">
                <LocalShippingIcon sx={{ color: "primary.success" }} />
              </Tooltip>
            </IconButton>
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell>{ordem.pedido}</TableCell>
        <TableCell>
          {moment(ordem.emissao).add(3, "h").format("DD/MM/YY")}
        </TableCell>
        <TableCell>
          {moment(ordem.entrega).add(3, "h").format("DD/MM/YY")}
        </TableCell>
        <TableCell>{ordem.fantasia ? ordem.fantasia : ordem.razao}</TableCell>
        <TableCell>{ordem.produto}</TableCell>
        <TableCell>{ordem.comprimento}</TableCell>
        <TableCell>{ordem.largura}</TableCell>
        <TableCell>{ordem.gramatura}</TableCell>
        <TableCell>{ordem.matriz}</TableCell>

        <TableCell>{ordem.quantidade}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={13}>
                      <strong>OBSERVAÇÃO: </strong> {ordem.observacao}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={13}>
                      <strong>CHAPA1:</strong>
                      {ordem.chapa}
                    </TableCell>
                    <TableCell colSpan={13}>
                      <strong>CHAPA2:</strong>
                      {ordem.chapa2}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <>
                      {ordem.etapas.length > 0 ? (
                        ordem.etapas.map((etapa, index) => {
                          if (etapa.maquina) {
                            return (
                              <TableCell colSpan={2} key={index}>
                                <strong> MAQUINA {index + 1}: </strong>
                                {etapa.maquina}
                                <span
                                  style={{ color: "red", marginLeft: "5px" }}
                                >
                                  {etapa.terceiro === "SIM" ? (
                                    `${ordem.terceiro[0].nome} - ${ordem.terceiro[0].entrada} - ${ordem.terceiro[0].saida}`
                                  ) : (
                                    <strong
                                      style={
                                        etapa.finalizado === "SIM"
                                          ? { color: "green" }
                                          : { color: "red" }
                                      }
                                    >
                                      ({etapa.finalizado})
                                    </strong>
                                  )}
                                </span>
                              </TableCell>
                            );
                          } else {
                            return <></>;
                          }
                        })
                      ) : (
                        <></>
                      )}
                    </>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
