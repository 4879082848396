import React from 'react';
import {
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Table,
  TableBody,
  Tooltip,
  Badge,
} from '@mui/material';
import moment from 'moment';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PhotoIcon from '@mui/icons-material/Photo';
import { useState } from 'react';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PlayCircleFilledWhiteTwoToneIcon from '@mui/icons-material/PlayCircleFilledWhiteTwoTone';
import FactoryIcon from '@mui/icons-material/Factory';

export default function Row(props) {
  const {
    ordem,
    visualizarImagem,
    concluiProducao,
    encontrarMaquinaAtual,
    iniciaProducao,
  } = props;
  const [open, setOpen] = useState(false);
  const maquinaAtual = encontrarMaquinaAtual(ordem.maquinas_selecionadas);

  const propriedadeMaquinaAtual = `inicio_maquina${maquinaAtual}`;
  console.log('index' + maquinaAtual);

  const BtnIniciaFinalizaProducao = () => {
    if (ordem[propriedadeMaquinaAtual] === 'SIM') {
      return (
        <Tooltip title="Conclui a Produção">
          <IconButton
            size="small"
            onClick={() =>
              concluiProducao(ordem.opr_codigo, ordem.maquinas_selecionadas)
            }
          >
            <CheckCircleOutlineIcon
              sx={{ color: 'primary.buttonColorSecondary' }}
            />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Inicia a Produção">
          <IconButton
            size="small"
            onClick={() =>
              iniciaProducao(ordem.opr_codigo, ordem.opr_id_duplica)
            }
          >
            <PlayCircleFilledWhiteTwoToneIcon
              sx={{ color: 'primary.success' }}
            />
          </IconButton>
        </Tooltip>
      );
    }
  };
  return (
    <>
      <TableRow
        sx={
          ordem.opr_id_duplica !== 0
            ? { bgcolor: 'secondary.light' }
            : ordem.opr_obs
            ? { bgcolor: 'primary.infoRow' }
            : { bgcolor: '' }
        }
      >
        <TableCell colSpan={2} sx={{ width: '20%', padding: 0, margin: 0 }}>
          <IconButton
            onClick={() => setOpen(!open)}
            disabled={ordem[propriedadeMaquinaAtual] === 'NÃO'}
          >
            {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </IconButton>
          <BtnIniciaFinalizaProducao />
          <Tooltip title="Visualizar Imagem">
            <IconButton
              size="small"
              disabled={ordem[propriedadeMaquinaAtual] === 'NÃO'}
              onClick={() =>
                visualizarImagem(ordem.opr_cd_caixa, ordem.opr_cd_cliente)
              }
            >
              <PhotoIcon sx={{ color: 'primary.buttonColorSecondary' }} />
            </IconButton>
          </Tooltip>

          {ordem.carga === 'SIM' ? (
            <IconButton size="small">
              <Tooltip title="Pedido esta em carga ">
                <LocalShippingIcon sx={{ color: 'primary.success' }} />
              </Tooltip>
            </IconButton>
          ) : (
            <></>
          )}

          <Badge
            badgeContent={ordem.prioridade}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            overlap="circular"
            color={
              ordem.prioridade === 1
                ? 'error'
                : ordem.prioridade === 2
                ? 'warning'
                : ordem.prioridade === 3
                ? 'success'
                : ordem.prioridade === 4
                ? 'info'
                : ordem.prioridade === 5
                ? 'primary'
                : 'primary'
            }
          >
            <FactoryIcon color="primary" />
          </Badge>
        </TableCell>
        <TableCell>
          {ordem.opr_cd_venda} ({ordem.qt_caixas_prontas}/
          {ordem.qt_caixas_pedido})
        </TableCell>
        <TableCell>{ordem.cliche ? ordem.cliche : 0}</TableCell>
        <TableCell>
          {ordem.opr_entrega
            ? moment(ordem.opr_entrega).add(3, 'h').format('DD/MM/YY')
            : ''}
        </TableCell>
        <TableCell>
          {ordem.nome_fantasia ? ordem.nome_fantasia : ordem.razao_social}
        </TableCell>
        <TableCell>{ordem.descricao}</TableCell>
        <TableCell>{ordem.comprimento}</TableCell>
        <TableCell>{ordem.largura}</TableCell>
        <TableCell>{ordem.altura}</TableCell>
        <TableCell>{ordem.tipo_chapa}</TableCell>
        <TableCell>{ordem.gramatura}</TableCell>
        <TableCell>{ordem.opr_qt_caixa}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <strong>OBSERVAÇÃO: </strong>{' '}
                      {ordem.opr_obs ? ordem.opr_obs : ''}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>
                      <strong>MODELO CAIXA: </strong>{' '}
                      {ordem.modelo_caixa ? ordem.modelo_caixa : ''}
                    </TableCell>

                    <TableCell colSpan={10}>
                      <strong>CORES: </strong> {ordem.cores ? ordem.cores : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <strong>MATRIZ: </strong>
                      {ordem.matriz ? ordem.matriz : 0}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <strong>EMISSÃO: </strong>{' '}
                      {ordem.opr_emissao
                        ? moment(ordem.opr_emissao)
                            .add(3, 'h')
                            .format('DD/MM/YY')
                        : ''}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    {ordem.maquinas_selecionadas[0].codigo1 !== 0 ? (
                      <>
                        {ordem.maquinas_selecionadas[0].maquina1 ? (
                          <TableCell colSpan={4}>
                            <strong> MAQUINA 1: </strong>
                            {ordem.maquinas_selecionadas[0].maquina1}
                            <span style={{ color: 'red', marginLeft: '5px' }}>
                              (
                              {
                                ordem.maquinas_selecionadas[0]
                                  .maquina1Confirmado
                              }
                              )
                            </span>
                          </TableCell>
                        ) : (
                          ''
                        )}
                        {ordem.maquinas_selecionadas[0].maquina2 ? (
                          <TableCell colSpan={4}>
                            <strong> MAQUINA 2: </strong>
                            {ordem.maquinas_selecionadas[0].maquina2}
                            <span style={{ color: 'red', marginLeft: '5px' }}>
                              (
                              {
                                ordem.maquinas_selecionadas[0]
                                  .maquina2Confirmado
                              }
                              )
                            </span>
                          </TableCell>
                        ) : (
                          ''
                        )}
                        {ordem.maquinas_selecionadas[0].maquina3 ? (
                          <TableCell colSpan={4}>
                            <strong> MAQUINA 3: </strong>
                            {ordem.maquinas_selecionadas[0].maquina3}
                            <span style={{ color: 'red', marginLeft: '5px' }}>
                              (
                              {
                                ordem.maquinas_selecionadas[0]
                                  .maquina3Confirmado
                              }
                              )
                            </span>
                          </TableCell>
                        ) : (
                          ''
                        )}
                        {ordem.maquinas_selecionadas[0].maquina4 ? (
                          <TableCell colSpan={4}>
                            <strong> MAQUINA 4: </strong>
                            {ordem.maquinas_selecionadas[0].maquina4}
                            <span style={{ color: 'red', marginLeft: '5px' }}>
                              (
                              {
                                ordem.maquinas_selecionadas[0]
                                  .maquina4Confirmado
                              }
                              )
                            </span>
                          </TableCell>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      <TableCell colSpan={13}>
                        <strong> MAQUINAS:</strong> MAQUINAS AINDA NÃO
                        SELECIONADAS
                      </TableCell>
                    )}
                  </TableRow>
                  {ordem.materia_prima_definida ? (
                    ordem.materia_prima_definida.map((materia_prima, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell colSpan={13}>
                            <strong
                              style={{ color: 'red', marginRight: ' 1%' }}
                            >
                              MATERIA DEFINIDA:
                            </strong>
                            {materia_prima.descricao}
                            <strong
                              style={{
                                color: 'red',
                                marginLeft: '1%',
                                marginRight: '1%',
                              }}
                            >
                              QTDE CHAPAS:
                            </strong>
                            {materia_prima.quantidade}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  {ordem.riscador ? (
                    ordem.riscador.map((medida, index) => (
                      <TableRow key={(medida, index)}>
                        <TableCell
                          colSpan={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <strong>RISCADOR </strong>
                        </TableCell>
                        <TableCell colSpan={1}>{medida.medida1}</TableCell>
                        <TableCell colSpan={1}>{medida.medida2}</TableCell>
                        <TableCell colSpan={1}>{medida.medida3}</TableCell>
                        <TableCell colSpan={1}>{medida.medida4}</TableCell>
                        <TableCell colSpan={1}>{medida.medida5}</TableCell>
                        <TableCell colSpan={1}>{medida.medida6}</TableCell>
                        <TableCell colSpan={1}>{medida.medida7}</TableCell>
                        <TableCell colSpan={1}>{medida.medida8}</TableCell>
                        <TableCell colSpan={1}>{medida.medida9}</TableCell>
                        <TableCell colSpan={1}>{medida.total}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}

                  {ordem.impressora ? (
                    ordem.impressora.map((medida, index) => (
                      <TableRow key={(medida, index)}>
                        <TableCell
                          colSpan={1}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <strong>IMPRESSORA</strong>
                        </TableCell>
                        <TableCell colSpan={1}>{medida.medida1}</TableCell>
                        <TableCell colSpan={1}>{medida.medida2}</TableCell>
                        <TableCell colSpan={1}> {medida.medida3}</TableCell>
                        <TableCell colSpan={1}>{medida.medida4}</TableCell>
                        <TableCell colSpan={1}>{medida.medida5}</TableCell>
                        <TableCell colSpan={1}>{medida.medida6}</TableCell>
                        <TableCell colSpan={1}>{medida.medida7}</TableCell>
                        <TableCell colSpan={1}>{medida.medida8}</TableCell>
                        <TableCell colSpan={1}>{medida.medida9}</TableCell>
                        <TableCell colSpan={1}>{medida.total}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
