import { useState } from 'react';
import {
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
  Collapse,
  Box,
  Table,
  TableBody,
} from '@mui/material';
import moment from 'moment';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PhotoIcon from '@mui/icons-material/Photo';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TimerIcon from '@mui/icons-material/Timer';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

export default function Row(props) {
  const {
    ordem,
    abrirDialogCadCliche,
    abrirDialogVisualizarImagem,
    abrirDialogAdiarCliche,
    enviarGravacao,
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        sx={
          ordem.emGravacao === 'SIM'
            ? { bgcolor: 'primary.destaqueColor' }
            : ordem.observacao
            ? { bgcolor: 'primary.infoRow' }
            : { bgcolor: '' }
        }
      >
        <TableCell colSpan={2} sx={{ width: '16%', padding: 0, margin: 0 }}>
          <IconButton
            mr={3}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </IconButton>
          <Tooltip title="Finalizar Arte">
            <IconButton
              sx={{ color: 'primary.main' }}
              onClick={() =>
                abrirDialogCadCliche(
                  ordem.chave,
                  ordem.venda,
                  ordem.produto,
                  ordem.matriz,
                  ordem.cores
                )
              }
            >
              <NoteAddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Visualizar Imagem">
            <IconButton
              mr={3}
              aria-label="expand row"
              size="small"
              onClick={() =>
                abrirDialogVisualizarImagem(ordem.cdCaixa, ordem.cdCliente)
              }
            >
              <PhotoIcon sx={{ color: 'primary.buttonColorSecondary' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Adiar Arte">
            <IconButton
              mr={3}
              aria-label="expand row"
              size="small"
              onClick={() =>
                abrirDialogAdiarCliche(ordem.chave, ordem.venda, ordem.produto)
              }
            >
              <TimerIcon
                sx={
                  ordem.cliche_atraso === 'SIM'
                    ? { color: 'primary.error' }
                    : { color: 'primary.buttonColorSecondary' }
                }
              />
            </IconButton>
          </Tooltip>
          {ordem.carga === 'SIM' ? (
            <IconButton aria-label="expand row" size="small">
              <Tooltip title="Pedido em Carga ">
                <LocalShippingIcon
                  sx={
                    ordem.carga === 'NÃO'
                      ? { color: 'primary.error' }
                      : { color: 'primary.success' }
                  }
                />
              </Tooltip>
            </IconButton>
          ) : (
            <></>
          )}

          <Tooltip title="Enviar Gravação">
            <IconButton
              mr={3}
              aria-label="expand row"
              size="small"
              onClick={() =>
                enviarGravacao(ordem.chave, ordem.venda, ordem.produto)
              }
            >
              <DocumentScannerIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>{ordem.venda}</TableCell>
        <TableCell>
          {moment(ordem.emissao).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell>
          {moment(ordem.entrega).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell>{ordem.fantasia ? ordem.fantasia : ordem.razao}</TableCell>
        <TableCell>{ordem.caixa}</TableCell>
        <TableCell>{ordem.matriz}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>PREVISÃO: </strong>
                      {ordem.arteMotivoPrevisao
                        ? moment(ordem.arteMotivoPrevisao)
                            .add(3, 'h')
                            .format('DD/MM/YY')
                        : ''}
                    </TableCell>
                    <TableCell>
                      <strong>MOTIVO: </strong>
                      {ordem.arteMotivoAtraso}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={13}>
                      <strong>OBSERVAÇÃO: </strong> {ordem.observacao}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
