import React from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { useState } from 'react';

export default function Row(props) {
  const { materia, handleOpenModalAjustaEstoque } = props;
  console.log(materia);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton onClick={() => handleOpenModalAjustaEstoque(materia)}>
            <FactCheckIcon color="primary.action" />
          </IconButton>
        </TableCell>
        <TableCell>{materia.codigo}</TableCell>
        <TableCell>{materia.descricao}</TableCell>
        <TableCell>{materia.descricao_tipo}</TableCell>
        <TableCell>{materia.comprimento}</TableCell>
        <TableCell>{materia.largura}</TableCell>
        <TableCell>{materia.gramatura}</TableCell>
        <TableCell>{materia.estoque_reservado}</TableCell>
        <TableCell>{materia.estoque_total}</TableCell>
      </TableRow>
    </>
  );
}
