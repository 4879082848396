export const filtroMateriaPrima = async (
  listaMateriasPrima,
  comprimento,
  largura,
  gramatura,
  qtdPedido,
  divisorComprimento,
  divisorLargura,
  coeficienteCaixa
) => {
  const newListMateriaPrima = listaMateriasPrima

    .map((materiaPrima) => {
      //mapeio o array buscando os elementos que encaixem nas condiçoes

      if (
        materiaPrima.comprimento >= comprimento &&
        materiaPrima.largura >= largura &&
        materiaPrima.gramatura == gramatura
      ) {
        //se eles se encaixam nas condicoes calculo a perda
        let qtdPorChapa =
          parseInt(
            materiaPrima.comprimento / (comprimento / divisorComprimento)
          ) * parseInt(materiaPrima.largura / (largura / divisorLargura));

        let qtdChapas = Math.ceil(qtdPedido / qtdPorChapa);
        let perda =
          100 *
          (1 -
            (qtdPorChapa * comprimento * largura) /
              (materiaPrima.largura * materiaPrima.comprimento));
        return {
          ...materiaPrima,
          perda: perda,
          qtde_caixas_chapa: qtdPorChapa * coeficienteCaixa,
          coeficiente: coeficienteCaixa,
          qtde_chapas: qtdChapas,
        }; // retorno o array com os elementos + a perda
      } else {
        return 0;
      }
    })
    .filter((item) => item !== 0) // elimino os items que nao condiz com a regra
    .sort((x, y) => {
      // faco a ordenacao dos elementos por ordem de perda da menor para a maior
      if (x.perda < y.perda) {
        return -1;
      } else if (x.perda > y.perda) {
        return 1;
      } else {
        return 0;
      }
    });

  return await newListMateriaPrima;
};

/*-------------------------------------------------------------------------------------------*/
/*                                  OFFSET                                                   */
/*-------------------------------------------------------------------------------------------*/

// Função para cálculos relacionados a chapas
function calcularChapas(
  materiaPrima,
  corteLargura,
  corteComprimento,
  gramatura,
  qtdCaixasPedido,
  caixasPorCorte
) {
  if (materiaPrima.comprimento >= 0 && materiaPrima.largura >= 0) {
    const qtdChapaPorCorte =
      parseInt((materiaPrima.comprimento / corteComprimento) * 1) *
      parseInt((materiaPrima.largura / corteLargura) * 1);

    const qtdChapas = Math.floor(qtdCaixasPedido / qtdChapaPorCorte);
    /* const perda =
    100 *
    (1 -
      (qtdPorChapa * comprimento * largura) /
        (materiaPrima.largura * materiaPrima.comprimento));
*/
    //const areaDoCorte = (corteComprimento / 1000) * (corteLargura / 1000);

    //const pesoDaChapa = areaDoCorte * (materiaPrima.gramatura / 1000);
    //const kilo = parseFloat((qtdChapas / caixasPorCorte) * pesoDaChapa);

    return {
      qtde_caixas_chapa: qtdChapaPorCorte,
      qtde_chapas: qtdChapas | 0,
      kilo: 0,
    };
  } else {
    // Se a condição não for atendida, marcar para exclusão
    return { excluir: "sim" };
  }
}

// Função para cálculos relacionados a bobinas

function calcularBobinas(
  materiaPrima,
  largura,
  comprimento,
  gramatura,
  qtdCaixasPedido,
  caixasPorCorte
) {
  if (materiaPrima.largura > 0) {
    const qtdeCaixasLargura = Math.floor(materiaPrima.largura / largura);
    const qtdeBobina =
      ((comprimento / 1000) * qtdCaixasPedido) /
      caixasPorCorte /
      qtdeCaixasLargura;
    const areaBobina = (largura / 1000) * qtdeBobina;
    const kiloBobina = areaBobina * (materiaPrima.gramatura / 1000);

    return {
      kilo: kiloBobina.toFixed(2) | 0,
      qtde_caixas_chapa: 0,
      qtde_chapas: 0,
    };
  } else {
    return { excluir: "sim" };
  }
}

export const filtraMateriaPrimaOffSet = async (
  listaMateriasPrima,
  corteComprimento,
  corteLargura,
  gramatura,
  qtdCaixasPedido,
  caixasPorCorte
) => {
  const newListMateriaPrima = listaMateriasPrima
    .map((materiaPrima) => {
      if (materiaPrima.comprimento == 0) {
        return {
          ...materiaPrima,
          ...calcularBobinas(
            materiaPrima,
            corteLargura,
            corteComprimento,
            gramatura,
            qtdCaixasPedido,
            caixasPorCorte
          ),
        };
      }

      if (materiaPrima.comprimento > 0) {
        return {
          ...materiaPrima,
          ...calcularChapas(
            materiaPrima,
            corteLargura,
            corteComprimento,
            gramatura,
            qtdCaixasPedido,
            caixasPorCorte
          ),
        };
      }
      return null;
    })
    .filter((item) => item.excluir !== "sim")
    .sort((x, y) => x.largura - y.largura);

  return await newListMateriaPrima;
};
