import React, { useEffect, useState } from 'react';
import {
  NativeSelect,
  TextField,
  Box,
  FormControl,
  Stack,
  Divider,
  Typography,
  Button,
} from '@mui/material';

import { toast } from 'react-toastify';
import moment from 'moment';

import api from '../../../../api/api';
import { useAuthState } from '../../../../contexts/AuthContext';
import Spinner from '../../../../components/Spinner/Spinner';
import TabelaResultados from './TabelaResultados/TabelaResultados';
import TitlePage from '../../../../components/TitlePage/TitlePage';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useConfirm } from 'material-ui-confirm';

const Cargas = () => {
  const { empresaAtiva, veiculo, usuarioIDLogado } = useAuthState();

  const confirm = useConfirm();
  const [loading, setLoading] = useState(false);
  const [listaOrdens, setListaOrdens] = useState([]);
  const [listaClientes, setListaClientes] = useState([]);
  const [listaVeiculos, setListaVeiculos] = useState([]);

  const [dataInicio, setDataInicio] = useState(moment().format('YYYY-MM-DD'));
  const [dataFim, setDataFim] = useState(moment().format('YYYY-MM-DD'));
  const [tipoFiltro, setTipoFiltro] = useState('');
  const [clienteSelecionado, setClienteSelecionado] = useState();
  const [veiculoSelecionado, setVeiculoSelecionado] = useState();
  const [atualizaFiltro, setAtualizaFiltro] = useState(false);

  const [parametroFiltro, setParametroFiltro] = useState({
    emissaoInicio: '',
    emissaoFim: '',
    entregaInicio: '',
    entregaFim: '',
    cliente: '',
    status: '',
    veiculo: veiculo,
    maquina: '',
  });

  useEffect(() => {
    setVeiculoSelecionado(veiculo);
  }, [veiculo]);

  useEffect(() => {
    setLoading(true);
    api
      .post('/producao/lista/cargaOff', parametroFiltro, {
        headers: { banco: empresaAtiva },
      })
      .then(({ data }) => {
        setListaOrdens(data);
      })
      .catch((err) => {
        toast.error(err);
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [parametroFiltro, empresaAtiva, atualizaFiltro]);

  useEffect(() => {
    setLoading(true);
    api
      .get('/cliente/lista1', {
        headers: {
          banco: empresaAtiva,
        },
      })
      .then(({ data }) => {
        setListaClientes(data);
      })
      .catch(({ data }) => toast.error('Erro ao buscar dados do cliente', data))
      .finally(() => setLoading(false));
  }, [empresaAtiva]);

  useEffect(() => {
    setLoading(true);
    api
      .get('/producao/lista/veiculo', {
        headers: {
          banco: empresaAtiva,
        },
      })
      .then(({ data }) => {
        setListaVeiculos(data);
      })
      .catch(({ data }) => toast.error('Erro ao buscar dados do cliente', data))
      .finally(() => setLoading(false));
  }, [empresaAtiva]);

  const selecionaFiltro = (tipoFiltro) => {
    setTipoFiltro(tipoFiltro.tipoFiltro);
    setClienteSelecionado(tipoFiltro.cliente);
    setVeiculoSelecionado(tipoFiltro.veiculo);
    switch (tipoFiltro.tipoFiltro) {
      case 'emissao':
        setParametroFiltro({
          emissaoInicio: dataInicio,
          emissaoFim: dataFim,
          entregaInicio: '',
          entregaFim: '',
          cliente: '',
          veiculo: veiculo,
          status: '',
          maquina: '',
        });
        break;

      case 'entrega':
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: dataInicio,
          entregaFim: dataFim,
          cliente: '',
          veiculo: veiculo,
          status: '',
          maquina: '',
        });
        break;
      case 'cliente':
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          veiculo: 0,
          cliente: tipoFiltro.cliente,
          status: '',
          maquina: '',
        });
        break;
      case 'veiculo':
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          veiculo: tipoFiltro.veiculo,
          cliente: '',
          status: ' ',
          maquina: '',
        });
        break;
      default:
        setParametroFiltro({
          emissaoInicio: '',
          emissaoFim: '',
          entregaInicio: '',
          entregaFim: '',
          veiculo: veiculo,
          cliente: '',
          status: '',
          maquina: '',
        });
    }
  };
  const separarCarga = (venda, veiculo, qtdeProntos) => {
    if (qtdeProntos === 0) {
      toast.error(
        'Operação impossível! -  Não existe pedidos prontos para a entrega'
      );
    } else if (veiculo === '') {
      toast.error(
        'Operação impossível! -  Selecione um veículo para enviar a carga '
      );
    } else {
      setLoading(true);
      api
        .put(
          '/producao/define/veiculo',
          {
            pedido: venda,
            veiculo: veiculo,
            data: moment().format('YYYY-MM-DD'),
            usuario: usuarioIDLogado,
          },
          {
            headers: { banco: empresaAtiva },
          }
        )
        .then(() => {
          toast.success('Producão alterada com sucesso ');
          setAtualizaFiltro(!atualizaFiltro);
        })
        .catch((err) => {
          toast.error('Erro ao  alterar Producao ');
          setAtualizaFiltro(!atualizaFiltro);
        })
        .finally(() => setLoading(false));
    }
  };

  const confirmaCargaVeiculo = (venda, veiculo) => {
    confirm({
      title: 'Confirme a Operação',
      description: 'Deseja confirmar a entrega desse Pedido ',
      confirmationText: 'SIM',
      cancellationText: 'NÃO',
    }).then(() => {
      setLoading(true);
      api
        .put(
          '/producao/confirma/carga',
          {
            pedido: venda,
            veiculo: veiculo,
            usuario: usuarioIDLogado,
          },
          {
            headers: { banco: empresaAtiva },
          }
        )
        .then(() => {
          toast.success('Producão alterada com sucesso ');
          setAtualizaFiltro(!atualizaFiltro);
        })
        .catch((err) => {
          toast.error('Erro ao  alterar Producao ');
          console.log(err);
          setAtualizaFiltro(!atualizaFiltro);
        })
        .finally(() => setLoading(false));
    });
  };

  const removeCargaVeiculo = (pedido, veiculo) => {
    confirm({
      title: 'Confirme a Operação',
      description: 'Deseja retirar esse pedido da entrega ',
      confirmationText: 'SIM',
      cancellationText: 'NÃO',
    }).then(() => {
      setLoading(true);
      api
        .put(
          '/producao/cancela/veiculo',
          {
            pedido: pedido,
            veiculo: veiculo,
            usuario: usuarioIDLogado,
          },
          {
            headers: { banco: empresaAtiva },
          }
        )
        .then(() => {
          toast.success('Producão alterada com sucesso ');
          setAtualizaFiltro(!atualizaFiltro);
        })
        .catch((err) => {
          toast.error('Erro ao  alterar Producao ');
          console.log(err);
          setAtualizaFiltro(!atualizaFiltro);
        })
        .finally(() => setLoading(false));
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Box>
        <TitlePage title="Gestão de Entregas" />
        <Box>
          <Box marginLeft={'6%'}>
            <Typography variant="subtitle2">Período </Typography>
          </Box>

          <Stack
            direction={{ xs: 'row', sm: 'row', md: 'row', lg: 'row' }}
            spacing={{ xs: 1, sm: 1, md: 3 }}
            mt={2}
            mb={2}
          >
            <Box width={'4%'} display={'flex'} justifyContent={'center'}>
              <Button
                onClick={() => setAtualizaFiltro(!atualizaFiltro)}
                sx={{
                  borderRadius: '50%',
                  width: '25%',
                  height: '100%',
                  backgroundColor: 'primary.success',
                }}
              >
                <RefreshIcon sx={{ color: 'white' }} />
              </Button>
            </Box>
            <Box width={'13%'}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Emissão Inicial "
                disabled={veiculo > 0}
                type="date"
                size="medium"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </Box>
            <Box width={'13%'}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Emissão Final"
                disabled={veiculo > 0}
                type="date"
                size="medium"
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </Box>
            <Box width={'20%'}>
              <Box>
                <Typography variant="subtitle2">Filtra por Data</Typography>
              </Box>
              <NativeSelect
                value={tipoFiltro}
                disabled={veiculo > 0}
                fullWidth
                onChange={(e) =>
                  selecionaFiltro({ tipoFiltro: e.target.value })
                }
              >
                <option default value="">
                  Todos os Registros
                </option>
                <option value="emissao">Por Emissao</option>
                <option value="entrega">Por Data Entrega </option>
              </NativeSelect>
            </Box>
            <Box width={'35%'}>
              <Box>
                <Typography variant="subtitle2">Filtrar por Cliente</Typography>
              </Box>

              <FormControl fullWidth>
                <NativeSelect
                  fullWidth
                  disabled={veiculo > 0}
                  value={clienteSelecionado}
                  onChange={(e) =>
                    selecionaFiltro({
                      tipoFiltro: 'cliente',
                      cliente: e.target.value,
                    })
                  }
                >
                  <option value="">Todos os Registros </option>
                  {listaClientes.length > 0 ? (
                    listaClientes.map((cliente) => {
                      return (
                        <option key={cliente.codigo} value={cliente.codigo}>
                          {cliente.nome_fantasia
                            ? cliente.nome_fantasia
                            : cliente.razao_social}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </NativeSelect>
              </FormControl>
            </Box>
            <Box width={'25%'}>
              <Box>
                <Typography variant="subtitle2">
                  Filtrar por Caminhão
                </Typography>
              </Box>

              <FormControl fullWidth>
                <NativeSelect
                  fullWidth
                  disabled={veiculo > 0}
                  value={veiculoSelecionado}
                  onChange={(e) =>
                    selecionaFiltro({
                      tipoFiltro: 'veiculo',
                      veiculo: e.target.value,
                    })
                  }
                >
                  <option value="">Todos os Registros </option>
                  {listaVeiculos.length > 0 ? (
                    listaVeiculos.map((veiculo) => {
                      return (
                        <option
                          key={veiculo.vei_codigo}
                          value={veiculo.vei_codigo}
                        >
                          {veiculo.vei_placa}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </NativeSelect>
              </FormControl>
            </Box>
          </Stack>
        </Box>
        <Divider />
        <TabelaResultados
          listaOrdens={listaOrdens}
          listaVeiculos={listaVeiculos}
          separarCarga={separarCarga}
          confirmaCargaVeiculo={confirmaCargaVeiculo}
          removeCargaVeiculo={removeCargaVeiculo}
        />
      </Box>
    </>
  );
};

export default Cargas;
