import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  TextField,
  FormControl,
  Box,
  DialogContentText,
  Typography,
} from '@mui/material';
import { useState } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConcluirProducao = ({
  openDialogConcluirProducao,
  onClose,
  producaoSelecionada,
  atualizaProducao,
  proximaMaquina,
}) => {
  const [qtde, setQtde] = useState('');

  return (
    <Dialog
      open={openDialogConcluirProducao}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>BAIXA PRODUÇÃO</DialogTitle>
      <DialogContent dividers>
        <DialogContent dividers>
          <DialogContentText>
            DIGITE A QUANTIDADE DE CAIXAS PRODUZIDAS
          </DialogContentText>
          <DialogContentText>
            NA ORDEM : {producaoSelecionada ? producaoSelecionada : ''}
          </DialogContentText>
        </DialogContent>
        {proximaMaquina.length > 0 ? (
          proximaMaquina.codigo !== 0 ? (
            <DialogContent sx={{ background: 'red' }}>
              <Typography variant="h6" sx={{ color: 'white' }}>
                ENVIAR PARA A MAQUINA:{' '}
                {proximaMaquina.maquina ? proximaMaquina.maquina : ''}
              </Typography>
            </DialogContent>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        <Box mt={2} mb={2}>
          <FormControl fullWidth>
            <TextField
              label="QUANTIDADE "
              type="number"
              value={qtde}
              onChange={(e) => setQtde(e.target.value)}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button onClick={onClose}>Fechar</Button>
        <Button
          onClick={() => {
            atualizaProducao(
              producaoSelecionada,
              qtde,
              proximaMaquina.maquina ? proximaMaquina.maquina : ''
            );
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConcluirProducao;
