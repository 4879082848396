import React, { useState } from 'react';
import {
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Table,
  TableBody,
  Tooltip,
  Badge,
} from '@mui/material';
import moment from 'moment';

import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import { useConfirm } from 'material-ui-confirm';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FactoryIcon from '@mui/icons-material/Factory';

export default function Row(props) {
  const { ordem, baixaEstoque } = props;
  const [open, setOpen] = useState(false);
  const confirm = useConfirm();

  const handleConfirm = (duplicada) => {
    if (duplicada !== 0) {
      confirm({
        title: 'Alerta!',
        description: `Existe uma ou mais ordens vinculadas a esse pedido, Verifique!  `,
        confirmationText: 'OK',
        cancellationText: 'CANCELAR',
      })
        .then(() => {
          confirm({
            title: 'Confirme a Operação',
            description: 'Deseja confirmar a a separacão da matéria prima?',
            confirmationText: 'SIM',
            cancellationText: 'NÃO',
          })
            .then(() => {
              baixaEstoque(ordem.opr_codigo);
            })
            .catch(() => {
              /* ... */
            });
        })
        .catch(() => {});
    } else {
      confirm({
        title: 'Confirme a Operação',
        description: 'Deseja confirmar a a separacão da matéria prima?',
        confirmationText: 'SIM',
        cancellationText: 'NÃO',
      })
        .then(() => {
          baixaEstoque(ordem.opr_codigo);
        })
        .catch(() => {
          /* ... */
        });
    }
  };
  console.log(ordem);
  return (
    <>
      <TableRow
        sx={
          ordem.opr_id_duplica !== 0
            ? { bgcolor: 'secondary.light' }
            : ordem.opr_obs
            ? { bgcolor: 'primary.infoRow' }
            : { bgcolor: '' }
        }
      >
        <TableCell colSpan={2} sx={{ width: '15%', margin: 1, padding: 1 }}>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </IconButton>
          <Tooltip title="Confirma separacão da Materia Prima ">
            <IconButton
              size="small"
              onClick={() => handleConfirm(ordem.opr_id_duplica)}
              sx={{ marginRight: 0.25 }}
            >
              <SwapHorizontalCircleIcon sx={{ color: 'primary.main' }} />
            </IconButton>
          </Tooltip>
          {ordem.carga === 'SIM' ? (
            <IconButton
              aria-label="expand row"
              size="small"
              sx={{ marginRight: 0.25 }}
            >
              <Tooltip title="Enviar Pedido para Carga">
                <LocalShippingIcon
                  sx={
                    ordem.carga === 'NÃO'
                      ? { color: 'primary.error' }
                      : { color: 'primary.success' }
                  }
                />
              </Tooltip>
            </IconButton>
          ) : (
            <></>
          )}
          <IconButton sx={{ marginRight: 0.25 }}>
            <Badge
              badgeContent={ordem.prioridade}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              overlap="circular"
              color={
                ordem.prioridade === 1
                  ? 'error'
                  : ordem.prioridade === 2
                  ? 'warning'
                  : ordem.prioridade === 3
                  ? 'success'
                  : ordem.prioridade === 4
                  ? 'info'
                  : ordem.prioridade === 5
                  ? 'primary'
                  : 'primary'
              }
            >
              <FactoryIcon color="primary" />
            </Badge>
          </IconButton>
        </TableCell>
        <TableCell>{ordem.opr_cd_venda}</TableCell>
        <TableCell sx={{ padding: 0, margin: 0 }}>
          {moment(ordem.opr_emissao).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell sx={{ padding: 0, margin: 0 }}>
          {moment(ordem.opr_entrega).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell>
          {ordem.nome_fantasia ? ordem.nome_fantasia : ordem.razao_social}
        </TableCell>
        <TableCell sx={{ padding: 0, margin: 0 }}>{ordem.descricao}</TableCell>
        <TableCell>{ordem.opr_qt_caixa}</TableCell>
        <TableCell sx={{ padding: 0, margin: 0 }}>
          {ordem.materia_prima_definida[0].descricao}
        </TableCell>
        <TableCell>{ordem.materia_prima_definida[0].quantidade}</TableCell>

        <TableCell>{ordem.maquina}</TableCell>
        <TableCell>{ordem.maquina2}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <strong>MODELO CAIXA: </strong> {ordem.modelo_caixa}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <strong>OBSERVAÇÃO: </strong> {ordem.opr_obs}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <strong
                        style={{
                          marginRight: '1%',
                        }}
                      >
                        COMPRIMENTO:{' '}
                      </strong>
                      {ordem.comprimento}
                      <strong
                        style={{
                          marginLeft: '1%',
                          marginRight: '1%',
                        }}
                      >
                        LARGURA:{' '}
                      </strong>
                      {ordem.largura}
                      <strong
                        style={{
                          marginLeft: '1%',
                          marginRight: '1%',
                        }}
                      >
                        ALTURA:{' '}
                      </strong>
                      {ordem.altura}
                      <strong
                        style={{
                          marginLeft: '1%',
                          marginRight: '1%',
                        }}
                      >
                        CHAPA:{' '}
                      </strong>
                      {ordem.tipo_chapa}
                      <strong
                        style={{
                          marginLeft: '1%',
                          marginRight: '1%',
                        }}
                      >
                        GRAMATURA:
                      </strong>{' '}
                      {ordem.gramatura}
                    </TableCell>
                  </TableRow>

                  {ordem.riscador ? (
                    ordem.riscador.map((medida, index) => (
                      <TableRow key={(medida, index)}>
                        <TableCell
                          colSpan={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <strong>RISCADOR </strong>
                        </TableCell>
                        <TableCell colSpan={1}>{medida.medida1}</TableCell>
                        <TableCell colSpan={1}>{medida.medida2}</TableCell>
                        <TableCell colSpan={1}>{medida.medida3}</TableCell>
                        <TableCell colSpan={1}>{medida.medida4}</TableCell>
                        <TableCell colSpan={1}>{medida.medida5}</TableCell>
                        <TableCell colSpan={1}>{medida.medida6}</TableCell>
                        <TableCell colSpan={1}>{medida.medida7}</TableCell>
                        <TableCell colSpan={1}>{medida.medida8}</TableCell>
                        <TableCell colSpan={1}>{medida.medida9}</TableCell>
                        <TableCell colSpan={1}>{medida.total}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}

                  {ordem.impressora ? (
                    ordem.impressora.map((medida, index) => (
                      <TableRow key={(medida, index)}>
                        <TableCell
                          colSpan={1}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <strong>IMPRESSORA</strong>
                        </TableCell>
                        <TableCell colSpan={1}>{medida.medida1}</TableCell>
                        <TableCell colSpan={1}>{medida.medida2}</TableCell>
                        <TableCell colSpan={1}>{medida.medida3}</TableCell>
                        <TableCell colSpan={1}>{medida.medida4}</TableCell>
                        <TableCell colSpan={1}>{medida.medida5}</TableCell>
                        <TableCell colSpan={1}>{medida.medida6}</TableCell>
                        <TableCell colSpan={1}>{medida.medida7}</TableCell>
                        <TableCell colSpan={1}>{medida.medida8}</TableCell>
                        <TableCell colSpan={1}>{medida.medida9}</TableCell>
                        <TableCell colSpan={1}>{medida.total}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}
                  <TableRow>
                    {ordem.maquinas_selecionadas[0].codigo1 !== 0 ? (
                      <>
                        {ordem.maquinas_selecionadas[0].maquina1 ? (
                          <TableCell colSpan={4}>
                            <strong> MAQUINA 1: </strong>
                            {ordem.maquinas_selecionadas[0].maquina1}
                            <span style={{ color: 'red', marginLeft: '5px' }}>
                              (
                              {
                                ordem.maquinas_selecionadas[0]
                                  .maquina1Confirmado
                              }
                              )
                            </span>
                          </TableCell>
                        ) : (
                          ''
                        )}
                        {ordem.maquinas_selecionadas[0].maquina2 ? (
                          <TableCell colSpan={4}>
                            <strong> MAQUINA 2: </strong>
                            {ordem.maquinas_selecionadas[0].maquina2}
                            <span style={{ color: 'red', marginLeft: '5px' }}>
                              (
                              {
                                ordem.maquinas_selecionadas[0]
                                  .maquina2Confirmado
                              }
                              )
                            </span>
                          </TableCell>
                        ) : (
                          ''
                        )}
                        {ordem.maquinas_selecionadas[0].maquina3 ? (
                          <TableCell colsPan={4}>
                            <strong> MAQUINA 3: </strong>
                            {ordem.maquinas_selecionadas[0].maquina3}
                            <span style={{ color: 'red', marginLeft: '5px' }}>
                              (
                              {
                                ordem.maquinas_selecionadas[0]
                                  .maquina3Confirmado
                              }
                              )
                            </span>
                          </TableCell>
                        ) : (
                          ''
                        )}
                        {ordem.maquinas_selecionadas[0].maquina4 ? (
                          <TableCell colSpan={4}>
                            <strong> MAQUINA 4: </strong>
                            {ordem.maquinas_selecionadas[0].maquina4}
                            <span style={{ color: 'red', marginLeft: '5px' }}>
                              (
                              {
                                ordem.maquinas_selecionadas[0]
                                  .maquina4Confirmado
                              }
                              )
                            </span>
                          </TableCell>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      <TableCell colSpan={13}>
                        <strong> MAQUINAS:</strong> MAQUINAS AINDA NÃO
                        SELECIONADAS
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
