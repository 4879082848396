import React, { useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  NativeSelect,
  TextField,
  FormControl,
  Box,
  InputLabel,
  Typography,
} from "@mui/material";

import { useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";
import { useAuthState } from "../../../../../../contexts/AuthContext";
import { useEffect } from "react";
import { Cancel, Save } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReservaMateria = ({
  openDialogSeparaMateria,
  onClose,
  producaoSelecionada,
  listaMateriasPrima,
  materiasFiltradas,
  atualizaMateriaPrima,
  quantidadeCaixas,
  nomeCaixa,
  comprimentoCaixa,
  larguraCaixa,
  gramaturaCaixa,
  medidas,
}) => {
  const { usuarioIDLogado } = useAuthState();
  const confirm = useConfirm();
  const [materiaPrima1, setMateriaPrima1] = useState({});
  const [materiaPrima2, setMateriaPrima2] = useState({});
  const [materiaPrima3, setMateriaPrima3] = useState({});

  const [estoqueMateriaPrima1, setEstoqueMateriaPrima1] = useState(0);
  const [estoqueMateriaPrima2, setEstoqueMateriaPrima2] = useState(0);
  const [estoqueMateriaPrima3, setEstoqueMateriaPrima3] = useState(0);

  useEffect(() => {
    setMateriaPrima1({
      producao: producaoSelecionada,
      usuario: usuarioIDLogado,
      materia: "0",
      quantidade: 0,
      kilo: 0,
      compra: "0",
      guilhotina: "0",
      comprimento: 0,
      largura: 0,
    });
    setMateriaPrima2({
      producao: producaoSelecionada,
      usuario: usuarioIDLogado,
      materia: "0",
      quantidade: 0,
      kilo: 0,
      compra: "0",
    });
    setMateriaPrima3({
      producao: producaoSelecionada,
      usuario: usuarioIDLogado,
      materia: "0",
      quantidade: 0,
      kilo: 0,
      compra: "0",
    });
  }, [producaoSelecionada, usuarioIDLogado]);

  const handleClose = () => {
    setMateriaPrima1({
      producao: producaoSelecionada,
      usuario: usuarioIDLogado,
      materia: "0",
      quantidade: 0,
      kilo: 0,
      compra: "0",
      guilhotina: "0",
      comprimento: 0,
      largura: 0,
    });
    setMateriaPrima2({
      producao: producaoSelecionada,
      usuario: usuarioIDLogado,
      materia: "0",
      quantidade: 0,
      kilo: 0,
      compra: "0",
      guilhotina: "0",
    });
    setMateriaPrima3({
      producao: producaoSelecionada,
      usuario: usuarioIDLogado,
      materia: "0",
      quantidade: 0,
      kilo: 0,
      compra: "0",
    });
    setEstoqueMateriaPrima1(0);
    setEstoqueMateriaPrima2(0);
    setEstoqueMateriaPrima3(0);
    onClose();
  };

  const selecionaMateriaPrima01 = (prop) => (event) => {
    let materiaPrimaEncontrada = "";

    if (prop === "materia" && event.target.value === "0") {
      setEstoqueMateriaPrima1(0);
      setMateriaPrima1({
        producao: producaoSelecionada,
        usuario: usuarioIDLogado,
        materia: "0",
        quantidade: 0,
        kilo: 0,
        compra: "0",
        guilhotina: "0",
        comprimento: 0,
        largura: 0,
      });
    } else if (prop === "materia" && event.target.value !== "0") {
      materiaPrimaEncontrada = materiasFiltradas[0].find(
        (materia) => materia.codigo === parseInt(event.target.value)
      );
      setMateriaPrima1({
        ...materiaPrima1,
        producao: producaoSelecionada,
        usuario: usuarioIDLogado,
        guilhotina: "0",
        quantidade: materiaPrimaEncontrada.qtde_chapas,
        kilo: materiaPrimaEncontrada.kilo,
        comprimentoMateria: materiaPrimaEncontrada.comprimento,

        [prop]: event.target.value,
      });

      setEstoqueMateriaPrima1(
        parseFloat(
          materiaPrimaEncontrada.estoque_total -
            materiaPrimaEncontrada.estoque_reservado
        )
      );
    } else if (prop === "guilhotina") {
      setMateriaPrima1({
        ...materiaPrima1,
        producao: producaoSelecionada,
        usuario: usuarioIDLogado,
        quantidadeCaixa: quantidadeCaixas,

        [prop]: event.target.value,
      });
    } else {
      setMateriaPrima1({
        ...materiaPrima1,
        producao: producaoSelecionada,
        usuario: usuarioIDLogado,
        [prop]: event.target.value,
      });
    }
  };

  const selecionaMateriaPrima02 = (prop) => (event) => {
    let materiaPrimaEncontrada = "";

    if (prop === "materia" && event.target.value === "0") {
      setEstoqueMateriaPrima2(0);
      setMateriaPrima2({
        producao: producaoSelecionada,
        usuario: usuarioIDLogado,
        materia: "0",
        quantidade: 0,
        kilo: 0,
        compra: "0",
      });
    } else if (prop === "materia" && event.target.value !== "0") {
      materiaPrimaEncontrada = materiasFiltradas[1].find(
        (materia) => materia.codigo === parseInt(event.target.value)
      );

      setMateriaPrima2({
        ...materiaPrima2,
        producao: producaoSelecionada,
        usuario: usuarioIDLogado,
        quantidade: materiaPrimaEncontrada.qtde_chapas,
        kilo: materiaPrimaEncontrada.kilo,
        comprimentoMateria: materiaPrimaEncontrada.comprimento,
        [prop]: event.target.value,
      });

      setEstoqueMateriaPrima2(
        parseFloat(
          materiaPrimaEncontrada.estoque_total -
            materiaPrimaEncontrada.estoque_reservado
        )
      );
    } else {
      setMateriaPrima2({
        ...materiaPrima2,
        producao: producaoSelecionada,
        usuario: usuarioIDLogado,
        [prop]: event.target.value,
      });
    }
  };

  const selecionaMateriaPrima03 = (prop) => (event) => {
    let materiaPrimaEncontrada = "";

    if (prop === "materia" && event.target.value === "0") {
      setEstoqueMateriaPrima3(0);
      setMateriaPrima3({
        producao: producaoSelecionada,
        usuario: usuarioIDLogado,
        materia: "0",
        quantidade: 0,
        kilo: 0,
        compra: "0",
      });
    } else if (prop === "materia" && event.target.value !== "0") {
      materiaPrimaEncontrada = listaMateriasPrima.find(
        (materia) => materia.codigo === parseInt(event.target.value)
      );
      setMateriaPrima3({
        ...materiaPrima3,
        producao: producaoSelecionada,
        usuario: usuarioIDLogado,
        quantidade:
          materiaPrimaEncontrada.comprimento > 0
            ? materiaPrimaEncontrada.quantidade
            : 0,
        kilo:
          materiaPrimaEncontrada.comprimento == 0
            ? materiaPrimaEncontrada.kilo
            : 0,
        comprimentoMateria: materiaPrimaEncontrada.comprimento,
        [prop]: event.target.value,
      });

      setEstoqueMateriaPrima3(
        parseFloat(
          materiaPrimaEncontrada.estoque_total -
            materiaPrimaEncontrada.estoque_reservado
        )
      );
    } else {
      setMateriaPrima3({
        ...materiaPrima3,
        producao: producaoSelecionada,
        usuario: usuarioIDLogado,
        [prop]: event.target.value,
      });
    }
  };

  const validarMateriaPrima = (materiaPrimaRecebida, numero) => {
    if (medidas[numero - 1] !== undefined) {
      if (
        materiaPrimaRecebida.materia === "0" &&
        materiaPrimaRecebida.compra === "0" &&
        materiaPrimaRecebida.quantidade === 0 &&
        materiaPrimaRecebida.kilo === 0
      ) {
        toast.error(
          `Erro na Operação! Verifique se as matérias estão selecionadas corretamente - Erro na Matéria-Prima ${numero}`
        );
        return false;
      } else if (
        (materiaPrimaRecebida.materia !== "0" &&
          materiaPrimaRecebida.compra === "0") ||
        (materiaPrimaRecebida.compra !== "0" &&
          materiaPrimaRecebida.materia === "0") ||
        (materiaPrimaRecebida.quantidade !== 0 &&
          materiaPrimaRecebida.materia === "0") ||
        (materiaPrimaRecebida.materia !== "0" &&
          materiaPrimaRecebida.quantidade === 0 &&
          materiaPrimaRecebida.kilo === 0)
      ) {
        toast.error(
          `Erro na Operação! Verifique se as matérias estão selecionadas corretamente - Erro na Matéria-Prima ${numero}`
        );
        return false;
      }
    }
    return true;
  };

  const validarMateria01 = () => {
    if (
      (materiaPrima1 !== "0" &&
        materiaPrima1.quantidade === 0 &&
        materiaPrima1.kilo === 0) ||
      materiaPrima1.materia === "0" ||
      materiaPrima1.compra === "0" ||
      materiaPrima1.guilhotina === "0" ||
      materiaPrima1.comprimento === 0 ||
      !materiaPrima1.comprimento ||
      materiaPrima1.largura === 0 ||
      !materiaPrima1.largura
    ) {
      toast.error(
        "Erro na Operação! Verifique se as matérias estão selecionadas corretamente - Erro na Matéria-Prima 01"
      );

      return false;
    }

    return true;
  };
  const validarDados = () => {
    if (
      materiaPrima1.comprimentoMateria &&
      materiaPrima1.comprimentoMateria === 0 &&
      !validarMateria01()
    ) {
      return false;
    }
    if (materiaPrima1.guilhotina && materiaPrima1.guilhotina === "0") {
      if (!validarMateriaPrima(materiaPrima1, "01")) {
        toast.error(
          "Erro na Operação! Verifique se as matérias estão selecionadas corretamente - Erro na  Matéria-Prima 01"
        );
        return false;
      }

      toast.error(
        "Erro na Operação! Verifique se as matérias estão selecionadas corretamente -PREENCHA SE É GUILHOTINA OU NÃO"
      );
      return false;
    }
    if (!validarMateriaPrima(materiaPrima2, "02")) {
      return false;
    }

    if (!validarMateriaPrima(materiaPrima3, "03")) {
      return false;
    }

    return true;
  };

  const handleConfirm = () => {
    console.log(validarDados());
    console.log(materiaPrima1, materiaPrima2);
    if (!validarDados()) {
      return;
    } else {
      confirm({
        title: "Confirme a Operação",
        description: "Deseja confirmar a conclusão da etapa? ",
        confirmationText: "SIM",
        cancellationText: "NÃO",
      })
        .then(() => {
          atualizaMateriaPrima([materiaPrima1, materiaPrima2, materiaPrima3]);
        })

        .catch(() => {});
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      keepMounted
      open={openDialogSeparaMateria}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <DialogTitle>
        RESERVA DE MATERIAL - {nomeCaixa} - ({comprimentoCaixa} X {larguraCaixa}
        )-
        {gramaturaCaixa}Gr
      </DialogTitle>
      <DialogContent dividers>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            width="100%"
          >
            {/*materia-prima 01*/}
            <Box mt={1} mr={2} ml={2} width={400}>
              <Box display="flex" justifyContent="left">
                <Typography variant="h6">
                  Matéria-Prima - {medidas[0] ? medidas[0].corte : ""}(
                  {medidas[0] ? medidas[0].qtde : ""})
                </Typography>
              </Box>

              <Box mt={2} mb={1}>
                <FormControl fullWidth>
                  <InputLabel shrink>SELECIONE A MATERIA PRIMA </InputLabel>
                  <NativeSelect
                    variant="filled"
                    value={materiaPrima1.materia}
                    onChange={selecionaMateriaPrima01("materia")}
                  >
                    <option value={0}>Selecione</option>
                    {materiasFiltradas.length > 0
                      ? materiasFiltradas[0] &&
                        medidas[0] &&
                        medidas[0].codTipoChapa
                        ? materiasFiltradas[0]
                            .filter(
                              (materiaPrima) => materiaPrima.comprimento === 0
                            )
                            .map((materiaPrima) => (
                              <option
                                value={materiaPrima.codigo}
                                key={materiaPrima.codigo}
                              >
                                {materiaPrima.descricao} -{" "}
                                {materiaPrima.gramatura}G
                              </option>
                            ))
                        : null
                      : null}
                  </NativeSelect>
                </FormControl>
              </Box>
              <Box mt={2} mb={1}>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: "primary.error" }} shrink>
                    NECESSÁRIO COMPRA ?{" "}
                  </InputLabel>
                  <NativeSelect
                    variant="filled"
                    onChange={selecionaMateriaPrima01("compra")}
                    value={materiaPrima1.compra}
                  >
                    <option defaultValue value={0}>
                      ESCOLHA SIM OU NÃO
                    </option>
                    <option value="SIM">SIM</option>
                    <option value="NAO">NÃO</option>
                  </NativeSelect>
                </FormControl>
              </Box>

              <Box mt={2} mb={2}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    value={estoqueMateriaPrima1.toString()}
                    label="Estoque Disponivel"
                  />
                </FormControl>
              </Box>

              <Box mt={1} mb={2}>
                {materiaPrima1.quantidade === 0 ? (
                  <FormControl fullWidth>
                    <TextField
                      label="Kilo"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      value={materiaPrima1.kilo}
                      onChange={selecionaMateriaPrima01("kilo")}
                    />
                  </FormControl>
                ) : (
                  <FormControl fullWidth>
                    <TextField
                      label="Quantidade"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      value={materiaPrima1.quantidade}
                      onChange={selecionaMateriaPrima01("quantidade")}
                    />
                  </FormControl>
                )}
              </Box>
            </Box>

            {/*materia-prima 02*/}

            <Box mt={1} mr={2} ml={2} width={400}>
              <Box display="flex" justifyContent="left">
                <Typography variant="h6">
                  {" "}
                  Matéria-Prima - {medidas[1] ? medidas[1].corte : ""}(
                  {medidas[1] ? medidas[1].qtde : ""})
                </Typography>
              </Box>

              <Box mt={2} mb={1}>
                <FormControl fullWidth>
                  <InputLabel shrink>SELECIONE A MATERIA PRIMA </InputLabel>
                  <NativeSelect
                    variant="filled"
                    value={materiaPrima2.materia}
                    onChange={selecionaMateriaPrima02("materia")}
                  >
                    <option value={0}>Selecione</option>
                    {materiasFiltradas.length > 0
                      ? materiasFiltradas[1] &&
                        medidas[1] &&
                        medidas[1].codTipoChapa
                        ? materiasFiltradas[1]
                            .filter(
                              (materiaPrima) => materiaPrima.comprimento > 0
                            )
                            .map((materiaPrima) => (
                              <option
                                value={materiaPrima.codigo}
                                key={materiaPrima.codigo}
                              >
                                {materiaPrima.descricao} -{" "}
                                {materiaPrima.gramatura}G
                              </option>
                            ))
                        : null
                      : null}
                  </NativeSelect>
                </FormControl>
              </Box>
              <Box mt={2} mb={1}>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: "primary.error" }} shrink>
                    NECESSÁRIO COMPRA ?{" "}
                  </InputLabel>
                  <NativeSelect
                    variant="filled"
                    onChange={selecionaMateriaPrima02("compra")}
                    value={materiaPrima2.compra}
                  >
                    <option defaultValue value={"0"}>
                      ESCOLHA SIM OU NÃO
                    </option>
                    <option value="SIM">SIM</option>
                    <option value="NAO">NÃO</option>
                  </NativeSelect>
                </FormControl>
              </Box>

              <Box mt={2} mb={2}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    value={estoqueMateriaPrima2}
                    label="Estoque Disponivel"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </FormControl>
              </Box>

              <Box mt={2} mb={2}>
                {materiaPrima2.quantidade == 0 ? (
                  <FormControl fullWidth>
                    <TextField
                      label="Kilo"
                      type="number"
                      value={materiaPrima2.kilo}
                      onChange={selecionaMateriaPrima02("kilo")}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  </FormControl>
                ) : (
                  <FormControl fullWidth>
                    <TextField
                      label="Quantidade"
                      type="number"
                      value={materiaPrima2.quantidade}
                      onChange={selecionaMateriaPrima02("quantidade")}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  </FormControl>
                )}
              </Box>
            </Box>

            {/*materia-prima 03*/}
            <Box mt={1} mr={2} ml={2} width={400}>
              <Box display="flex" justifyContent="left">
                <Typography variant="h6">
                  Matéria-Prima - {medidas[2] ? medidas[2].corte : ""}(
                  {medidas[2] ? medidas[2].qtde : ""})
                </Typography>
              </Box>

              <Box mt={2} mb={1}>
                <FormControl fullWidth>
                  <InputLabel shrink>SELECIONE A MATERIA PRIMA </InputLabel>
                  <NativeSelect
                    variant="filled"
                    value={materiaPrima3.materia}
                    onChange={selecionaMateriaPrima03("materia")}
                  >
                    <option value={0}>Selecione</option>
                    {listaMateriasPrima.map((materiaPrima) => (
                      <option
                        value={materiaPrima.codigo}
                        key={materiaPrima.codigo}
                      >
                        {materiaPrima.descricao} - {materiaPrima.gramatura}G
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Box>
              <Box mt={2} mb={1}>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: "primary.error" }} shrink>
                    NECESSÁRIO COMPRA ?{" "}
                  </InputLabel>
                  <NativeSelect
                    variant="filled"
                    onChange={selecionaMateriaPrima03("compra")}
                    value={materiaPrima3.compra}
                  >
                    <option defaultValue value={0}>
                      ESCOLHA SIM OU NÃO
                    </option>
                    <option value="SIM">SIM</option>
                    <option value="NAO">NÃO</option>
                  </NativeSelect>
                </FormControl>
              </Box>

              <Box mt={2} mb={2}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    size="small"
                    value={estoqueMateriaPrima3}
                    label="Estoque Disponivel"
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Box>

              <Box mt={1} mb={2}>
                {materiaPrima3.quantidade == 0 ? (
                  <FormControl fullWidth>
                    <TextField
                      label="Kilo"
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      size="small"
                      value={materiaPrima3.kilo}
                      onChange={selecionaMateriaPrima03("kilo")}
                    />
                  </FormControl>
                ) : (
                  <FormControl fullWidth>
                    <TextField
                      label="Quantidade"
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      size="small"
                      value={materiaPrima3.quantidade}
                      onChange={selecionaMateriaPrima03("quantidade")}
                    />
                  </FormControl>
                )}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexDirection: "row",
              padding: 1,
            }}
          >
            <Box
              sx={{
                width: 400,
                display: "flex",
                m: 1,
              }}
            >
              <FormControl fullWidth>
                <InputLabel shrink>É GUILHOTINA ? </InputLabel>
                <NativeSelect
                  variant="filled"
                  onChange={selecionaMateriaPrima01("guilhotina")}
                  value={materiaPrima1.guilhotina}
                >
                  <option defaultValue value={0}>
                    ESCOLHA SIM OU NÃO
                  </option>
                  <option value="SIM">SIM</option>
                  <option value="NAO">NÃO</option>
                </NativeSelect>
              </FormControl>
            </Box>
            <Box
              sx={{
                ml: 0,
                width: 400,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormControl fullWidth>
                <TextField
                  value={materiaPrima1.comprimento}
                  onChange={selecionaMateriaPrima01("comprimento")}
                  label="Novo Comprimento"
                  size="small"
                />
              </FormControl>
            </Box>{" "}
            <Box
              sx={{
                width: 400,
                ml: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormControl fullWidth>
                <TextField
                  value={materiaPrima1.largura}
                  onChange={selecionaMateriaPrima01("largura")}
                  label="Nova Largura"
                  size="small"
                />
              </FormControl>
            </Box>
          </Box>
        </DialogContent>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <Button
          variant="contained"
          startIcon={<Cancel color={"error"} />}
          onClick={() => handleClose()}
        >
          Fechar
        </Button>
        <Button
          variant="contained"
          startIcon={<Save color={"success"} />}
          onClick={() => {
            handleConfirm();
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReservaMateria;
