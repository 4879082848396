import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TablePagination,
} from '@mui/material';

import { toast } from 'react-toastify';

import Row from './Rows/Row';
import ConcluirProducao from './ConcluirProducao/ConcluirProducao';
import VisualizarImagem from './VisualizarImagem/VisualizarImagem';
import { useAuthState } from '../../../../../contexts/AuthContext';
import Spinner from '../../../../../components/Spinner/Spinner';
import api from '../../../../../api/api';

export default function TabelaResultados({
  listaOrdens,
  atualizaProducao,
  proximoItem,
  encontrarMaquinaAtual,
  iniciaProducao,
}) {
  const { empresaAtiva } = useAuthState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openDialogConcluirProducao, setOpenDialogConcluirProducao] =
    useState(false);
  const [producaoSelecionada, setProducaoSelecionada] = useState('');
  const [imagemSelecionada, setImagemSelecionada] = useState();
  const [loading, setLoading] = useState(false);
  const [proximaMaquina, setProximaMaquina] = useState('');
  const [openDialogVisualizaImagem, setOpenDialogVisualizaImagem] =
    useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };
  const fechadialogConcluirProducao = () => {
    setOpenDialogConcluirProducao(false);
  };
  const concluiProducao = (producao, listaMaquinas) => {
    console.log(listaMaquinas);
    setProximaMaquina(proximoItem(listaMaquinas));
    setProducaoSelecionada(producao);
    setOpenDialogConcluirProducao(true);
  };
  const fechaDialogVisualizaImagem = () => {
    setOpenDialogVisualizaImagem(false);
    setImagemSelecionada(null);
  };

  const functionVisualizarImagem = (caixa, cliente) => {
    setLoading(true);
    api
      .post(
        '/imagem/arte',
        { item: caixa, cliente: cliente },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(({ data }) => {
        setImagemSelecionada(data.artes[0].imagem);
      })
      .catch((err) => {
        toast.error('Imagem não encontrada ');
      })
      .finally(() => {
        setLoading(false);
        setOpenDialogVisualizaImagem(true);
      });
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <Box>
      {listaOrdens ? (
        <ConcluirProducao
          openDialogConcluirProducao={openDialogConcluirProducao}
          onClose={fechadialogConcluirProducao}
          producaoSelecionada={producaoSelecionada}
          atualizaProducao={atualizaProducao}
          proximaMaquina={proximaMaquina}
        />
      ) : (
        <></>
      )}

      {imagemSelecionada ? (
        <VisualizarImagem
          onClose={fechaDialogVisualizaImagem}
          openDialogVisualizaImagem={openDialogVisualizaImagem}
          imagemSelecionada={imagemSelecionada}
        />
      ) : (
        <></>
      )}
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              <TableCell>Pedido</TableCell>
              <TableCell>Guia</TableCell>
              <TableCell>Entrega</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Caixa</TableCell>
              <TableCell>Comp.</TableCell>
              <TableCell>Larg.</TableCell>
              <TableCell>Alt.</TableCell>
              <TableCell>Chapa</TableCell>
              <TableCell>Gram.</TableCell>
              <TableCell>Qtde</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listaOrdens ? (
              listaOrdens.length > 0 ? (
                listaOrdens
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((ordem, index) => {
                    return (
                      <Row
                        ordem={ordem}
                        key={index}
                        concluiProducao={concluiProducao}
                        visualizarImagem={functionVisualizarImagem}
                        encontrarMaquinaAtual={encontrarMaquinaAtual}
                        iniciaProducao={iniciaProducao}
                      />
                    );
                  })
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listaOrdens.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}
