import React from 'react';
import { Link } from 'react-router-dom';
import {
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  Button,
  useTheme,
  Box,
} from '@mui/material';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import FactoryIcon from '@mui/icons-material/Factory';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import {
  useAuthState,
  handleLogout,
  useAuthDispatch,
} from '../../../contexts/AuthContext';

function ItemListDrawer() {
  const { url, acessos } = useAuthState();

  const dispatch = useAuthDispatch();
  const theme = useTheme();

  const acessaProducao = acessos.findIndex(
    (acesso) => acesso.grupo === 'PRODUCAO'
  );
  const acessaLog = acessos.findIndex((acesso) => acesso.grupo === 'LOG');

  return (
    <List>
      <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/">
        <Tooltip arrow title="Home" placement="right">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Home" />
      </ListItem>
      {/*-------------------------------------------------------------*/}
      <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/imagens">
        <Tooltip
          arrow
          title="Layouts Cadastrados no sistema  "
          placement="right"
        >
          <ListItemIcon>
            <InsertPhotoIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Layouts" />
      </ListItem>
      {/*-------------------------------------------------------------*/}
      {acessaProducao !== -1 ? (
        <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/producao">
          <Tooltip arrow title="Acompanhar Produção" placement="right">
            <ListItemIcon>
              <FactoryIcon color={theme.palette.primary.success} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Produção" />
        </ListItem>
      ) : (
        <></>
      )}
      {/*-------------------------------------------------------------*/}
      {acessaLog !== -1 ? (
        <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/consultaLog">
          <Tooltip
            arrow
            title="Consulta Logs Gerados no sistema "
            placement="right"
          >
            <ListItemIcon>
              <PlaylistAddCheckIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Consulta Logs" />
        </ListItem>
      ) : (
        <></>
      )}
      {/*-------------------------------------------------------------*/}
      {/*
      <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/conscpfcnpj">
        <Tooltip arrow title="Consulta Títulos  " placement="right">
          <ListItemIcon>
            <AttachMoneyIcon  />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Consulta CPF/CNPJ" />
      </ListItem>

      <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/concentracao">
        <Tooltip arrow title="Concentração" placement="right">
          <ListItemIcon>
            <AttachMoneyIcon  />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Concentração" />
      </ListItem>
  */}
      <Divider />

      <Box sx={{ top: '30em', bottom: 0, position: 'absolute' }}>
        <ListItem
          component={Button}
          onClick={() => handleLogout(dispatch, url)}
        >
          <Tooltip arrow title="Sair " placement="right">
            <ListItemIcon>
              <ExitToAppTwoToneIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Sair" />
        </ListItem>
      </Box>
    </List>
  );
}

export default ItemListDrawer;
