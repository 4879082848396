import { Route } from 'react-router-dom';
import { useAuthState } from '../contexts/AuthContext';
import history from './history';

export function CustomRoute({ component: Component, isPrivate, ...rest }) {
  const { autenticado, url } = useAuthState();

  if (!autenticado && isPrivate) {
    history.push(`/login/${url}`);
    history.go();
  }

  return (
    <Route {...rest} render={({ location }) => <Component {...location} />} />
  );
}
