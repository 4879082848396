import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  FormControl,
  Box,
  DialogContentText,
  NativeSelect,
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlteraPrioridade = ({
  open,
  onClose,
  producaoSelecionada,
  alterarPrioridade,
  codDuplica,
}) => {
  const [prioridade, setPrioridade] = useState(5);
  const handleClose = () => {
    setPrioridade(5);
    onClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>ALTERAR PRIORIDADE DE PRODUÇÃO</DialogTitle>
      <DialogContent dividers>
        <DialogContent dividers>
          <DialogContentText>
            ESCOLHA A PRIORIDADE DE PRODUÇÃO {producaoSelecionada}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <Box mt={2} mb={2}>
            <FormControl fullWidth>
              <NativeSelect
                value={prioridade}
                onChange={(e) => setPrioridade(e.target.value)}
              >
                <option value={1}>1 - Maxima Prioridade </option>
                <option value={2}>2 - Muita Prioridade</option>
                <option value={3}>3 - Alta Prioridade</option>
                <option value={4}>4 - Moderada Prioridade</option>
                <option value={5}>5 - Media Prioridade</option>
                <option value={6}>6 - Baixa Prioridade</option>
                <option value={7}>7 - Minima Prioridade</option>
                <option value={8}>8 - Sem Prioridade - 1 </option>
                <option value={9}>9 - Sem Prioridade - 2</option>
                <option value={10}>10 - Sem Prioridade - 3</option>
                <option value={11}>11 - Sem Prioridade - 4</option>
                <option value={12}>12 - Sem Prioridade - 5</option>
                <option value={13}>13 - Sem Prioridade - 6</option>
                <option value={14}>14 - Sem Prioridade - 7</option>
                <option value={15}>15 - Sem Prioridade - 8</option>
                <option value={16}>16 - Sem Prioridade - 8</option>
                <option value={17}>17 - Sem Prioridade - 8</option>
                <option value={18}>18 - Sem Prioridade - 8</option>
                <option value={19}>19 - Sem Prioridade - 8</option>
                <option value={20}>20 - Sem Prioridade - 8</option>
                <option value={21}>21 - Sem Prioridade - 8</option>
                <option value={22}>22 - Sem Prioridade - 8</option>
                <option value={23}>23 - Sem Prioridade - 8</option>
                <option value={24}>24 - Sem Prioridade - 8</option>
                <option value={25}>25 - Sem Prioridade - 8</option>
                <option value={26}>26 - Sem Prioridade - 8</option>
                <option value={27}>27 - Sem Prioridade - 8</option>
                <option value={28}>28 - Sem Prioridade - 8</option>
                <option value={29}>29 - Sem Prioridade - 8</option>
                <option value={30}>30 - Sem Prioridade - 8</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </DialogContent>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button onClick={handleClose}>Fechar</Button>
        <Button
          onClick={() => {
            alterarPrioridade(producaoSelecionada, prioridade, codDuplica);
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlteraPrioridade;
