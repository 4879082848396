import React from 'react';
import {
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Table,
  TableBody,
  Tooltip,
  Badge,
} from '@mui/material';
import moment from 'moment';

import EngineeringIcon from '@mui/icons-material/Engineering';
import { useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FactoryIcon from '@mui/icons-material/Factory';

export default function Row(props) {
  const { ordem, separaMateria } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        sx={
          ordem.opr_id_duplica !== 0
            ? { bgcolor: 'secondary.light' }
            : ordem.opr_obs
            ? { bgcolor: 'primary.infoRow' }
            : { bgcolor: '' }
        }
      >
        <TableCell colSpan={2} sx={{ width: '12%' }}>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </IconButton>
          <Tooltip title="Selecionar Etapas">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() =>
                separaMateria(ordem.opr_codigo, ordem.opr_id_duplica)
              }
            >
              <EngineeringIcon sx={{ color: 'primary.buttonColorSecondary' }} />
            </IconButton>
          </Tooltip>
          {ordem.carga === 'SIM' ? (
            <IconButton aria-label="expand row" size="small">
              <Tooltip title="Pedido Enviado para carga">
                <LocalShippingIcon
                  sx={
                    ordem.carga === 'NÃO'
                      ? { color: 'primary.error' }
                      : { color: 'primary.success' }
                  }
                />
              </Tooltip>
            </IconButton>
          ) : (
            <></>
          )}
          <Badge
            badgeContent={ordem.prioridade}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            overlap="circular"
            color={
              ordem.prioridade === 1
                ? 'error'
                : ordem.prioridade === 2
                ? 'warning'
                : ordem.prioridade === 3
                ? 'success'
                : ordem.prioridade === 4
                ? 'info'
                : ordem.prioridade === 5
                ? 'primary'
                : 'primary'
            }
          >
            <FactoryIcon color="primary" />
          </Badge>
        </TableCell>
        <TableCell>
          {ordem.opr_cd_venda} ({ordem.qt_caixas_prontas}/
          {ordem.qt_caixas_pedido})
        </TableCell>
        <TableCell>
          {moment(ordem.opr_emissao).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell>
          {moment(ordem.opr_entrega).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell>
          {ordem.nome_fantasia ? ordem.nome_fantasia : ordem.razao_social}
        </TableCell>
        <TableCell>{ordem.descricao}</TableCell>
        <TableCell>{ordem.comprimento}</TableCell>
        <TableCell>{ordem.largura}</TableCell>
        <TableCell>{ordem.altura}</TableCell>
        <TableCell>{ordem.tipo_chapa}</TableCell>
        <TableCell>{ordem.vincada}</TableCell>
        <TableCell>{ordem.gramatura}</TableCell>

        <TableCell>{ordem.opr_qt_caixa}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <strong>MODELO CAIXA: </strong> {ordem.modelo_caixa}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <strong>MATRIZ: </strong>
                      {ordem.matriz}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <strong>OBSERVAÇÃO: </strong> {ordem.opr_obs}
                    </TableCell>
                  </TableRow>
                  {ordem.materia_prima_definida ? (
                    ordem.materia_prima_definida.map((materia_prima, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell colSpan={13}>
                            <strong
                              style={{ color: 'red', marginRight: ' 1%' }}
                            >
                              MATERIA DEFINIDA:
                            </strong>
                            {materia_prima.descricao}
                            <strong
                              style={{
                                color: 'red',
                                marginLeft: '1%',
                                marginRight: '1%',
                              }}
                            >
                              QTDE CHAPAS:
                            </strong>
                            {materia_prima.quantidade}
                            <strong
                              style={{
                                color: 'red',
                                marginLeft: '1%',
                                marginRight: '1%',
                              }}
                            >
                              DATA/HORA:
                            </strong>
                            {ordem.dataDefineMateria
                              ? moment(ordem.dataDefineMateria)
                                  .add(3, 'hours')
                                  .format('DD/MM/YYYY')
                              : ''}
                            -{' '}
                            {ordem.horaDefineMateria
                              ? ordem.horaDefineMateria
                              : ''}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  {ordem.riscador ? (
                    ordem.riscador.map((medida, index) => (
                      <TableRow key={(medida, index)}>
                        <TableCell
                          colSpan={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <strong>RISCADOR </strong>
                        </TableCell>
                        <TableCell colSpan={1}>{medida.medida1}</TableCell>
                        <TableCell colSpan={1}>{medida.medida2}</TableCell>
                        <TableCell colSpan={1}>{medida.medida3}</TableCell>
                        <TableCell colSpan={1}>{medida.medida4}</TableCell>
                        <TableCell colSpan={1}>{medida.medida5}</TableCell>
                        <TableCell colSpan={1}>{medida.medida6}</TableCell>
                        <TableCell colSpan={1}>{medida.medida7}</TableCell>
                        <TableCell colSpan={1}>{medida.medida8}</TableCell>
                        <TableCell colSpan={1}>{medida.medida9}</TableCell>
                        <TableCell colSpan={1}>{medida.total}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}

                  {ordem.impressora ? (
                    ordem.impressora.map((medida, index) => (
                      <TableRow key={(medida, index)}>
                        <TableCell
                          colSpan={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <strong>IMPRESSORA</strong>
                        </TableCell>
                        <TableCell colSpan={1}>{medida.medida1}</TableCell>
                        <TableCell colSpan={1}>{medida.medida2}</TableCell>
                        <TableCell colSpan={1}> {medida.medida3}</TableCell>
                        <TableCell colSpan={1}>{medida.medida4}</TableCell>
                        <TableCell colSpan={1}>{medida.medida5}</TableCell>
                        <TableCell colSpan={1}>{medida.medida6}</TableCell>
                        <TableCell colSpan={1}>{medida.medida7}</TableCell>
                        <TableCell colSpan={1}>{medida.medida8}</TableCell>
                        <TableCell colSpan={1}>{medida.medida9}</TableCell>
                        <TableCell colSpan={1}>{medida.total}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
