import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  NativeSelect,
  FormControl,
  Box,
  DialogContentText,
  InputLabel,
} from '@mui/material';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelecionaMaquinas = ({
  open,
  onClose,
  producaoSelecionada,
  listaMaquinas,
  atualizarMaquinas,
  maquinasSelecionadas,
  statusAtual,
}) => {
  const [maquina1, setMaquina1] = useState({
    sequencia: 0,
    maquina: 0,
    funcionario: 0,
  });
  const [maquina2, setMaquina2] = useState({
    sequencia: 0,
    maquina: 0,
    funcionario: 0,
  });
  const [maquina3, setMaquina3] = useState({
    sequencia: 0,
    maquina: 0,
    funcionario: 0,
  });
  const [maquina4, setMaquina4] = useState({
    sequencia: 0,
    maquina: 0,
    funcionario: 0,
  });

  const [values, setValues] = useState({
    producao: 0,
    status: 0,
    configura: [maquina1, maquina2, maquina3, maquina4],
  });

  function encontrarMaquinas(maquina) {
    for (let i = 0; i < listaMaquinas.length; i++) {
      let maquinaEncontrada = listaMaquinas.find(
        (item) => item.maq_codigo === maquina
      );

      return maquinaEncontrada;
    }
  }

  useEffect(() => {
    setValues({
      producao: producaoSelecionada,
      status: statusAtual,
      configura: [maquina1, maquina2, maquina3, maquina4],
    });
  }, [
    maquina1,
    maquina2,
    maquina3,
    maquina4,
    producaoSelecionada,
    listaMaquinas,
    statusAtual,
  ]);

  useEffect(() => {
    if (maquinasSelecionadas.length > 0) {
      const iMaquina1 = encontrarMaquinas(maquinasSelecionadas[0].codigo1);
      const iMaquina2 = encontrarMaquinas(maquinasSelecionadas[0].codigo2);
      const iMaquina3 = encontrarMaquinas(maquinasSelecionadas[0].codigo3);
      const iMaquina4 = encontrarMaquinas(maquinasSelecionadas[0].codigo4);
      console.log(iMaquina1, iMaquina2, iMaquina3, iMaquina4);
      setMaquina1({
        sequencia: iMaquina1 ? 1 : 0,
        maquina: iMaquina1 ? iMaquina1.maq_codigo : 0,
        funcionario: iMaquina1 ? iMaquina1.fun_codigo : 0,
      });
      setMaquina2({
        sequencia: iMaquina2 ? 2 : 0,
        maquina: iMaquina2 ? iMaquina2.maq_codigo : 0,
        funcionario: iMaquina2 ? iMaquina2.fun_codigo : 0,
      });
      setMaquina3({
        sequencia: iMaquina3 ? 3 : 0,
        maquina: iMaquina3 ? iMaquina3.maq_codigo : 0,
        funcionario: iMaquina3 ? iMaquina3.fun_codigo : 0,
      });
      setMaquina4({
        sequencia: iMaquina4 ? 4 : 0,
        maquina: iMaquina4 ? iMaquina4.maq_codigo : 0,
        funcionario: iMaquina4 ? iMaquina4.fun_codigo : 0,
      });
    }
  }, [maquinasSelecionadas, listaMaquinas]);

  const validaMaquinas = () => {
    const maquina = [];
    const todosIguais = [];
    values.configura.forEach((item) => {
      if (item.maquina > 0) {
        return maquina.push(item.maquina);
      } else {
        return todosIguais.push(item.maquina);
      }
    });

    if (new Set(maquina).size !== maquina.length) {
      return new Set(maquina).size !== maquina.length;
    } else if (todosIguais.length !== 0 && maquina.length === 0) {
      return true;
    }
  };

  const handleSave = () => {
    if (validaMaquinas()) {
      toast.error(
        'Não existe maquinas selecionadas ou existem maquinas iguais selecionadas! Verifique'
      );
    } else {
      atualizarMaquinas(values);
    }
  };
  const handleChangeMaquina = (prop) => (event) => {
    const obj = { sequencia: 0, maquina: 0, funcionario: 0 };
    let maquinaSelecionada = {};
    if (event.target.value !== '0') {
      maquinaSelecionada = listaMaquinas.find((maquina) =>
        maquina.maq_codigo == event.target.value ? event.target.value : 0
      );
    } else maquinaSelecionada = {};

    switch (prop) {
      case 'maquina1':
        if (!maquinaSelecionada.maq_codigo) {
          setMaquina1(obj);
          setMaquina2(obj);
          setMaquina3(obj);
          setMaquina4(obj);
        } else {
          setMaquina1({
            sequencia: maquinaSelecionada.maq_codigo ? 1 : 0,
            maquina: maquinaSelecionada.maq_codigo
              ? maquinaSelecionada.maq_codigo
              : 0,
            funcionario: maquinaSelecionada.fun_codigo
              ? maquinaSelecionada.fun_codigo
              : 0,
          });
        }
        break;
      case 'maquina2':
        if (!maquinaSelecionada.maq_codigo) {
          setMaquina2(obj);
          setMaquina3(obj);
          setMaquina4(obj);
        } else {
          setMaquina2({
            sequencia: maquinaSelecionada.maq_codigo ? 2 : 0,
            maquina: maquinaSelecionada.maq_codigo
              ? maquinaSelecionada.maq_codigo
              : 0,
            funcionario: maquinaSelecionada.fun_codigo
              ? maquinaSelecionada.fun_codigo
              : 0,
          });
        }

        break;
      case 'maquina3':
        if (!maquinaSelecionada.maq_codigo) {
          setMaquina3(obj);
          setMaquina4(obj);
        } else {
          setMaquina3({
            sequencia: maquinaSelecionada.maq_codigo ? 3 : 0,
            maquina: maquinaSelecionada.maq_codigo
              ? maquinaSelecionada.maq_codigo
              : 0,
            funcionario: maquinaSelecionada.fun_codigo
              ? maquinaSelecionada.fun_codigo
              : 0,
          });
        }

        break;
      case 'maquina4':
        if (!maquinaSelecionada.maq_codigo) {
          setMaquina4(obj);
        } else {
          setMaquina4({
            sequencia: maquinaSelecionada.maq_codigo ? 4 : 0,
            maquina: maquinaSelecionada.maq_codigo
              ? maquinaSelecionada.maq_codigo
              : 0,
            funcionario: maquinaSelecionada.fun_codigo
              ? maquinaSelecionada.fun_codigo
              : 0,
          });
        }
        break;

      default:
        break;
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>DEFINIÇÃO DE MAQUINAS</DialogTitle>
      <DialogContent dividers>
        <DialogContent dividers>
          <DialogContentText>
            SELECIONE AS MAQUINAS A SEREM USADAS NA PRODUÇÃO:
            {producaoSelecionada}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <Box mt={2} mb={2}>
            <FormControl fullWidth>
              <InputLabel shrink>MAQUINA 01 </InputLabel>
              <NativeSelect
                variant="filled"
                disabled={
                  maquinasSelecionadas.length > 0
                    ? maquinasSelecionadas[0].maquina1Confirmado == 'SIM'
                      ? true
                      : false
                    : ''
                }
                value={values.configura[0].maquina}
                onChange={handleChangeMaquina('maquina1')}
              >
                <option value={0}>Selecione</option>
                {listaMaquinas.length > 0
                  ? listaMaquinas.map((maquina, index) => {
                      return (
                        <option value={maquina.maq_codigo} key={index}>
                          {maquina.maq_descricao}
                        </option>
                      );
                    })
                  : ''}
              </NativeSelect>
            </FormControl>
          </Box>
          <Box mt={2} mb={2}>
            <FormControl fullWidth>
              <InputLabel shrink>MAQUINA 02</InputLabel>
              <NativeSelect
                disabled={
                  values.configura[0].maquina !== 0
                    ? false
                    : true || maquinasSelecionadas.length > 0
                    ? maquinasSelecionadas[0].maquina2Confirmado == 'SIM'
                      ? true
                      : false
                    : ''
                }
                variant="filled"
                value={values.configura[1].maquina}
                onChange={handleChangeMaquina('maquina2')}
              >
                <option value={0}>Selecione</option>
                {listaMaquinas.length > 0
                  ? listaMaquinas.map((maquina, index) => {
                      return (
                        <option value={maquina.maq_codigo} key={index}>
                          {maquina.maq_descricao}
                        </option>
                      );
                    })
                  : ''}
              </NativeSelect>
            </FormControl>
          </Box>
          <Box mt={2} mb={2}>
            <FormControl fullWidth>
              <InputLabel shrink>MAQUINA 03 </InputLabel>
              <NativeSelect
                disabled={
                  values.configura[1].maquina !== 0
                    ? false
                    : true || maquinasSelecionadas.length > 0
                    ? maquinasSelecionadas[0].maquina3Confirmado == 'SIM'
                      ? true
                      : false
                    : ''
                }
                variant="filled"
                value={values.configura[2].maquina}
                onChange={handleChangeMaquina('maquina3')}
              >
                <option value={0}>Selecione</option>
                {listaMaquinas.length > 0
                  ? listaMaquinas.map((maquina, index) => {
                      return (
                        <option value={maquina.maq_codigo} key={index}>
                          {maquina.maq_descricao}
                        </option>
                      );
                    })
                  : ''}
              </NativeSelect>
            </FormControl>
          </Box>
          <Box mt={2} mb={2}>
            <FormControl fullWidth>
              <InputLabel shrink>MAQUINA 04 </InputLabel>
              <NativeSelect
                disabled={
                  values.configura[2].maquina !== 0
                    ? false
                    : true || maquinasSelecionadas.length > 0
                    ? maquinasSelecionadas[0].maquina4Confirmado == 'SIM'
                      ? true
                      : false
                    : ''
                }
                variant="filled"
                value={values.configura[3].maquina}
                onChange={handleChangeMaquina('maquina4')}
              >
                <option value={0}>Selecione</option>
                {listaMaquinas.length > 0
                  ? listaMaquinas.map((maquina, index) => {
                      return (
                        <option value={maquina.maq_codigo} key={index}>
                          {maquina.maq_descricao}
                        </option>
                      );
                    })
                  : ''}
              </NativeSelect>
            </FormControl>
          </Box>
        </DialogContent>

        <Box mt={2} mb={2}></Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button onClick={onClose}>Fechar</Button>
        <Button
          onClick={() => {
            handleSave();
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelecionaMaquinas;
