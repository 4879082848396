import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Collapse,
  IconButton,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import CheckIcon from '@mui/icons-material/Check';

import moment from 'moment';

export default function Row(props) {
  const { lista, confirmaLog } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell
          colSpan={1}
          sx={{ justifyContent: 'space-around', display: 'flex' }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <IconButton size="small" onClick={() => confirmaLog(lista.codigo)}>
            <CheckIcon color="success" />
          </IconButton>
        </TableCell>
        <TableCell>
          {moment(lista.data).add(3, 'h').format('DD/MM/YY')}
        </TableCell>
        <TableCell>{lista.hora}</TableCell>
        <TableCell>{lista.tipo}</TableCell>
        <TableCell>{lista.operacao}</TableCell>
        <TableCell>{lista.nome_usuario}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {lista.descricao}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
