import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  Stack,
  Divider,
  Typography,
  TextField,
  IconButton,
} from '@mui/material';

import { toast } from 'react-toastify';

import api from '../../../../api/api';
import { useAuthState } from '../../../../contexts/AuthContext';
import Spinner from '../../../../components/Spinner/Spinner';
import TabelaResultados from './TabelaResultados/TabelaResultados';
import TitlePage from '../../../../components/TitlePage/TitlePage';
import SearchIcon from '@mui/icons-material/Search';

const ColsultaMateria = () => {
  const [loading, setLoading] = useState(false);
  const [materiaPrima, setMateriaPrima] = useState();
  const [listaMateriaPrima, setListaMateriaPrima] = useState([]);

  const { empresaAtiva, acessos, appAcesso, usuarioIDLogado } = useAuthState();
  const buscaMateriaPrima = () => {
    setLoading(true);
    api
      .post(
        '/materiaPrima/listaestoque',
        { descricao: materiaPrima },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(({ data }) => {
        setListaMateriaPrima(data);
        console.log(data);
      })
      .catch((err) => {
        toast.error('Erro ao buscar Matéria Prima ');
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <Spinner />;
  }

  const atualizaEstoque = (idmateria, tipoMovimento, quantidade) => {
    setLoading(true);
    api
      .put(
        '/producao/estoque/ajuste',
        {
          idmateria: idmateria,
          movimento: tipoMovimento,
          quantidade: quantidade,
          tipo: 'AJU',
          usuario: usuarioIDLogado,
        },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(() => {
        toast.success('Producão alterada com sucesso ');
      })
      .catch((err) => {
        toast.success('Erro ao  alterar Producao ');
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        buscaMateriaPrima();
      });
  };

  return (
    <>
      <Box>
        <TitlePage title="Consulta Matéria Prima" />
        <Box>
          <Stack
            direction={{ xs: 'row', sm: 'row', md: 'row', lg: 'row' }}
            spacing={{ xs: 1, sm: 1, md: 3 }}
            flexWrap="wrap"
            mt={2}
            mb={2}
          >
            <Box width={'100%'} ml={3}>
              <Typography variant="subtitle2">
                Digite a Matéria Prima
              </Typography>
            </Box>
            <Box width={'80%'}>
              <FormControl fullWidth>
                <TextField
                  value={materiaPrima}
                  onChange={(e) => setMateriaPrima(e.target.value)}
                />
              </FormControl>
            </Box>
            <Box>
              <IconButton
                size="large"
                sx={{ bgcolor: 'primary.success' }}
                onClick={() => buscaMateriaPrima()}
              >
                <SearchIcon />
              </IconButton>
            </Box>
          </Stack>
        </Box>
        <Divider />
        <TabelaResultados
          listaMateriaPrima={listaMateriaPrima}
          atualizaEstoque={atualizaEstoque}
        />
      </Box>
    </>
  );
};

export default ColsultaMateria;
