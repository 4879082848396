import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TablePagination,
} from '@mui/material';

import Row from './Rows/Row';
import { useConfirm } from 'material-ui-confirm';

import { filtroMateriaPrima } from '../../../../../helpers/FilterFunctions';
import ReservaMateria from './ReservaMateria/ReservaMateria';
import { toast } from 'react-toastify';

export default function TabelaResultados({
  listaOrdens,
  listaMateriasPrima,
  atualizaMateriaPrima,
  solicitaCompra,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openDialogSeparaMateria, setOpenDialogSeparaMateria] = useState(false);
  const [producaoSelecionada, setProducaoSelecionada] = useState('');
  const [totalRiscador, setTotalRiscador] = useState(0);
  const [totalImpressora, setTotalImpressora] = useState(0);
  const [modeloCaixa, setModeloCaixa] = useState('');
  const [materiaVincada, setMateriaVincada] = useState('');
  const [qtdPedido, setQtdPedido] = useState('');
  const confirm = useConfirm();

  const [filtredMateriasPrima, setFiltredMateriasPrima] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };
  const fechaDialogSeparaMateria = () => {
    setOpenDialogSeparaMateria(false);
    setTotalImpressora(0);
    setTotalRiscador(0);
    setModeloCaixa('');
  };

  const filtroReservaMateria = (
    producao,
    comprimento,
    largura,
    gramatura,
    qtdPedido,
    materiaPedida,
    qtdePedida,
    divisorComprimento,
    divisorLargura,
    vincada,
    modeloCaixa,
    coeficienteCaixa,
    duplicada
  ) => {
    if (duplicada !== 0) {
      confirm({
        title: 'Alerta!',
        description: `Existe uma ou mais ordens vinculadas a esse pedido, Verifique!  `,
        confirmationText: 'OK',
        cancellationText: '',
      });
    }
    if (materiaPedida !== null) {
      const materialPedido = listaMateriasPrima.find(
        (materia) => materia.codigo == materiaPedida
      );

      if (!materialPedido) {
        toast.error('Material não encontrado');
        return;
      } else {
        setFiltredMateriasPrima([
          {
            ...materialPedido,
            qtde_chapas: qtdePedida,
            qtde_caixasPedido: qtdPedido,
          },
        ]);
      }
    } else {
      filtroMateriaPrima(
        listaMateriasPrima,
        comprimento,
        largura,
        gramatura,
        qtdPedido,
        divisorComprimento,
        divisorLargura,
        coeficienteCaixa
      )
        .then((res) => {
          setFiltredMateriasPrima(res);
        })
        .catch((err) => console.log(err));
    }
    setTotalRiscador(largura);
    setTotalImpressora(comprimento);
    setProducaoSelecionada(producao);
    setOpenDialogSeparaMateria(true);
    setMateriaVincada(vincada);
    setModeloCaixa(modeloCaixa);
    setQtdPedido(qtdPedido);
  };

  return (
    <Box>
      {listaOrdens ? (
        <ReservaMateria
          openDialogSeparaMateria={openDialogSeparaMateria}
          onClose={fechaDialogSeparaMateria}
          producaoSelecionada={producaoSelecionada}
          listaMateriasPrima={filtredMateriasPrima}
          atualizaMateriaPrima={atualizaMateriaPrima}
          totalRiscador={totalRiscador}
          totalImpressora={totalImpressora}
          materiaVincada={materiaVincada}
          modeloCaixa={modeloCaixa}
          qtdPedido={qtdPedido}
        />
      ) : (
        <></>
      )}

      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              <TableCell>Pedido</TableCell>
              <TableCell>Emissão</TableCell>
              <TableCell>Entrega</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Caixa</TableCell>
              <TableCell>Comp.</TableCell>
              <TableCell>Larg.</TableCell>
              <TableCell>Alt.</TableCell>
              <TableCell>Chapa</TableCell>
              <TableCell>Gramat.</TableCell>
              <TableCell>Qtde</TableCell>
              <TableCell>Kilo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listaOrdens ? (
              listaOrdens.length > 0 ? (
                listaOrdens
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((ordem, index) => {
                    return (
                      <Row
                        ordem={ordem}
                        key={index}
                        filtroReservaMateria={filtroReservaMateria}
                        solicitaCompra={solicitaCompra}
                      />
                    );
                  })
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listaOrdens.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}
